import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewPeoReport,
  fetchPeoReports,
} from '../../reduxToolkit/features/PeoReport/PeoReportSlice'
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Select } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../Components/ErrorModal'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
export default function PeoReportModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const groupStatus = useSelector((state) => state.group.groupStatus)

  const [value, setValue] = React.useState(1)
  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }

  const onFinish = (values) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }


  const { Option } = Select

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleSend = async () => {
    let PeoReport = {
      program_id: props.ProgramID,
    }
    setUpdateRequestStatus('pending')
    dispatch(addNewPeoReport(PeoReport))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        props.handleCloseReportModal()
        dispatch(fetchPeoReports())
        history.push(`/citrine/PeoImprovementList/`);
        setUpdateRequestStatus('idle')
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
        setUpdateRequestStatus('idle')
      })

    // try {
    //   let PeoReport = {
    //     program_id: props.ProgramID,
    //   };
    //   console.log("PeoReport", PeoReport);
    //   setUpdateRequestStatus("pending");
    //   const resultAction = await dispatch(
    //     addNewPeoReport(PeoReport),
    //     props.handleToggle(),
    //     fetchPeoReports()
    //   );
    //   unwrapResult(resultAction);

    //   const resultAction1 = await dispatch(fetchPeoReports());
    //   unwrapResult(resultAction1);
    // } catch (err) {
    //   console.log("Failed to save the CourseReport: ", err);
    // } finally {
    //   history.push(`/citrine/PeoImprovementList/`);
    //   fetchPeoReports();

    //   setUpdateRequestStatus("idle");
    // }
    // fetchPeoReports();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCloseReportModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {' '}
          {t('Create PEO Report')}
        </DialogTitle>
        <DialogContent>
          <h4>{t('Are you sure you want to Create PEO Report ?')}</h4>
          <DialogActions>
            <Button onClick={props.handleCloseReportModal} color="primary">
              {t(' Cancel')}
            </Button>
            <Button
              onClick={() => {
                props.handleCloseReportModal()
                handleSend()
              }}
              color="primary"
            >
              {t(' Confirm')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
