import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { DatePicker, Form, Input, notification, Select } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addNewActionRecommendation } from '../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice'
import { fetchKPIsRecommendations } from '../../reduxToolkit/features/Recommendations/RecommendationsSlice'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useButtonStyles = makeStyles(buttonStyles)
export default function KPIActionRecommendationModal(props) {
  console.log(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonClasses = useButtonStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const { Option, OptGroup } = Select
  const { TextArea } = Input
  const [form] = Form.useForm()

  const [start_date, setStart_date] = useState([])
  const [due_date, setDue_date] = useState([])
  const [priority, setPriority] = useState(null)
  const [type_action, setType_action] = useState(null)
  const [assign_to_id, setAssign_to_id] = useState(null)
  const [resource, setResource] = useState('KPI')
  const [resource_id, setResource_id] = useState(
    props.kpiReport && props.kpiReport.id,
  )
  const [recommendation_id, setRecommendation_id] = useState(
    props.recommendation_id,
  )
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const users = useSelector((state) => state.user.users)
  const actionsTypes = useSelector((state) => state.ActionType.actionTypes)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  useEffect(() => {}, [dispatch])

  const onFinish = async (values) => {
    console.log({
          ...values,
          type_action,
          priority,
          due_date,
          start_date,
          assign_to_id,
          resource:"program",
          resource_id: props.program_id,
          recommendation_id,
        })
    let obj = {
      type: props.type,
      Kpi_id: props.kpi_id,
    }
    const resultAction = await dispatch(
      addNewActionRecommendation({
        ...values,
        type_action,
        priority,
        due_date,
        start_date,
        assign_to_id,
        resource:"program",
        resource_id: props.program_id,
        recommendation_id,
      }),
    )

    if (addNewActionRecommendation.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Add successfully`)
      dispatch(fetchKPIsRecommendations(obj))
      props.dispatchKPIsRecommendations(props.kpi_id, props.type)
      form.resetFields()
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      }
    }
  }

  const KpiRecommendations = useSelector(
    (state) => state.RecommendationsSlice.KPIsRecommendations,
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  function onChangeDue_date(date, dateString) {
    setDue_date(dateString)
  }
  function onChangeStart_date(date, dateString) {
    setStart_date(dateString)
  }

  function onOk(value) {
    console.log('onOk: ', value)
  }

  function handleChange(value) {
    console.log('value', value)
    setType_action(value)
  }
  function onChange(value) {
    console.log(`selected Assign_to_id ${value}`)
    setAssign_to_id(value)
  }
  function onChangePriority(value) {
    console.log(`selected Priority ${value}`)
    setPriority(value)
  }

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }

  return (
    <div>
      <Button
        color="primary"
        style={{
          border: '1px solid #D1CF4B',
          backgroundColor: '#D1CF4B',
          borderRadius: '30px',
          fontSize: '10px',
          height: '30px',
          minWidth: '30px',
          width: '30px',
          marginTop: '-10px',
          marginBottom: '5px',
          marginRight: '20px',
        }}
        onClick={(event) => {
          handleClickOpen(event)
          props.handleDropDownButtonClose()
        }}
      >
        <AddIcon style={{ color: 'white', fontSize: '15px' }} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('Add Action Recommendation')}
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="type_action"
                  label="Action Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the type_action field.',
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={handleChange}
                  >
                    {actionsTypes
                      ?.filter(
                        (actionType) => actionType?.category === 'Program',
                      )
                      .map((item) => (
                        <Option value={item?.id}>{item?.name}</Option>
                      ))}
                    {/* <OptGroup label="Teachning Process">
                      <Option value={t("Teachning Strategy")}>
                        {t("Teachning Strategy")}
                      </Option>
                      <Option value={t("Weekly Distribution")}>
                        {t("Weekly Distribution")}
                      </Option>
                      <Option value={t("Teachning Material")}>
                        {t("Teachning Material")}
                      </Option>
                      <Option value={t("Mode Of Instructions")}>
                        {t("Mode Of Instructions")}
                      </Option>
                      <Option value={t("Learning Resources")}>
                        {t("Learning Resources")}
                      </Option>
                      <Option value={t("Facilities")}>{t("Facilities")}</Option>
                    </OptGroup>
                    <OptGroup label="Assessment">
                      <Option value={t("Planning")}>{t("Planning")}</Option>
                      <Option value={t("Sampling")}>{t("Sampling")}</Option>
                      <Option value={t("Assessment Method")}>
                        {t("Assessment Method")}
                      </Option>
                    </OptGroup>
                    <OptGroup label="Curriculum">
                      <Option value={t("Update Syllabus")}>
                        {t("Update Syllabus")}
                      </Option>
                      <Option value={t("Update Extra-curricular Activities")}>
                        {t("Update Extra-curricular Activities")}
                      </Option>
                    </OptGroup>
                    <OptGroup label="Alignment">
                      <Option value={t("Mapping CLO-Assessment Method")}>
                        {t("Mapping CLO-Assessment Method")}
                      </Option>
                      <Option value={t("Mapping PLO Ind - CLO")}>
                        {t("Mapping PLO Ind - CLO")}
                      </Option>
                      <Option value={t("Mapping GA Ind - CLO")}>
                        {t("Mapping GA Ind - CLO")}
                      </Option>
                      <Option value={t("Mapping PLO Ind - PLO")}>
                        {t("Mapping PLO Ind - PLO")}
                      </Option>

                      <Option value={t("Mapping GA Ind - GA")}>
                        {t("Mapping GA Ind - GA")}
                      </Option>

                      <Option value={t("Mapping PEO - PLO/GA")}>
                        {t("Mapping PEO - PLO/GA")}
                      </Option>
                    </OptGroup> */}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="assign_to_id"
                  label="Assign To"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the assign_to_id field.',
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={onChange}
                  >
                    {props.users &&
                      props.users.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.person_details[0].first_name}{' '}
                          {user.person_details[0].last_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the priority field.',
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={onChangePriority}
                  >
                    <Option value={`${1}`}>{t('High')}</Option>

                    <Option value={`${2}`}>{t('Medium')}</Option>

                    <Option value={`${3}`}>{t('Low')}</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the Description field.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="start_date"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the start  date  field.',
                    },
                  ]}
                >
                  <DatePicker
                    //showTime
                    onChange={onChangeStart_date}
                    style={{ zIndex: '10000  !important' }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode
                    }}
                    style={{ width: '400px' }}
                  />
                </Form.Item>

                <Form.Item
                  name="due_date"
                  label="Due Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the due date  field.',
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChangeDue_date}
                    style={{ zIndex: '10000  !important' }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode
                    }}
                    style={{ width: '400px' }}
                    //showTime
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  )
}
