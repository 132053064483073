import { ArrowRightOutlined } from '@ant-design/icons'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Avatar, Breadcrumb, Divider, notification, Tabs } from 'antd'
import 'antd/dist/antd.css'
import {
  blackColor,
  hexToRgb,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
// core components
import GridItem from 'components/Grid/GridItem.js'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTasks } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DropDownDocumentKpiImprovementReport from 'views/Components/DropDownDocumentKpiImprovementReport.js'
import DropDownKpiImprovementReportButton from 'views/Components/DropDownKpiImprovementReportButton.js'
import {
  grayColor,
  primaryColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchActivities } from '../../../../reduxToolkit/features/Activitiy/ActivitiesSlice'
import {
  fetchKpiReportById,
  fetchKpiReports,
  UpdateKpiReport,
} from '../../../../reduxToolkit/features/KpiReport/KpiReportSlice.js'
import { fetchKPIReportDocumentsByProgramId } from '../../../../reduxToolkit/features/KpiReportDocument/KpiReportDocument'
import ErrorModal from '../../../Components/ErrorModal'
import PreviousActionPlan from '../../../Components/PreviousActionPlan'
import AnalysisKpiCollege from './AnalysisKpiCollege'
import AnalysisKpiDepartment from './AnalysisKpiDepartment'
import AnalysisKpiProgram from './AnalysisKpiProgram'
import AnalysisKpis from './AnalysisKpis'
import AnalysisKpiUniversity from './AnalysisKpiUniversity'
import GenerateKPIActionsModal from './GenerateKPIActionsModal'
import Identification from './Identifications'

import { fetchAll_standards_KPIsCollege } from '../../../../reduxToolkit/features/KPI/KPICollegeSlice.jsx'
import { fetchAll_standards_KPIsDepartment } from '../../../../reduxToolkit/features/KPI/KPIDepartmentSlice'
import { fetchAll_standards_KPIsProgram } from '../../../../reduxToolkit/features/KPI/KPIProgramSlice'
import { fetchAll_standards_KPIsUniversity } from '../../../../reduxToolkit/features/KPI/KPIUniversitySlice.jsx'

const useStyles = makeStyles(styles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const { TabPane } = Tabs

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}))
export default function KPIsImprovementDetails({ match }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const titleClasses = useTitleStyles()
  const cardClasses = useCardStyles()
  const { Id } = match.params
  const { type } = match.params
  const classes1 = useStyles1()
  console.log(Id, 'id')
  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [openModal, setOpenModal] = React.useState(false)

  const handleClickOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  console.log("kpiReport", kpiReport);
  const [current, setCurrent] = React.useState(0);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);

  const [open, setOpen] = React.useState(false)
  const [disabledStatus, setDisabledStatus] = useState(false)
  const renderTasks = () => {
    setShowTasks(true)
    setShowActivities(false)
    setShowDiagram(false)
    setShowReports(false)
  }
  const renderActivities = () => {
    setShowActivities(true)
    setShowDiagram(false)
    setShowTasks(false)
    setShowReports(false)
  }
  const renderReports = () => {
    setShowReports(true)
    setShowActivities(false)
    setShowDiagram(false)
    setShowTasks(false)
  }

  //bmpn Modal
  const handleClickOpen = () => {
    setOpen(true)
    setShowDiagram(false)
    setShowActivities(false)
    setShowTasks(false)
    setShowReports(true)
  }

  const handleModalClose = () => {
    setOpen(false)
    setShowDiagram(false)
    setShowActivities(true)
  }

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: 'gray' }}
              >
                {Moment(
                  item &&
                  item.changes &&
                  item.changes[0] &&
                  item.changes[0].date,
                ).format('YYYY-MM-DD')}
              </Divider>

              <div
                style={{
                  backgroundColor: '#edeff1',
                  borderBottom: '1px solid #cfd5db',
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={2}>
                    <Avatar
                      style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={10}>
                    <p>
                      <strong>
                        {item &&
                          item.changes &&
                          item.changes[0] &&
                          item.changes[0].user_id}
                      </strong>
                    </p>

                    <strong> Field : {item && item.field}</strong>
                    <ul style={{ paddingLeft: '0px', marginLeft: '0px' }}>
                      <li>
                        {item &&
                          item.changes &&
                          item.changes[0] &&
                          item.changes[0].value1}
                        <ArrowRightOutlined style={{ fontSize: '12px' }} />
                        {item &&
                          item.changes &&
                          item.changes[0] &&
                          item.changes[0].value2}
                      </li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      )
    } else if (showTasks) {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            <DropDownDocumentKpiImprovementReport
              programId={kpiReport && kpiReport.program}
              type={kpiReport && kpiReport.type}
              resourceId={Id}
            />
          </div>

          {kpiReportDocuments &&
            kpiReportDocuments.map((document) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <GridContainer justify="space-between" alignItems="center">
                  <Link
                    to={`/citrine/KPIImprovementDetails/${Id}/KPIReportDocument/${document.id}`}
                  >
                    <GridItem>
                      <h6> {document && document.name}</h6>
                      <br />
                      <h7>State : {document && document.state}</h7>
                      <br />
                      <h7>Type : {document && document.resource}</h7>
                      <br />
                      {/* <h7>Created By: {document && document.creator_details.username}</h7> */}
                      <br />
                      <h7>
                        Created On:{' '}
                        {document &&
                          Moment(document.last_activity).format('MMM Do YY')}
                      </h7>
                      <br />
                      {/* <h7>Assigned To: {document && document.report_details.assign_to_details && document.report_details.assign_to_details[0].username}</h7>
                      <br /> */}
                      <h7>Version: {document && document.version} </h7>
                      <br />
                    </GridItem>
                  </Link>
                  <GridItem>
                    <DropDownKpiImprovementReportButton
                      id={document && document.id}
                      progId={kpiReport && kpiReport.program}
                      type={kpiReport && kpiReport.type}
                    />
                  </GridItem>
                </GridContainer>
              </Card>
            ))}
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            <DropDownDocumentKpiImprovementReport
              programId={kpiReport && kpiReport.program}
              type={kpiReport && kpiReport.type}
              resourceId={Id}
            />
          </div>

          {kpiReportDocuments &&
            kpiReportDocuments.map((document) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <GridContainer justify="space-between" alignItems="center">
                  <Link
                    to={`/citrine/KPIImprovementDetails/${Id}/KPIReportDocument/${document.id}`}
                  >
                    <GridItem>
                      <h6> {document && document.name}</h6>
                      <br />
                      <h7>State : {document && document.state}</h7>
                      <br />
                      <h7>
                        Created By:{' '}
                        {document && document.creator_details.username}
                      </h7>
                      <br />
                      <h7>Created On: {document && document.creation_date}</h7>
                      <br />
                      <h7>
                        Assigned To:{' '}
                        {document &&
                          document.report_details.assign_to_details &&
                          document.report_details.assign_to_details[0].username}
                      </h7>
                      <br />
                      <h7>Version: {document && document.version} </h7>
                      <br />
                    </GridItem>
                  </Link>
                  <GridItem>
                    <DropDownKpiImprovementReportButton
                      id={document && document.id}
                      progId={kpiReport && kpiReport.program}
                      type={kpiReport && kpiReport.type}
                    />
                  </GridItem>
                </GridContainer>
              </Card>
            ))}
        </div>
      )
    }
  }

  //   const fetchKpiReport = async (id) => {
  //     try {
  //       const resultAction = await dispatch(fetchKpiReportById(id))
  //         .then(unwrapResult)
  //         .then((kpiReport) => {
  //           console.log(kpiReport, "kpiReport");
  //           localStorage.setItem("kpiReport", kpiReport.state_object);
  //           setStateName(kpiReport.state_object);
  //         });
  //     } catch (err) {
  //       setHandledError(err);
  //       handleOpenErrorModal();
  //     }
  //   };
  const fetchKPIReport = async () => {
    try {

      await dispatch(fetchKpiReportById(Id));

    }

    catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchKPIReport();
    dispatch(
      fetchKPIReportDocumentsByProgramId(Id)
    );
  }, []);

  useEffect(() => {
    // fetchKpiReport(Id);

    // dispatch(fetchKpiReportById(Id))
    //   .then(unwrapResult)
    //   .then((kpiReport) => {
    //     console.log(kpiReport, "kpiReport");
    //     localStorage.setItem("kpiReport", kpiReport && kpiReport.state_object);
    //     setStateName(kpiReport && kpiReport.state_object);
    //     dispatch(fetchKPIReportDocumentsByProgramId(kpiReport && kpiReport.id && kpiReport.id));
    //   });

    dispatch(fetchActivities());

  }, []);

  const kpiReportDocuments = useSelector(
    (state) => state.KPIDocumentReport.kpiReportDocuments,
  )

  // console.log("kpiReportDocuments", kpiReportDocuments);

  const Activities = useSelector((state) => state.Activity.Activities)

  const kpiReport = useSelector((state) => state.KpiReport.KpiReport);
  console.log("kpiReport", kpiReport)
  // console.log(kpiReport, "kpiReport");
  const kpiReportStatus = useSelector((state) => state.KpiReport.KpiReportStatus);
  useEffect(() => {
    setStateName(kpiReport && kpiReport.state_object && kpiReport.state_object)
  }, [])
  // console.log(stateName, "stateName");

  const Approve = () => {
    setShow(true)
    setStateName('Approved')
    localStorage.setItem('state', 'Approved')
    //dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date: kpiReport && kpiReport.due_date && kpiReport.due_date,
        state_object: 'Approved',
      }
      const resultAction = dispatch(
        UpdateKpiReport({
          id: Id,
          updatedKpiReport: { ...modifiedAnnualProgramReport },
        }),
      )
      unwrapResult(resultAction)
      setStateName('Approved')
      dispatch(fetchKpiReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchKpiReports())
      //dispatch(fetchPrograms());
    }
  }
  const SendforApproval = () => {
    setShow(true)
    //dispatch(fetchActivitiesProgramSpec(programId));
    setStateName('Waiting for approval')
    localStorage.setItem('state', 'Waiting for approval')
    setDisabledStatus(true)
    try {
      let modifiedAnnualProgramReport = {
        due_date: kpiReport && kpiReport.due_date && kpiReport.due_date,
        state_object: 'Waiting for approval',
      }
      const resultAction = dispatch(
        UpdateKpiReport({
          id: Id,
          updatedKpiReport: { ...modifiedAnnualProgramReport },
        }),
      )
      unwrapResult(resultAction)
      setStateName('Waiting for approval')
      dispatch(fetchKpiReports())
      setDisabledStatus(true)
      //dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchKpiReports())
      // dispatch(fetchActivitiesProgramSpec(programId));
      setDisabledStatus(true)
    }
  }
  const ResetTODraft = () => {
    setShow(true)
    setStateName('Created')
    localStorage.setItem('state', 'Created')
    // dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date: kpiReport && kpiReport.due_date && kpiReport.due_date,
        state_object: 'Created',
      }
      const resultAction = dispatch(
        UpdateKpiReport({
          id: Id,
          updatedKpiReport: { ...modifiedAnnualProgramReport },
        }),
      )
      unwrapResult(resultAction)
      setStateName('Created')
      dispatch(fetchKpiReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchKpiReports())
      //dispatch(fetchPrograms());
    }
  }

  const Archive = () => {
    setShow(true)
    setStateName('Created')
    //dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date: kpiReport && kpiReport.due_date && kpiReport.due_date,
        state_object: 'Archived',
      }

      const resultAction = dispatch(
        UpdateKpiReport({
          id: Id,
          updatedKpiReport: { ...modifiedAnnualProgramReport },
        }),
      )
      unwrapResult(resultAction)
      setStateName('Archived')
      dispatch(fetchKpiReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchKpiReports())
      //dispatch(fetchPrograms());
    }
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const callback = (key) => {
    console.log(key)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let content
  {
    kpiReport &&
      kpiReport.states_actions &&
      kpiReport.states_actions.map((item) => {
        if (stateName === 'Created' && item.state === 'Created') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateKPIActionsModal
                          kpiReport={kpiReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('reset tO draft')
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {kpiReport &&
                    kpiReport.states_actions &&
                    kpiReport.states_actions.map((item) => {
                      return item.state === 'Created' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (
          stateName &&
          stateName.toLowerCase().includes('waiting for approval') &&
          item.state &&
          item.state.toLowerCase().includes('waiting for approval')
        ) {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateKPIActionsModal
                          kpiReport={kpiReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {kpiReport &&
                    kpiReport.states_actions &&
                    kpiReport.states_actions.map((item) => {
                      return item.state &&
                        item.state
                          .toLowerCase()
                          .includes('waiting for approval') ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (stateName === 'Approved' && item.state === 'Approved') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateKPIActionsModal
                          kpiReport={kpiReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('archive')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Archive')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {kpiReport &&
                    kpiReport.states_actions &&
                    kpiReport.states_actions.map((item) => {
                      return item.state === 'Approved' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (stateName === 'Archived' && item.state === 'Archived') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateKPIActionsModal
                          kpiReport={kpiReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {kpiReport &&
                    kpiReport.states_actions &&
                    kpiReport.states_actions.map((item) => {
                      return item.state === 'Archived' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        }
      })
  }
  const getKpiProgram = () => {
    dispatch(fetchAll_standards_KPIsProgram())
  }

  const getKpiDepartment = () => {
    dispatch(fetchAll_standards_KPIsDepartment())
  }

  const getKpiUniversity = () => {
    dispatch(fetchAll_standards_KPIsUniversity())
  }

  const getKpiCollege = () => {
    dispatch(fetchAll_standards_KPIsCollege())
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/KPIsImprovementList/`}>
              {' '}
              {t('KPI WorkFlow Report')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: 'orange' }}
              to={`/citrine/KPIsImprovementDetails/${Id}`}
            >
              {t('KPI Report Details')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={9}>
          <GridContainer xs={12} sm={12} md={12}>
            {content}
          </GridContainer>

          <GridContainer style={{ border: 'none', marginTop: '10px' }}>
            <div class="card" class="scrollable">
              <div
                style={{
                  padding: '10px',
                  borderRadius: '0px',
                  color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
                  background: whiteColor,
                  width: '100%',
                  boxShadow:
                    '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '0',
                  wordWrap: 'break-word',
                  fontSize: '.875rem',
                  // borderRight: "1px solid lightgray"
                }}
              >
                <GridContainer>
                  {kpiReport && <GridContainer style={{ marginBottom: '20px' }}>
                    <Identification kpiReport={kpiReport} />
                    {kpiReport && kpiReport.type === 'AllKpiReport' ? (
                      <Tabs onChange={callback} type="card">
                        <TabPane
                          tab={t(' Program kPIs')}
                          key="1"
                          onClick={() => getKpiProgram()}
                        >
                          <AnalysisKpiProgram kpiReport={kpiReport} />
                        </TabPane>
                        <TabPane
                          tab={t(' Department kPIs')}
                          key="2"
                          onClick={() => getKpiDepartment()}
                        >
                          <AnalysisKpiDepartment kpiReport={kpiReport} />
                        </TabPane>
                        <TabPane
                          tab={t(' College kPIs')}
                          key="3"
                          onClick={() => getKpiCollege()}
                        >
                          <AnalysisKpiCollege kpiReport={kpiReport} />
                        </TabPane>
                        <TabPane
                          tab={t(' University kPIs')}
                          key="4"
                          onClick={() => getKpiUniversity()}
                        >
                          <AnalysisKpiUniversity kpiReport={kpiReport} />
                        </TabPane>
                        <TabPane tab={t('Previous Action Plan')} key="5">
                          <PreviousActionPlan
                            resource_id={kpiReport.id}
                            resource="kpi"
                          />
                        </TabPane>
                      </Tabs>
                    ) : (
                      <Tabs onChange={callback} type="card">
                        <TabPane tab={t('kPIs && Recommendations')} key="6">
                          <AnalysisKpis kpiReport={kpiReport} />
                        </TabPane>
                        <TabPane tab={t('Previous Action Plan')} key="7">
                          <PreviousActionPlan
                            resource_id={kpiReport.id}
                            resource="kpi"
                          />
                        </TabPane>
                      </Tabs>
                    )}
                  </GridContainer>}
                  <br></br>
                  <ErrorModal
                    open={openErrorModal}
                    handleClose={handleCloseErrorModal}
                    handledError={handledError}
                  />
                </GridContainer>
              </div>
            </div>
          </GridContainer>
        </GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={3}>
          <div
            style={{
              padding: '10px',
              borderRadius: '0px',
              color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
              background: whiteColor,
              width: '100%',
              boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              minWidth: '0',
              wordWrap: 'break-word',
              fontSize: '.875rem',
              height: 570,
              border: '1px solid lightgray',
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-around">
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderActivities}
                    >
                      <FaHistory
                        color={showActivities ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderTasks}
                    >
                      <FaTasks color={showTasks ? primaryColor[0] : 'black'} />
                    </Button>
                  </GridItem>
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={(event) => {
                        handleClickOpen(event);
                      }}
                    >
                      <FaProjectDiagram
                        color={showDiagram ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem>   */}

                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderReports}
                    >
                      <GoReport
                        color={showReports ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                </GridContainer>
              </GridItem>
              <div
                className={classes1.root}
                style={{
                  height: '470px',
                  paddingBottom: '0px',
                  overflowY: 'auto',
                  maxHeight: '500px',
                  width: '100%',
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <div>{renderRow()}</div>
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
