import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { primaryColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import "assets/css/styles.css";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import EditImprovement from "./EditImprovement";
import AddAssessmentAnalysis from "./AddAssessmentAnalysis";

export default function AnalysisPLOsAssessment(props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.activeChildTabKey === "2") {
      props.fetchAnalysis()
    }
  }, [props.activeChildTabKey]);

  const improvementByResourceAndResourceIdAndType = useSelector(
    (state) =>
      state.AnnualProgramAnalysis.improvementByResourceAndResourseIdAndType
  );
  console.log('improvementByResourceAndResourceIdAndType', improvementByResourceAndResourceIdAndType)
  return (
    <div>
      <Card style={{ marginRight: "10px" }}>
        <CardBody>
          {improvementByResourceAndResourceIdAndType &&
            improvementByResourceAndResourceIdAndType.length == 0 && (
              <AddAssessmentAnalysis
                AnnualProgramReport={props.AnnualProgramReport}
                improvementByResourceAndResourceIdAndType={
                  improvementByResourceAndResourceIdAndType &&
                  improvementByResourceAndResourceIdAndType
                }
              />
            )}
          <br />
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ height: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>Strengths</strong> :
                <p>
                  {improvementByResourceAndResourceIdAndType &&
                    improvementByResourceAndResourceIdAndType[0] &&
                    improvementByResourceAndResourceIdAndType[0].strength}
                </p>
              </p>
            </GridItem>

            {improvementByResourceAndResourceIdAndType &&
              improvementByResourceAndResourceIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditImprovement
                    AnnualProgramReport={props.AnnualProgramReport}
                    improvementByResourceAndResourceIdAndType={
                      improvementByResourceAndResourceIdAndType &&
                      improvementByResourceAndResourceIdAndType
                    }
                    option={"strength"}
                  />
                </GridItem>
              )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ height: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>
                  Priorities for improvement
                </strong>
                :
                {improvementByResourceAndResourceIdAndType &&
                  improvementByResourceAndResourceIdAndType[0] &&
                  improvementByResourceAndResourceIdAndType[0]
                    .priorities_for_improvement_names?.map((item) =>

                      <GridItem>
                        {item}
                      </GridItem>

                    )}

              </p>

            </GridItem>

            {improvementByResourceAndResourceIdAndType &&
              improvementByResourceAndResourceIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditImprovement
                    AnnualProgramReport={props.AnnualProgramReport}
                    improvementByResourceAndResourceIdAndType={
                      improvementByResourceAndResourceIdAndType &&
                      improvementByResourceAndResourceIdAndType
                    }
                    option={"priorities_for_improvement"}
                  />
                </GridItem>
              )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ height: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>
                  Areas for improvement
                </strong>
                :
                <p>
                  {improvementByResourceAndResourceIdAndType &&
                    improvementByResourceAndResourceIdAndType[0] &&
                    improvementByResourceAndResourceIdAndType[0]
                      .areas_for_improvement}
                </p>
              </p>
            </GridItem>

            {improvementByResourceAndResourceIdAndType &&
              improvementByResourceAndResourceIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditImprovement
                    AnnualProgramReport={props.AnnualProgramReport}
                    improvementByResourceAndResourceIdAndType={
                      improvementByResourceAndResourceIdAndType &&
                      improvementByResourceAndResourceIdAndType
                    }
                    option={"areas_for_improvement"}
                  />
                </GridItem>
              )}
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
