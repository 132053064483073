import { CheckOutlined, DownOutlined } from '@ant-design/icons'
import { TableBody, TableCell, TableRow } from '@material-ui/core'
import CloseIcon from '@material-ui/core/IconButton'

import IconButton from '@material-ui/core/IconButton'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Close from '@material-ui/icons/Close'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Select, Radio, notification } from 'antd'
import 'antd/dist/antd.css'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles4 from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js'
import styles1 from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
// core components
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import { deleteActionRecommendation } from '../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice'
import { fetchCourses } from '../../../../reduxToolkit/features/course/CoursesSlice'
import {
  fetchGraduateAttributes,
  updateGraduateAttributeFieldByField,
} from '../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
// import Recommendations from "./Recommendations";
import StarIcon from '@material-ui/icons/Star'
import {
  deleteRecommendation,
  fetchGARecommendations,
} from '../../../../reduxToolkit/features/Recommendations/RecommendationsSlice'
import { fetchStaff } from '../../../../reduxToolkit/features/user/UserSlice'
import AddGARecommendationModal from '../../../Components/AddGARecommendationModal'
import EditGAIndictorsModal from '../../../Components/EditGAIndictorsModal'
import EditGARecommendationModal from '../../../Components/EditGARecommendationModal'
import GA_ActionRecommendationModal from '../../../Components/GA_ActionRecommendationModal'
import useTable from '../../../Components/useTable'
import useTablePlo from '../../../Components/useTablePlo'
import useTableRecommendation from '../../../Components/useTableRecommendation'
import ErrorModal from '../../../Components/ErrorModal'
import {fetchActionTypes} from 'reduxToolkit/features/actionType/ActionTypeSlice'

import {
  fetchGAReportById,
  fetchGAReports,
  UpdateGAReport,
} from '../../../../reduxToolkit/features/GAReport/GAResportSlice'

import { Table, Tag, Space } from 'antd'
import {
  grayColor,
  primaryColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import EditComments from './EditComments'
import Typography from '@material-ui/core/Typography'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <Typography>{record.graduateAttributeConfig_details.name}</Typography>
    ),
  },
  {
    title: 'Internal benchmark',
    dataIndex: 'internal_benchmark',
    key: 'internal_benchmark',
  },
  {
    title: 'External benchmark',
    dataIndex: 'external_benchmark',
    key: 'external_benchmark',
  },
  {
    title: 'Target',
    dataIndex: 'target',
    key: 'target',
  },
  {
    title: 'Direct Assessment',
    dataIndex: 'direct_assessment',
    key: 'direct_assessment',
  },
  {
    title: 'Indirect Assessment',
    dataIndex: 'indirect_assessment',
    key: 'indirect_assessment',
  },
  {
    title: 'Score',
    dataIndex: 'score_by_question',
    key: 'score_by_question',
  },
  // {
  //   title: 'Weakness',
  //   dataIndex: 'weaknesses',
  //   key: 'weaknesses',
  //   render: (_, { weaknesses }) => (

  //     <>
  //         {weaknesses && <Close/>}
  //     </>
  //   ),
  // },
  // {
  //   title: 'Strength',
  //   dataIndex: 'strengths',
  //   key: 'strengths',
  //   render: (_, { strengths }) => (
  //     <>
  //         {strengths && <Close/>}
  //     </>
  //   ),
  // },
]

const { Option, OptGroup } = Select

const useStyles3 = makeStyles(styles1)

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}))
const useStyles1 = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))
const useStyles2 = makeStyles(styles)
const useStyles4 = makeStyles(styles4)

const useCardStyles = makeStyles(cardStyles)
const useButtonStyles = makeStyles(buttonStyles)
const headCells = [
  { id: 'PLOs', label: 'PLOs' },
  { id: 'Satisfactory', label: 'Satisfactory' },
  { id: 'Developing', label: 'Developing' },
  { id: 'Unsatisfactory', label: 'Unsatisfactory' },
  { id: 'edit', label: 'Edit' },
]
const headPloCells = [
  { id: 'PLOs', label: 'PLOs' },
  { id: 'Score', label: 'Score' },
  { id: 'Target', label: 'Target' },
  { id: 'Strength', label: 'Strength' },
  { id: 'Weakness', label: 'Weakness' },
]
const headRecommendationsCells = [
  { id: 'Edit/Delete', label: '' },
  { id: 'Recommendations', label: 'Recommendations' },
  { id: 'Actions', label: 'Actions' },
]
export default function AnalysisGA(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  let { id } = useParams()

  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchGAReportByIdCustomFunction = async () => {
    try {
      const resultAction = await dispatch(fetchGAReportById(id)).unwrap()
      dispatch(fetchProgramById(resultAction.program))
      dispatch(fetchGraduateAttributes(resultAction.program))
      console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchGAReportByIdCustomFunction()
  }, [])
  const GAReport = useSelector((state) => state.GAReport.GAReport)

  const program = useSelector((state) => state.program.program)
  
  const GAs = useSelector((state) => state.GraduateAttribute.GraduateAttributes)

  const GasArrays =
    GAs &&
    GAs.map((ga) => {
      let UniTab = [ga]

      return UniTab
    })
  console.log('GasArrays', GasArrays)

  let obj = {}

  useEffect(() => {
    dispatch(fetchStaff())
    dispatch(fetchActionTypes())
  }, [dispatch])

  const coursesStatus = useSelector((state) => state.course.coursesStatus)
  useEffect(() => {
    dispatch(fetchCourses())
  }, [dispatch])
  const courses = useSelector((state) => state.course.courses)
  const error = useSelector((state) => state.course.error)
  const score = useSelector((state) => state.ploIndicator.ScorePloIndicator)

  const users = useSelector((state) => state.user.staffList)

  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
    console.log('res')
  }
  const [shownComments, setShownComments] = useState({})

  const toggleComment = (id) => {
    setShownComments((prevShownComments) => ({
      ...prevShownComments,
      [id]: !prevShownComments[id],
    }))
    dispatch(fetchGARecommendations(id))
  }
  const GARecommendations = useSelector(
    (state) => state.RecommendationsSlice.GARecommendations,
  )
  const [records, setRecords] = []
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn)
  const { TblPloContainer, TblPloHead } = useTablePlo(
    records,
    headPloCells,
    filterFn,
  )

  const {
    TblRecommendationContainer,
    TblRecommendationHead,
  } = useTableRecommendation(records, headRecommendationsCells, filterFn)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const delete_PloRecommendations = (id, obiId) => {
    dispatch(deleteRecommendation(id)).then(() => {
      dispatch(fetchGARecommendations(obiId))
    })
  }
  const delete_Recommendation = (id, objId) => {
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(fetchGARecommendations(objId))
    })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onChange = async (e, id) => {
    let data = {
      updatedGraduateAttributeSpec: {
        strengths: e.target.value === 's' ? true : false,
        weaknesses: e.target.value === 'w' ? true : false,
      },
      id: id,
    }
    const resultAction = await dispatch(
      updateGraduateAttributeFieldByField(data),
    )

    if (updateGraduateAttributeFieldByField.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchGraduateAttributes(program && program.id))
      openNotificationWithIcon('success', `updated successfully`)
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
        </div>
      )
    } else if (value == 2) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    } else if (value == 3) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    }
  }
  return (
    <div>
      <GridContainer>
        {GasArrays &&
          GasArrays.map((obj, i) => (
            <div
              key={obj[0].id}
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
              }}
            >
              <Card key={i}>
                <CardBody>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      border: '1px solid lightgray',
                      borderRadius: '3px',
                    }}
                  >
                    <GridItem xs={12} sm={12} md={11}>
                      <strong>GA {i}</strong>
                      <strong>
                        <p
                          style={{
                            color: 'gray',
                            textAlign: 'start',
                            fontSize: '14px',
                          }}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title={obj[0].graduateAttributeConfig_details.name}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                            style={{ fontSize: '14px' }}
                          >
                            <div simple color="info" justIcon>
                              {obj[0].graduateAttributeConfig_details.name}
                            </div>
                          </Tooltip>
                        </p>
                      </strong>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      style={{ textAlign: 'end' }}
                    >
                      {obj[0].name ? (
                        <DownOutlined
                          onClick={() => toggleComment(obj[0].id)}
                        />
                      ) : null}
                    </GridItem>
                  </GridContainer>

                  {shownComments[obj[0].id] ? (
                    <div>
                      <Table
                        bordered
                        dataSource={obj || []}
                        columns={columns}
                        pagination={false}
                      />
                      <br></br>
                      <br></br>

                      <GridContainer>
                        <GridItem>
                          <span>Analysis:</span>
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <div>
                        <Radio.Group
                          onChange={(e) => {
                            onChange(e, obj[0].id)
                          }}
                          value={
                            obj[0].weaknesses
                              ? 'w'
                              : obj[0].strengths
                              ? 's'
                              : null
                          }
                        >
                          <Radio value={'s'}>Strength</Radio>
                          <Radio value={'w'}>Weakness</Radio>
                        </Radio.Group>
                        <br></br>
                        <br></br>
                        <GridContainer justify="space-between">
                          <GridItem>
                            <span>Comments:</span>
                          </GridItem>
                          <GridItem>
                            <EditComments
                              id={obj[0].id}
                              program={program && program}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                      <div
                        style={{
                          border: `1px solid  ${grayColor[15]}`,
                          padding: '10px',
                          minHeight: '50px',
                        }}
                      >
                        {obj[0].comments}
                      </div>
                      <div>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginLeft: '20px' }}
                        >
                          <AddGARecommendationModal
                            handleDropDownButtonClose={handleClose}
                            GA_id={obj[0].id}
                            resource={'ga'}
                            resource_id={obj[0].id}
                          />
                        </GridItem>
                        <div
                          style={{
                            margin: '0px 10px 10px 10px ',
                            borderRight: '1px solid lightgray',
                            borderLeft: '1px solid lightgray',
                          }}
                        >
                          <TblRecommendationContainer
                            style={{ marginTop: '0px', paddingTop: '0px' }}
                          >
                            <TblRecommendationHead />
                            <TableBody>
                              {GARecommendations &&
                                GARecommendations.map((item) => (
                                  <TableRow key={item.id}>

                                    <TableCell
                                      className={classes.tableActions}
                                      style={{
                                        borderRight: '1px solid lightgray',
                                      }}
                                    >
                                      
                                      <EditGARecommendationModal
                                        recommendation_id={item.id}
                                        GA_Id={obj[0].id}
                                      />

                                      <Tooltip
                                        id="tooltip-top-start"
                                        title="Remove"
                                        placement="top"
                                        classes={{
                                          tooltip: classes.tooltip,
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Close"
                                          style={{
                                            color: 'red',
                                            fontSize: '10px',
                                          }}
                                          className={classes.tableActionButton}
                                          onClick={() =>
                                            delete_PloRecommendations(
                                              item.id,
                                              obj[0].id,
                                            )
                                          }
                                        >
                                          <Close
                                            className={
                                              classes.tableActionButtonIcon +
                                              ' '
                                              // classes.close
                                            }
                                            style={{
                                              fontSize: '18px',
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        borderRight: '1px solid lightgray',
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: '-webkit-box',
                                          maxWidth: '200px',
                                          WebkitLineClamp: 4,
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {item && item.recommendation}
                                      </p>
                                    </TableCell>

                                    <TableCell
                                      className="multipleLinePlo"
                                      style={{
                                        width: '490px',
                                        //  borderRight: "1px solid lightgray",
                                      }}
                                    >
                                      <GridContainer xs={12} sm={12} md={12}>
                                        <GridItem
                                          xs={12}
                                          sm={12}
                                          md={11}
                                        ></GridItem>

                                        <GridItem xs={12} sm={12} md={1}>
                                          
                                          <GA_ActionRecommendationModal
                                          program_id={program?.id}
                                            id={id}
                                            handleDropDownButtonClose={
                                              handleClose
                                            }
                                            recommendation_id={item.id}
                                            GA_id={obj[0].id}
                                            users={users}
                                            report_id={id}
                                          />
                                        </GridItem>
                                      </GridContainer>

                                      <GridContainer xs={12} sm={12} md={12}>
                                        {item &&
                                          item.action_recommendations &&
                                          item.action_recommendations.map(
                                            (element) => (
                                              <GridContainer
                                                xs={12}
                                                sm={12}
                                                md={12}
                                              >
                                                <GridItem
                                                  xs={12}
                                                  sm={12}
                                                  md={11}
                                                >
                                                  <strong>
                                                    <CheckOutlined />{' '}
                                                    {element.type_action &&
                                                      element.type_action}{' '}
                                                    :
                                                  </strong>
                                                  {element.text && element.text}
                                                  <br></br>
                                                  <strong>
                                                    {' '}
                                                    Assigned to :{' '}
                                                  </strong>
                                                  {element.assign_to}
                                                  <br></br>
                                                  <strong>
                                                    {' '}
                                                    Start date :{' '}
                                                  </strong>
                                                  {element.start_date &&
                                                    element.start_date}
                                                  <br></br>
                                                  <strong> Due date : </strong>
                                                  {element.endDate &&
                                                    element.endDate}
                                                  <br></br>
                                                  <strong>
                                                    {' '}
                                                    Priority :{' '}
                                                    {RaitingRender(
                                                      element &&
                                                        element.priorityId,
                                                    )}
                                                  </strong>

                                                  <br></br>
                                                </GridItem>

                                                <GridItem
                                                  xs={12}
                                                  sm={12}
                                                  md={1}
                                                >
                                                  <DeleteOutlineIcon
                                                    onClick={() =>
                                                      delete_Recommendation(
                                                        element.id,
                                                        obj[0].id,
                                                      )
                                                    }
                                                    style={{
                                                      color: 'red',
                                                      fontSize: '16px',
                                                    }}
                                                  />
                                                </GridItem>
                                              </GridContainer>
                                            ),
                                          )}
                                      </GridContainer>
                                    </TableCell>

                                  </TableRow>
                                ))}
                            </TableBody>
                          </TblRecommendationContainer>
                        </div>
                      </div>
                      <br></br>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </div>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
