import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import Button from "components/CustomButtons/Button.js";
// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchAPRecommendations, fetchRecommendationById,
  updateRecommendation
} from "reduxToolkit/features/Recommendations/RecommendationsSlice";

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useStyles4 = makeStyles(styles4);


export default function EditAPRecommendationModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();
  console.log("props.recommendation_id", props.recommendation_id);
  console.log("props.course_id", props.annualProgram_id);

  const classes4 = useStyles4();
  useEffect(() => {
    dispatch(fetchRecommendationById(props.recommendation_id))
      .then(unwrapResult)
      .then((Recommendation) => {
        setRecommendation(Recommendation.recommendation);
      });
  }, [dispatch, Recommendation]);
  const Recommendation = useSelector(
    (state) => state.RecommendationsSlice.Recommendation
  );

  const [recommendation, setRecommendation] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onRecommendationChanged = (e) => setRecommendation(e.target.value);

  const { Option } = Select;
  const [open, setOpen] = React.useState(false);

  const canSave =
    [recommendation].every(Boolean) && addRequestStatus === "idle";
  const history = useHistory();

  const onUpdateRecommendationClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        updateRecommendation({
          id: props.recommendation_id,
          updatedRecommendation: {
            recommendation,
          },
        })
      );
      unwrapResult(resultAction);
      setRecommendation("");
      props.fetchRecs();
    } catch (err) {
    } finally {
      dispatch(fetchAPRecommendations(props.annualProgram_id))
      setAddRequestStatus("idle");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip
        id="tooltip-top"
        title="Edit"
        placement="top"
        classes={{
          tooltip: classes4.tooltip,
        }}
      >
        <IconButton
          aria-label="Edit"
          className={classes4.tableActionButton}
          onClick={(event) => {
            handleClickOpen(event);
          }}
        >
          <Edit
            className={classes4.tableActionButtonIcon + " " + classes4.edit}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Recommendation")}
        </DialogTitle>
        <DialogContent>
          <form>
            <GridContainer xs={12} sm={12} md={12} style={{ width: "700px" }}>
              <GridItem xs={12} sm={12} md={3}>
                <label>{t("recommendation*")} </label>
              </GridItem>

              <GridItem xs={12} sm={12} md={9}>
                <TextArea
                  rows={6}
                  id="recommendation"
                  value={recommendation}
                  onChange={(event) => setRecommendation(event.target.value)}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              onUpdateRecommendationClicked();
              handleClose();
            }}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
