import React, { Suspense, useEffect, useState } from 'react'

import AuthLayout from 'layouts/Auth.js'
import AdminLayout from 'layouts/Admin.js'

import "antd/dist/antd.css"
import 'assets/css/styles.css'
import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0'
import './i18n'

import useAuth from "./hooks/useAuth";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "./reduxToolkit/features/user/UserSlice";

const App = () => {

    const { getCurrentUser, checkStorage } = useAuth();

    const { currentUser } = useSelector((state) => state.user);
    const { isFetching } = useSelector(userSelector);

    useEffect(() => {
        getCurrentUser().then((x) => {
            console.log(x)
        });
    }, []);

    const current_location = window.location.pathname;

    return (<Suspense fallback="loading">
        <BrowserRouter>
            <Switch>
                {checkStorage() ? (isFetching) ? <>
                    loading... {isFetching}
                </> : currentUser ? <>
                    <Route path="/citrine" render={(props) => <AdminLayout {...props} />} /> :
                    <Redirect from="/" to={current_location.slice(0, 8) === '/citrine' ? current_location : '/citrine'} />
                </> : <>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Redirect from="/" to="/auth/login-page" />
                </> : <>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Redirect from="/" to="/auth/login-page" />
                </>}
            </Switch>
        </BrowserRouter>
    </Suspense>)
}

export default App;
