import { DownOutlined } from "@ant-design/icons";
import {
  Avatar
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PeoReportModal from "views/Components/PeoReportModal";
import { fetchPeosByProgramId, rerunPEOsScores } from "../../../../reduxToolkit/features/PEO/PEOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import EditWeightPlo_PeoModal from "../../../Components/EditWeightPlo_PeoModal";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Button from "../../../../components/CustomButtons/Button";
import {
  primaryColor,
  greyColor,
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function PeoListPage({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = match.params;

  const [open, setOpen] = React.useState(false);
  const handleOpenReportModal = () => {
    setOpen(true);
  };
  const handleCloseReportModal = () => {
    setOpen(false);
  };

  const [openEditPLOWeightModal, setOpenEditPLOWeightModal] = useState(false);
  const [plo, setPlo] = useState();

  const handleOpenEditPLOWeightModal = (ploIndicator) => {
    setOpenEditPLOWeightModal(true);
    setPlo(ploIndicator)
    console.log(ploIndicator)
  };
  const handleCloseEditPLOWeightModal = () => {
    setOpenEditPLOWeightModal(false);
  };

  const columns = [
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'name', headerName: 'Title', flex: 1 },
    { field: 'target', headerName: 'Target', flex: 1 },
    { field: 'weight', headerName: 'Weight', flex: 1 },
    { field: 'plo_score', headerName: 'Score', flex: 1 },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderCell: (params) => (
        <Tooltip
          id="tooltip-top"
          title="Edit PLO's Weight"
          placement="bottom"
        >
          <Button
            round
            justIcon
            simple
            onClick={() => handleOpenEditPLOWeightModal(params.row)}
          >
            <EditIcon fontSize="sm" color="action" />
          </Button>
        </Tooltip>
      ),
    },

  ];

  const rerunPEOs = () => {

    dispatch(rerunPEOsScores({ program: id }))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        dispatch(fetchPeosByProgramId(id));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      })
  }

  useEffect(() => {
    dispatch(fetchProgramById(id))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        dispatch(fetchPeosByProgramId(id)).unwrap()
      }).then(() => {
        rerunPEOs()
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(rejectedValueOrSerializedError)
      })
  }, []);



  const program = useSelector((state) => state.program.program);
  const ProgramPeos = useSelector((state) => state.peo.ProgramPeos);

  return (
    <div>
      <Breadcrumb separator=">" params={id}>
        <Breadcrumb.Item>
          <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${id}`}>
            {program?.program_degree_name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/program/${id}/PeoListPage/`}
            style={{ color: "orange" }}
          >
            {t("Peo List")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>


      <Card style={{ height: "fit-content", paddingBottom: 10 }}>
        <CardHeader
          color="info"
          icon
        >
          <GridContainer alignItems="center">

            <GridItem xs={12} sm={2} md={2}>
              <CardIcon
                color="primary"
              >
                <SchoolRounded />
              </CardIcon>
            </GridItem>

            <GridItem xs={12} sm={10} md={10}>
              <GridContainer justifyContent="space-between" alignItems="center" wrap="nowrap">
                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program?.code}</strong>
                  </h4>
                  <h4
                    style={{
                      color: "#6C7B9D",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>
                      {program?.program_degree_name}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong> {t("University")} </strong>

                  </h4>
                  <h4
                    style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                  >
                    <strong>
                      {program?.university_detail?.map((el) => el.name)}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <CustomDropdown
                    dropPlacement="bottom-end"
                    isButtonIcon
                    dropdownList={[
                      { text: "Rerun PEOs Scores", onClickHandler() { rerunPEOs() } },
                      { text: "Create PEO Report", onClickHandler() { handleOpenReportModal() } },
                    ]}
                  />
                  {/* <p>last refreshed at : 22/02/2024</p> */}
                </GridItem>

              </GridContainer>
            </GridItem>
          </GridContainer>

        </CardHeader>
      </Card>
      <br></br>





      {ProgramPeos?.length > 0 && <>
        <p style={{ fontFamily: "Georgia", fontSize: "16px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>PEOs</p>
        <Card >
          <CardBody>
            <GridContainer>
              {ProgramPeos?.map((peo) => (
                <GridItem xs={12} sm={12} md={12} key={peo.id} style={{ border: "1px solid #EEEEEE", borderRadius: 4, margin: 4 }} >
                  <GridContainer alignItems="center">
                    <GridItem xs={12} sm={12} md={12}>

                      <strong>{peo.code}: </strong>
                      <Tooltip
                        id="tooltip-top"
                        title={peo.name}
                        placement="bottom"
                      >
                        <span>
                          {peo.name.slice(0, 100)}...
                        </span>
                      </Tooltip>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer alignItems="baseline" justifyContent="space-around">
                        <GridItem>
                          <GridContainer direction="column" justifyContent="center" alignItems="center" >
                            <GridItem>
                              <Avatar
                                style={{
                                  height: "50px",
                                  width: "50px", backgroundColor: primaryColor[0]
                                }}
                              >
                                {peo?.plos?.length}
                              </Avatar>
                            </GridItem>
                            <GridItem>
                              <p style={{ marginTop: "10px", fontSize: "12px" }}>
                                {t("Aligned PLOs")}
                              </p>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer direction="column" justifyContent="center" alignItems="center" >
                            <GridItem>
                              <Avatar
                                style={{
                                  height: "50px",
                                  width: "50px", backgroundColor: primaryColor[0]
                                }}
                              >
                                {peo?.peo_score?.toFixed()}%
                              </Avatar>
                            </GridItem>
                            <GridItem>
                              <p style={{ marginTop: "10px", fontSize: "12px" }}>
                                {t("Score")}
                              </p>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>

                  <div style={{ height: 300, width: '100%' }}>
                    <DataGrid rows={peo.plos} columns={columns} disableRowSelectionOnClick />
                  </div>

                  <EditWeightPlo_PeoModal
                    openEditPLOWeightModal={openEditPLOWeightModal}
                    handleCloseEditPLOWeightModal={handleCloseEditPLOWeightModal}
                    ploID={plo?.id}
                    plo={plo}
                    peo_id={plo?.peo_id}
                    ProgramID={program?.id}
                  />
                </GridItem>
              ))}
            </GridContainer>
          </CardBody>
        </Card >
      </>}

      <PeoReportModal
        ProgramID={program?.id}
        handleCloseReportModal={handleCloseReportModal}
        open={open}
      />
    </div>
  );
}
