// @material-ui/core components
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import {
  blackColor,
  hexToRgb,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchPeoReportById, fetchPeoReports, UpdatePeoReport
} from "../../../../reduxToolkit/features/PeoReport/PeoReportSlice";
import { fetchPEOReportDocumentsByProgramId } from "../../../../reduxToolkit/features/PEOReportDocument/PEOReportDocumentSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import DropDownPEOImprovementReportButton from "../../../Components/DropDownPEOImprovementReportButton";
import PreviousActionPlan from "../../../Components/PreviousActionPlan";
import Analysis from "./AnalysisPEO";
import AssessmentAnalysis from "./AssessmentAnalysis";
import Stepper from "./Stepper";
import CircularProgress from "@mui/material/CircularProgress";
import CreateReportPEOImprovementDocument from "../../../Components/CreateReportPEOImprovementDocument";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";

const { TabPane } = Tabs;

export default function PEOImprovementDetails({ match }) {

  const { t } = useTranslation();
  const { id } = match.params;
  const dispatch = useDispatch();
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);

  useEffect(() => {
    dispatch(fetchPeoReportById(id)).unwrap()
      .then((PeoReport) => {
        // handle result here
        setStateName(PeoReport.state_object)
        dispatch(fetchPEOReportDocumentsByProgramId(PeoReport.program));
        dispatch(fetchProgramById(PeoReport.program));
      })
  }, []);

  const PeoReport = useSelector((state) => state.PeoReport.PeoReport);
  const peoReportDocuments = useSelector((state) => state.peoReportDocument.peoReportDocuments);
  const program = useSelector((state) => state.program.program);

  const peoReportDocumentsSection =
    <div>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <CreateReportPEOImprovementDocument
            title={"Create Peos Improvement Document Report"}
            programId={PeoReport?.program}
          />
        </GridItem>
      </GridContainer>
      {peoReportDocuments?.map((peoReportDocument) => (
        <Card style={{ backgroundColor: grayColor[8] }}>
          <GridContainer justifyContent="space-between">
            <GridItem>
              <Link
                to={`/citrine/PEOImprovementDetails/${id}/PEOReportDocument/${peoReportDocument.id}`}
              >
                <h6> {peoReportDocument.name}</h6>
                <br />
                <h7>State : {peoReportDocument.state}</h7>
                <br />
                <h7>Created By: {peoReportDocument.creator_details?.username}</h7>
                <br />
                <h7>Created On: {peoReportDocument.creation_date}</h7>
                <br />
                <h7>{`Assigned To: ${peoReportDocument.report_details?.assign_to_details?.[0]?.first_name} ${peoReportDocument.report_details?.assign_to_details?.[0]?.last_name}`}</h7>
                <br />
                <h7>Version: {peoReportDocument.version} </h7>
                <br />
              </Link>
            </GridItem>

            <GridItem>
              <DropDownPEOImprovementReportButton
                id={peoReportDocument.id}
                progId={PeoReport?.program}
              />
            </GridItem>
          </GridContainer>
        </Card>
      ))}
    </div>

  const peoReportDetails = (

    <div style={{ border: "1px solid lightgray" }}>
      <GridContainer>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("Program Name")} </h5>
          <p style={{ fontSize: "12px" }}>
            {program?.program_degree_name}
          </p>
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("Program Code")}</h5>
          <p style={{ fontSize: "12px" }}>
            {program?.code}
          </p>
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("Program Category")}</h5>
          <p style={{ fontSize: "12px" }}>
            {program?.category_of_the_program_details?.[0]?.name}
          </p>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("Department Name")}</h5>
          <p style={{ fontSize: "12px" }}>
            {program?.department_detail?.[0]?.name}
          </p>
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("College Name")}</h5>
          <p style={{ fontSize: "12px" }}>
            {program?.college_detail?.[0]?.name}
          </p>
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {t("University Name")}</h5>
          <p style={{ fontSize: "12px" }}>
            {program?.university_detail?.[0]?.name}
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("PeoReport_State", "Approved");
    try {
      let modifiedPeoReport = {
        due_date: PeoReport && PeoReport.due_date && PeoReport.due_date,
        state_object: "Approved",
      };
      const resultAction = dispatch(
        UpdatePeoReport({
          id: id,
          updatedPeoReport: { ...modifiedPeoReport },
        }),
        dispatch(fetchPeoReports())
      );
      unwrapResult(resultAction);
      setStateName("Approved");
      dispatch(fetchPeoReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the Report: ", err);
    } finally {
      dispatch(fetchPeoReports());
    }
  };

  const SendforApproval = () => {
    setShow(true);
    setStateName("Waiting for approval");
    localStorage.setItem("PeoReport_State", "Waiting for approval");
    try {
      let modifiedPeoReport = {
        due_date: PeoReport && PeoReport.due_date && PeoReport.due_date,
        state_object: "Waiting for approval",
      };
      const resultAction = dispatch(
        UpdatePeoReport({
          id: id,
          updatedPeoReport: { ...modifiedPeoReport },
        }),
        dispatch(fetchPeoReports())
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchPeoReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the Report: ", err);
    } finally {
      dispatch(fetchPeoReports());
    }
  };

  const ResetTODraft = () => {
    setStateName("Created");
    localStorage.setItem("PeoReport_State", "Created");
    try {
      let modifiedPeoReport = {
        due_date: PeoReport && PeoReport.due_date && PeoReport.due_date,
        state_object: "Created",
      };
      const resultAction = dispatch(
        UpdatePeoReport({
          id: id,
          updatedPeoReport: { ...modifiedPeoReport },
        }),
        dispatch(fetchPeoReports())
      );
      unwrapResult(resultAction);
      setStateName("Created");
      dispatch(fetchPeoReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the Report: ", err);
    } finally {
      dispatch(fetchPeoReports());
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    localStorage.setItem("PeoReport_State", "Archived");
    try {
      let modifiedPeoReport = {
        due_date: PeoReport && PeoReport.due_date && PeoReport.due_date,
        state_object: "Archived",
      };
      const resultAction = dispatch(
        UpdatePeoReport({
          id: id,
          updatedPeoReport: { ...modifiedPeoReport },
        }),
        dispatch(fetchPeoReports())
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchPeoReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the Report: ", err);
    } finally {
      dispatch(fetchPeoReports());
    }
  };

  if (!(PeoReport && program)) {
    return (
      <GridContainer style={{ height: "100%" }} justifyContent="center" alignItems="center"><GridItem><CircularProgress color="inherit" /></GridItem></GridContainer>
    );
  }

  return (
    <div style={{
      height: "100%"
    }}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/PeoImprovementList/`}>
            {t("PEO Reports List")}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          style={{ fontWeight: 400 }}>
          <Link
            to={`/citrine/PeoImprovementDetails/${id}`}
          >
            {program?.program_degree_name}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {PeoReport && <Stepper PeoReport={PeoReport} ResetTODraft={ResetTODraft} stateName={stateName} Approve={Approve} SendforApproval={SendforApproval} Archive={Archive} />}
      <GridContainer>
        <GridItem xs={12} sm={9} md={9}>
          <div style={{
            padding: "10px",
            background: whiteColor,
            width: "100%",
            boxShadow:
              "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
            height: "100%",
            overflowY: "auto"
          }}>
            {peoReportDetails}
            <Tabs>
              <TabPane tab={t("PEOs & Recommendations ")} key="1">
                <Analysis
                  idProgram={
                    PeoReport && PeoReport.program && PeoReport.program
                  }
                />
              </TabPane>
              <TabPane tab={t("Previous Action Plan")} key="3">
                <PreviousActionPlan
                  resource_id={PeoReport && PeoReport.id}
                  resource="Peo"
                />
              </TabPane>
              <TabPane tab={t("Assessment Analysis")} key="4">
                <AssessmentAnalysis
                  PeoReport={PeoReport && PeoReport}
                />
              </TabPane>
              <TabPane tab={t("Dynamic section")} key="5">
                <HTMLEditor />
              </TabPane>
            </Tabs>
          </div>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
          <div
            style={{
              padding: "10px",
              background: whiteColor,
              width: "100%",
              boxShadow:
                "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
              height: "100%",
              overflowY: "auto"

            }}
          >
            {peoReportDocumentsSection}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
