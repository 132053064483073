import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BulbOutlined, TagOutlined } from "@ant-design/icons";
import { Avatar } from "@material-ui/core";
// @material-ui/core components
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Progress, Tooltip } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//chartist
import { fetchGraphGa } from "reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
import { fetchGraphPeo } from "reduxToolkit/features/PEO/PEOsSlice";
import { fetchGraphPlo } from "../../../../reduxToolkit/features/PLO/PLOsSlice";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import { simpleBarChart } from "variables/charts.js";
import CourseLogo from "../../../../assets/img/CourseLogo.png";
import NoDataFound from "../../../../assets/img/NoDataFound.png";
import DropDownButtonAnnualProgram_PES_SES from "../../../Components/DropDownButtonAnnualProgram_PES_SES";
import DropDownButton from "../../../Components/DropDownButtonGoToAssessment";
import ErrorModal from "../../../Components/ErrorModal";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Code from "components/Typography/Code.js";

const useCardStyles = makeStyles(cardStyles);

export default function CoursesList({ match }) {
  const cardClasses = useCardStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = match.params;

  const [program, setProgram] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const GraphPlo = useSelector((state) => state.plo.GraphPlo);
  const GraphPeo = useSelector((state) => state.peo.GraphPeo);
  const GraphGa = useSelector((state) => state.GraduateAttribute.GraphGa);
  const staffList = useSelector((state) => state.user.staffList);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchProgramAss = async () => {
    try {
      const resultAction = await dispatch(fetchProgramById(id)).then(unwrapResult).then((result) => {
        setProgram(result)
      });
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchProgramAss();
    dispatch(fetchGraphPlo(id))
    dispatch(fetchGraphPeo(id))
    dispatch(fetchGraphGa(id))
  }, []);

  useEffect(() => {
    dispatch(fetchStaff())
  }, []);

  return (
    <div>
      <Breadcrumb separator=">" params={id}>
        <Breadcrumb.Item>
          <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${id}`} style={{ color: "orange" }}>
            {program?.program_degree_name}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>
      <Card style={{ height: "fit-content", paddingBottom: 10 }}>
        <CardHeader
          color="info"
          icon
        >
          <GridContainer alignItems="center">

            <GridItem xs={12} sm={2} md={2}>
              <CardIcon
                color="primary"
              >
                <SchoolRounded />
              </CardIcon>
            </GridItem>

            <GridItem xs={12} sm={10} md={10}>
              <GridContainer justifyContent="space-between" alignItems="center" wrap="nowrap">
                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program?.code}</strong>
                  </h4>
                  <h4
                    style={{
                      color: "#6C7B9D",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>
                      {program?.program_degree_name}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong> {t("University")} </strong>

                  </h4>
                  <h4
                    style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                  >
                    <strong>
                      {program?.university_detail?.map((el) => el.name)}
                    </strong>
                  </h4>
                </GridItem>
                <GridItem>
                  <DropDownButtonAnnualProgram_PES_SES
                    ProgramID={id}
                    data={program && program}
                    staffList={staffList}
                    semester_id={program && program.semester_id}
                    fetchProgAss={fetchProgramAss}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      <br></br>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card style={{ height: '300px' }}>
            <CardBody>
              <Link to={`/citrine/program/${id}/PloListPage/`}>
                <strong
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title={t("(PLOs) Program Learning Outcomes")} placement="top">
                    {
                      t("(PLOs) Program Learning Outcomes")?.slice(0, 42) + '...'
                    }
                  </Tooltip>
                </strong>
              </Link>
              <Progress
                strokeLinecap="square"
                percent={`${program?.achievement_plo?.toFixed(0)}`}
              />
              <br />
              <br />
              {GraphPlo ? (
                <div>
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={GraphPlo && JSON.parse(JSON.stringify(GraphPlo))}
                    type="Bar"
                    options={simpleBarChart.options}
                    responsiveOptions={simpleBarChart.responsiveOptions}
                    listener={simpleBarChart.animation}
                    style={{ backgroundColor: "#EA9E4D" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#EA9E4D",
                    }}
                  >
                    <p> {t("Score")}</p>
                    <p
                      style={{
                        backgroundColor: "aliceblue",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",
                      }}
                    ></p>

                    <p> {t("Target")}</p>
                    <p
                      style={{
                        backgroundColor: "#e94e49",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",

                      }}
                    ></p>
                  </div>
                </div>
              ) : (
                <img src={NoDataFound} width="100%" height="185" />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card style={{ height: '300px' }}>
            <CardBody>
              <Link to={`/citrine/program/${id}/PeoListPage/`}>
                <strong
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title={t("(PEOs) Program Educational Objectives")} placement="top">
                    {
                      t("(PEOs) Program Educational Objectives")?.slice(0, 42) + '...'
                    }
                  </Tooltip>
                </strong>
              </Link>
              <Progress strokeLinecap="square" percent={`${program?.achievement_peo?.toFixed(0)}`} />
              <br />
              <br />
              {GraphPeo ? (
                <div>
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={GraphPeo && JSON.parse(JSON.stringify(GraphPeo))}
                    type="Bar"
                    options={simpleBarChart.options}
                    responsiveOptions={simpleBarChart.responsiveOptions}
                    listener={simpleBarChart.animation}
                    style={{ backgroundColor: "#EA9E4D" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#EA9E4D",
                    }}
                  >
                    <p> {t("Score")}</p>
                    <p
                      style={{
                        backgroundColor: "aliceblue",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",
                      }}
                    ></p>
                    <p> {t("Target")}</p>
                    <p
                      style={{
                        backgroundColor: "#e94e49",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",
                      }}
                    ></p>
                  </div>
                </div>
              ) : (
                <img src={NoDataFound} width="100%" height="185" />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card style={{ height: '300px' }}>
            <CardBody>
              <Link to={`/citrine/program/${id}/GAListPage/`}>
                <strong
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "16px",
                  }}
                >
                  <Tooltip title={t("(GAs) Graduate Attributes")} placement="top">
                    {
                      t("(GAs) Graduate Attributes")?.slice(0, 42) + '...'
                    }
                  </Tooltip>
                </strong>
              </Link>
              <Progress strokeLinecap="square" percent={`${program?.achievement_GAs?.toFixed(0)}`} />
              <br />
              <br />
              {GraphGa ? (
                <div>
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={GraphGa && JSON.parse(JSON.stringify(GraphGa))}
                    type="Bar"
                    options={simpleBarChart.options}
                    responsiveOptions={simpleBarChart.responsiveOptions}
                    listener={simpleBarChart.animation}
                    style={{ backgroundColor: "#EA9E4D" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#EA9E4D",
                    }}
                  >
                    <p> {t("Score")}</p>
                    <p
                      style={{
                        backgroundColor: "aliceblue",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",
                      }}
                    ></p>
                    <p> {t("Target")}</p>
                    <p
                      style={{
                        backgroundColor: "#e94e49",
                        width: "30px",
                        marginTop: "7px",
                        height: "7px",
                        margin: "10px",
                      }}
                    ></p>
                  </div>
                </div>
              ) : (
                <img src={NoDataFound} width="100%" height="185" />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <br></br>
      <GridContainer>
        {program?.courses?.map((course) => (
          <GridItem xs={12} sm={12} md={12} lg={6} key={program.id}>
            <Card>
              <CardBody>
                <div style={{ marginTop: "10px" }}><img src={CourseLogo} width="100%" height="auto" /></div>
                <GridContainer justifyContent="space-between" alignItems="baseline" wrap="nowrap">
                  <GridItem xs={10} sm={10} md={10}>
                    <Code >
                      {program.code}
                    </Code>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <div style={{ textAlign: "end", zIndex: "1000" }}>
                      <DropDownButton
                        courseID={course.id}
                        course={course}
                        program={program}
                        semester_id={program.semester_id}
                        staffList={staffList}
                        fetchProgAss={fetchProgramAss}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <strong>
                      <p>
                        {program?.program_degree_name?.toUpperCase()}
                      </p>
                    </strong>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <Code>{course.code}</Code>
                  </GridItem>
                  <GridItem>
                    <strong>
                      <p>{course.title}</p>
                    </strong>
                  </GridItem>
                </GridContainer>
                {course?.course_coordinator?.length > 0 &&
                  <GridContainer>
                    <GridItem>
                      <strong>
                        <p>Coordinators:</p>
                      </strong>
                    </GridItem><GridItem>
                      {course.course_coordinator.map(
                        (course, key) =>
                          <p key={key}>  {course.first_name} {course.last_name}</p>
                      )
                      }
                    </GridItem>
                  </GridContainer>
                }
                <p style={{ fontSize: "12px" }}>
                  {course.description.slice(0, 150)}
                  ...
                </p>
                <br />
                <GridContainer alignItems="baseline" justifyContent="space-around">
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#968988",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {course?.clos_count}
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#968988" }}>
                          {t("CLOs")}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#9B6F72",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {course?.students_count}
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#9B6F72" }}>
                          {t("Students")}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#E0C4A4",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {course.assessments_by_question_count}
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#E0C4A4" }}>
                          {t("Assessments")}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#574B56",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {course?.score_course_by_question?.[0]?.score_by_course?.toFixed(
                            0
                          )} %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#574B56" }}>
                          {t("Score")}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div >
  );
}
