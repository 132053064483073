// const BaseUrl = "https://demo.citrine.university/api";
// export const MediaBaseUrl = "https://demo.citrine.university/media";
// export const HistoryBaseUrl = "https://demo.citrine.university/";

// const BaseUrl = "http://demo.citrine.university:8000/api";
// export const MediaBaseUrl = "http://demo.citrine.university:8000/media";

//back 141 with build adress
//const BaseUrl = "https://api.citrine.university/api";
//export const MediaBaseUrl = "https://api.citrine.university/media/";
//export const DocumentBaseUrl = 'https://api.citrine.university/media/';
//export const HistoryBaseUrl = "https://api.citrine.university/";
//export const MediaBaseUrlAvatar = 'https://api.citrine.university';

// localhost configuration
const BaseUrl = "https://ubt-test-api.citrine.university/api";
export const MediaBaseUrl = "https://ubt-test-api.citrine.university/media/";
export const DocumentBaseUrl = "https://ubt-test-api.citrine.university/media/";
export const HistoryBaseUrl = "https://ubt-test-api.citrine.university/";
export const MediaBaseUrlAvatar = 'https://ubt-test-api.citrine.university';

// const BaseUrl = "http://51.68.49.194:8001/api";
// export const MediaBaseUrl = "http://51.68.49.194:8001/media/";
// export const DocumentBaseUrl = "http://51.68.49.194:8001/media/";
// export const HistoryBaseUrl = "http://51.68.49.194:8001/";
// export const MediaBaseUrlAvatar = 'http://51.68.49.194:8001';
// Server configuration 152
// const BaseUrl = 'http://152.228.211.15:8000/api'
// export const MediaBaseUrl = 'http://152.228.211.15:8000/media'

//back for local front
// const BaseUrl = "http://141.94.175.167:8004/api";
// export const MediaBaseUrl = "http://141.94.175.167:8004/media/";
// export const DocumentBaseUrl = "http://141.94.175.167:8004/media/";
// export const HistoryBaseUrl = "http://141.94.175.167:8004/";
// export const MediaBaseUrlAvatar = 'http://141.94.175.167:8004';

export default BaseUrl;
