import {
  CheckOutlined, DownOutlined
} from "@ant-design/icons";
import {
  TableBody, TableCell, TableRow
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "antd/dist/antd.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteActionRecommendation } from "../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import {
  fetchCourses
} from "../../../../reduxToolkit/features/course/CoursesSlice";
import {
  fetchPeosByProgramId
} from "../../../../reduxToolkit/features/PEO/PEOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import StarIcon from "@material-ui/icons/Star";
import {
  deleteRecommendation, fetchPeoRecommendations
} from "../../../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import AddPeoRecommendationModal from "../../../Components/AddPeoRecommendationModal";
import EditPeoRecommendationModal from "../../../Components/EditPeoRecommendationModal";
import PEO_ActionRecommendationModal from "../../../Components/PEO_ActionRecommendationModal";
import useTablePlo from "../../../Components/useTablePlo";
import useTableRecommendation from "../../../Components/useTableRecommendation";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const headPloCells = [
  { id: "PLOs", label: "PLOs " },
  { id: "Score", label: "Score" },
  { id: "Target", label: "Target" },
  { id: "Strength", label: "Strength" },
  { id: "Weakness", label: "Weakness" },
];

const headRecommendationsCells = [
  { id: "Edit/Delete", label: "" },
  { id: "Recommendations", label: "Recommendations" },
  { id: "Actions", label: "Actions" },
];

export default function AnalysisPEO(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  let { id } = useParams();

  const coursesStatus = useSelector((state) => state.course.coursesStatus);
  const Peos = useSelector((state) => state.peo.ProgramPeos);
  const users = useSelector((state) => state.user.staffList);
  const PeoRecommendations = useSelector((state) => state.RecommendationsSlice.PeoRecommendations);

  const [anchorEl, setAnchorEl] = useState(null);
  const [shownComments, setShownComments] = useState({});
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  let obj = {};
  useEffect(() => {
    dispatch(fetchProgramById(props.idProgram));
    dispatch(fetchPeosByProgramId(props.idProgram));
  }, []);

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  useEffect(() => {
    if (coursesStatus === "idle") {
      dispatch(fetchCourses());
    }
  }, []);

  const toggleComment = (id) => {
    setShownComments((prevShownComments) => ({
      ...prevShownComments,
      [id]: !prevShownComments[id],
    }));
    dispatch(fetchPeoRecommendations(id));
  };

  const [records, setRecords] = [];

  const { TblPloContainer, TblPloHead } = useTablePlo(
    records,
    headPloCells,
    filterFn
  );

  const { TblRecommendationContainer, TblRecommendationHead } = useTableRecommendation(records, headRecommendationsCells, filterFn);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const delete_PeoRecommendations = (id, obiId) => {
    dispatch(deleteRecommendation(id)).then(() => {
      dispatch(fetchPeoRecommendations(obiId));
    });
  };

  const delete_Recommendation = (id, objId) => {
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(fetchPeoRecommendations(objId));
    });
  };

  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    } else if (value == 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    } else if (value == 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    }
  };

  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        {Peos &&
          Peos.map((obj, i) => (
            <div
              key={obj.id}
              style={{
                width: "100%",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <Card key={i}>
                <CardBody>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "3px",
                    }}
                  >
                    <GridItem xs={12} sm={12} md={11}>
                      <strong style={{padding: 5, backgroundColor: 'indianred', borderRadius: 20, color: 'white'}}>PEO No.{i}</strong>
                      <strong>
                        <p
                          style={{
                            color: "gray",
                            textAlign: "start",
                            fontSize: "14px",
                          }}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title={obj.name}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                            style={{ fontSize: "14px", marginTop: 10 }}
                          >
                            <Stack direction="row" spacing={1} alignItems={'center'}>
                              <span style={{color: 'indianred'}}>PEO Name:</span>
                              <strong>{obj.name}</strong>
                            </Stack>
                          </Tooltip>
                        </p>
                      </strong>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      style={{ textAlign: "end" }}
                    >
                      {obj.name ? (
                        <DownOutlined onClick={() => toggleComment(obj.id)} />
                      ) : null}
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12} sm={12} md={12}>
                    {shownComments[obj.id] ? (
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <div>
                            <div
                              style={{
                                margin: "0px 10px 10px 10px ",
                                borderRight: "1px solid lightgray",
                                borderLeft: "1px solid lightgray",
                              }}
                            >
                              <TblPloContainer
                                style={{ marginTop: "0px", paddingTop: "0px" }}
                              >
                                <TblPloHead />
                                <TableBody>
                                  {obj && obj.plos.map((item) => (
                                    <TableRow key={item.id}>
                                      <TableCell style={{ borderRight: "1px solid lightgray" }}>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            maxWidth: "200px",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {item && item.name}
                                        </p>
                                      </TableCell>
                                      <TableCell className="multipleLine" style={{ borderRight: "1px solid lightgray" }}>
                                        {item && item.plo_score}
                                      </TableCell>
                                      <TableCell className="multipleLine" style={{ borderRight: "1px solid lightgray" }}>
                                        {item && item.target}
                                      </TableCell>
                                      <TableCell className="multipleLine" style={{ borderRight: "1px solid lightgray" }}>
                                        {item && item.strengths ?
                                          <CheckOutlined />
                                          :
                                          <p></p>
                                        }
                                      </TableCell>
                                      <TableCell className="multipleLine">
                                        {item && item.weaknesses ?
                                          <CheckOutlined />
                                          :
                                          <p></p>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </TblPloContainer>
                            </div>
                          </div>
                          <br></br>
                          <div>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ marginLeft: "20px" }}
                            >
                              <Stack direction="row" spacing={1} alignItems={'center'}>
                                <span>Recommendations:</span>
                                <div>
                                  <AddPeoRecommendationModal
                                      handleDropDownButtonClose={handleClose}
                                      peo_id={obj.id}
                                  />
                                </div>
                              </Stack>
                            </GridItem>
                            <div
                              style={{
                                margin: "0px 10px 10px 10px ",
                                borderRight: "1px solid lightgray",
                                borderLeft: "1px solid lightgray",
                              }}
                            >
                              <TblRecommendationContainer
                                style={{ marginTop: "0px", paddingTop: "0px" }}
                              >
                                <TblRecommendationHead />
                                <TableBody>
                                  {PeoRecommendations &&
                                    PeoRecommendations.map((item) => (
                                      <TableRow key={item.id}>
                                        <TableCell
                                          style={{
                                            borderRight: "1px solid lightgray",
                                          }}
                                        >
                                          <p
                                            style={{
                                              display: "-webkit-box",
                                              maxWidth: "200px",
                                              WebkitLineClamp: 4,
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {item && item.recommendation}
                                          </p>
                                        </TableCell>
                                        <TableCell
                                          className="multipleLinePlo"
                                          style={{
                                            width: "490px",
                                            //  borderRight: "1px solid lightgray",
                                          }}
                                        >
                                          <GridContainer
                                            xs={12}
                                            sm={12}
                                            md={12}
                                          >
                                            <GridItem
                                              xs={12}
                                              sm={12}
                                              md={11}
                                            ></GridItem>
                                            <GridItem xs={12} sm={12} md={1}>
                                              <PEO_ActionRecommendationModal
                                                handleDropDownButtonClose={
                                                  handleClose
                                                }
                                                recommendation_id={item.id}
                                                peo_id={obj.id}
                                                users={users || []}
                                                report_id={id}
                                              />
                                            </GridItem>
                                          </GridContainer>
                                          <GridContainer
                                            xs={12}
                                            sm={12}
                                            md={12}
                                          >
                                            {item &&
                                              item.action_recommendations &&
                                              item.action_recommendations.map(
                                                (element) => (
                                                  <GridContainer
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={11}
                                                    >
                                                      <strong>
                                                        <CheckOutlined />{" "}
                                                        {element.type_action && element.type_action}{" "}
                                                        :
                                                      </strong>
                                                      {element.text && element.text}
                                                      <br></br>
                                                      <strong>
                                                        {" "}
                                                        Assigned to :  {" "}
                                                      </strong>
                                                      {element.assign_to}
                                                      <br></br>
                                                      <strong>
                                                        {" "}
                                                        Start date : {" "}
                                                      </strong>
                                                      {element.start_date && element.start_date}
                                                      <br></br>
                                                      <strong>
                                                        {" "}
                                                        Due date : {" "}
                                                      </strong>
                                                      {element.endDate && element.endDate}
                                                      <br></br>
                                                      <strong>
                                                        {" "}
                                                        Priority : {RaitingRender(element && element.priorityId)}
                                                      </strong>
                                                      <br></br>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={1}
                                                    >
                                                      <DeleteOutlineIcon
                                                        onClick={() =>
                                                          delete_Recommendation(
                                                            element.id,
                                                            obj.id
                                                          )
                                                        }
                                                        style={{
                                                          color: "red",
                                                          fontSize: "16px",
                                                        }}
                                                      />
                                                    </GridItem>
                                                  </GridContainer>
                                                )
                                              )}
                                          </GridContainer>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableActions}
                                            style={{
                                              borderRight: "1px solid lightgray",
                                            }}
                                        >
                                          <Stack direction="row" spacing={1} justifyContent={'center'} alignItems={'center'}>
                                            <EditPeoRecommendationModal
                                                recommendation_id={item.id}
                                                Peo_Id={obj.id}
                                            />
                                            <Tooltip
                                                id="tooltip-top-start"
                                                title="Remove"
                                                placement="top"
                                                classes={{
                                                  tooltip: classes.tooltip,
                                                }}
                                            >
                                              <IconButton
                                                  aria-label="Close"
                                                  style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                  }}
                                                  className={
                                                    classes.tableActionButton
                                                  }
                                                  onClick={() =>
                                                      delete_PeoRecommendations(
                                                          item.id,
                                                          obj.id
                                                      )
                                                  }
                                              >
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " "
                                                      // classes.close
                                                    }
                                                    style={{
                                                      fontSize: "18px",
                                                    }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Stack>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </TblRecommendationContainer>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          ))}
      </GridContainer>
    </div>
  );
}
