import React from "react";
import Typography from '@mui/material/Typography';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from "../../../../components/Card/Card";
import CardContent from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import {
  fetchAssessmentById
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice';
import UploadAttachmentAssessmentEvidence from '../../../../views/Pages/Assessments/courseAssessments/UploadAttachmentAssessmentEvidence';
import GradingIcon from '@mui/icons-material/Grading';
import SubjectIcon from '@mui/icons-material/Subject';

export default function RecipeReviewCard(props) {

  const { Assessmentid } = useParams()

  const dispatch = useDispatch()

  const assessment = useSelector((state) => state.AssessmentMethods.assessmentById,)

  useEffect(() => {
    dispatch(fetchAssessmentById(Assessmentid))
  }, [])

  return (
    <div>
      <GridContainer>
        {assessment ? (
          assessment?.map((item) => (
            <GridItem xs={12} sm={12} md={4} >
              <div style={{ marginLeft: "20px", width: '300px', height: '300px' }}>
                <Card sx={{ minWidth: 500, minHeight: 500 }} style={{ boxShadow: "-5px -5px 8px 5px rgba(0,0,0,0.3)" }}>
                  <CardHeader >
                    <Typography variant="body2" color="text.secondary">
                      {item.required ? <GradingIcon style={{ color: 'red' }} /> : <SubjectIcon />} {item.evidence_type}
                    </Typography>
                  </CardHeader>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary" style={{ border: '1px solid black' }}>
                      <UploadAttachmentAssessmentEvidence item={item} resource={props.resource} resourceId={props.resourceId} />
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </GridItem>
          ))
        ) : (
          <div>...</div>
        )}
      </GridContainer>
    </div>
  )
}
