// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Form, Input, notification, Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addNewComment, fetchCommentsOnAnnualProgramByProgramAndType } from "reduxToolkit/features/Comments/CommentsSlice";
import { fetchCounselingAndSupportActivitiesByProgramId } from "reduxToolkit/features/CounselingAndSupportActivities/CounselingAndSupportActivitiesSlice";
import { addNewCounselingAndSupportActivity } from "reduxToolkit/features/CounselingAndSupportActivities/CounselingAndSupportActivitiesSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);
export default function AddCounselingAndSupportOnAnnualProgram(props) {
  const { Option } = Select;

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch()
  const history = useHistory();
  const [value, setValue] = React.useState(1);
  useEffect(() => {

  }, [dispatch])

  const titleClasses = useTitleStyles();

  // console.log('idCourse', props.idCourse)
  const [activity, setActivity] = useState("")
  const [description, setDescription] = useState("")
  const [annualProgramReport_id, setAnnualProgramReport_id] = useState(props.id)



  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
        addNewCounselingAndSupportActivity({
        ...values,
       
        annualProgramReport_id,
      })
    );
    if (addNewCounselingAndSupportActivity.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchCounselingAndSupportActivitiesByProgramId(props.id))
      props.fetchCounseling();
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };


  return (
    <div>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={(event) => {
          handleClickOpen(event);
        }}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Counseling and support Activity")}</center>
        </DialogTitle>
        <DialogContent>
          <GridItem xs={12} sm={12} md={12}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="activity"
                label="Activity"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input this field.",
                  },
                ]}
              >
                <TextArea rows={1} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input this field.",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  style={{ marginRight: "20px" }}
                  color="danger"
                  onClick={handleClose}
                  htmlType="submit"
                >
                  Cancel
                </Button>
                <Button type="primary" color="info" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </GridItem>

        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
