import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import logo from 'assets/img/logo.png';
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import validationFormsStyles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import {
  fetchCurrentSemester
} from "../../../reduxToolkit/features/semester/SemestersSlice";
import { clearState, loginUser, userSelector } from '../../../reduxToolkit/features/user/UserSlice';
import InvalidLogin from "./invaldDesign/InvalidLogin";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertStyles);
const useValidationFormsStyles = makeStyles(validationFormsStyles);

export default function LoginPage() {

  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory();
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(userSelector);

  const { getCurrentUser } = useAuth();

  // states
  const [loginState, setloginState] = useState("");
  const [loginPasswordState, setloginPasswordState] = useState("");
  const [alert, setAlert] = useState(null);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [emptyCredentialsAlertIsOpen, setEmptyCredentialsAlertIsOpen] = useState(false)

  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const validationFormsStylesClasses = useValidationFormsStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }
    if (isSuccess && localStorage.getItem('token')) {
      dispatch(clearState());
      history.push('/citrine/dashboard');
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const verifyLength = (value) => {
    return value.length > 0;
  };

  const handleCloseEmptyCredentialsAlert = () => {
    setEmptyCredentialsAlertIsOpen(false)
  }

  const basicAlert = (e) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={e}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.warning}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const onLoginClicked = async (e) => {
    e.preventDefault();
    if (!username?.trim().length || !password?.trim().length) {
      setloginState("error");
      setloginPasswordState("error");
      return
    }
    const initialData = { username, password }
    const logindata = JSON.stringify(initialData)
    await dispatch(loginUser(logindata))
      .unwrap()
      .then((originalPromiseResult) => {
        getCurrentUser()
        dispatch(fetchCurrentSemester(originalPromiseResult.id))
          .unwrap()
          .then((originalPromiseResult) => {
            localStorage.setItem('semester_id', JSON.stringify(originalPromiseResult.semester_id));
          })
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        rejectedValueOrSerializedError.non_field_errors && basicAlert(rejectedValueOrSerializedError.non_field_errors[0])
       })
  }

  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <form onSubmit={onLoginClicked}>
            <Card login className={classes[cardAnimaton]}>
              <br></br>
              <br></br>
              <br></br>
              <CardHeader>
                <div style={{ backgroundColor: "#ffffff", textAlign: "center", margin: 10}}>
                  <img src={logo} alt="Logo" width="300px" height="100%" />
                </div>
              </CardHeader>
              <br></br><br></br><br></br>
              <CardBody>
                <br></br>
                {emptyCredentialsAlertIsOpen && (<InvalidLogin handleClose={handleCloseEmptyCredentialsAlert} />)}
                <CustomInput
                  success={loginState === "success"}
                  error={loginState === "error"}
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyLength(event.target.value)) {
                        setloginState("success");
                        setEmptyCredentialsAlertIsOpen(false)

                      } else {
                        setloginState("error");
                        setEmptyCredentialsAlertIsOpen(true)
                      }
                      setUsername(event.target.value);
                    },
                    value: username,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={validationFormsStylesClasses.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <br></br>
                <CustomInput
                  success={loginPasswordState === "success"}
                  error={loginPasswordState === "error"}
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyLength(event.target.value)) {
                        setloginPasswordState("success");
                        setEmptyCredentialsAlertIsOpen(false)
                      } else {
                        setloginPasswordState("error");
                        setEmptyCredentialsAlertIsOpen(true)
                      }
                      setPassword(event.target.value);
                    },
                    value: password,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={validationFormsStylesClasses.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button disabled={isFetching}  type='submit' color="primary" simple size="lg" block  >
                  {t('Login')}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
