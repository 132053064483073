import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
    annualProgramAnalysiss: [],
    AllAnnualProgramAnalysiss: [],
    annualProgramAnalysissStatus: "idle",
    annualProgramAnalysis: null,
    annualProgramAnalysisStatus: "idle",
    AllAnnualProgramAnalysisStatus: "idle",
    annualProgramAnalysissByProgram: [],
    annualProgramAnalysissByProgramStatus: "idle",
    improvementByResourceAndResourseIdAndTypeStatus: "idle",
    improvementByResourceAndResourseIdAndType:null,
    improvementByResourceAndProgramIdAndTypeStatus: "idle",
    improvementByResourceAndProgramIdAndType:null
};



export const fetchImprovementByResourceAndResourceIdAndType = createAsyncThunk('annualProgramAnalysiss/fetchImprovementByResourceAndResourceIdAndType', async (data,{ rejectWithValue }) => {
    try {
  
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/strengthImprovement/by_resource/?resource=${data.resource}&resource_id=${data.resource_id}&type=${data.type}`,  
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      console.log('response from slice', response.data)
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  })

  export const fetchImprovementByResourceAndProgramIdAndType = createAsyncThunk('annualProgramAnalysiss/fetchImprovementByResourceAndProgramIdAndType', async (data,{ rejectWithValue }) => {
    try {
  
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/strengthImprovement/by_resource_and_program/?resource=${data.resource}&program_id=${data.program_id}&type=${data.type}`,  
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  })
  
export const fetchAnnualProgramAnalysissByProgram = createAsyncThunk(
    "annualProgramAnalysiss/fetchAnnualProgramAnalysissByProgram",
    async (obj, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                BaseUrl + `/strengthImprovement/by_program/?program_id=${obj.program_id}&type=${obj.analysisType}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            //console.log(response.data, 'response.data')
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }

            return rejectWithValue(error.response);
        }
    }
);
export const fetchAllAnnualProgramAnalysiss = createAsyncThunk(
    "annualProgramAnalysiss/fetchAllAnnualProgramAnalysiss",
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                BaseUrl + `/strengthImprovement/all/`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }

            return rejectWithValue(error.response);
        }
    }
);

export const addNewAnnualProgramAnalysis = createAsyncThunk(
    "annualProgramAnalysiss/addNewAnnualProgramAnalysis",
    async (toAdd, { rejectWithValue }) => {
        console.log('toAdd', toAdd)
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                BaseUrl + `/strengthImprovement/create/`,
                toAdd,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err; // cast the error for access
            if (!error.response) {
                throw err;
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            // console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response);
        }
    }
);

export const fetchAnnualProgramAnalysisById = createAsyncThunk(
    "annualProgramAnalysiss/fetchAnnualProgramAnalysisById",
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                BaseUrl + `/strengthImprovement/${id}/`,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            // console.log(response.data,'response.data')

            return response.data;
        } catch (err) {
            let error = err; // cast the error for access
            if (!error.response) {
                throw err;
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            // console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response);
        }
    }
);

export const updateAnnualProgramAnalysis = createAsyncThunk(
    "annualProgramAnalysiss/updateAnnualProgramAnalysiss",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.put(
                BaseUrl + `/strengthImprovement/${data.id}/edit/`,
                data.updatedAnnualProgramAnalysis,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            //console.log(response.data, 'response.data')
            return response.data;
        } catch (err) {
            let error = err; // cast the error for access
            if (!error.response) {
                throw err;
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            //console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response);
        }
    }
);


export const deleteAnnualProgramAnalysis = createAsyncThunk(
    "annualProgramAnalysiss/deleteAnnualProgramAnalysis",
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.delete(BaseUrl + `/strengthImprovement/${id}/delete/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            let error = err; // cast the error for access
            if (!error.response) {
                throw err;
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log("error.response from inside the CAT", error.response);
            return rejectWithValue(error.response);
        }
    }
);

const annualProgramAnalysissSlice = createSlice({
    name: "annualProgramAnalysissSlice",
    initialState,
    extraReducers: {
       
        [fetchAnnualProgramAnalysissByProgram.pending]: (state, action) => {
            state.annualProgramAnalysissByProgramStatus = "loading";
        },
        [fetchAnnualProgramAnalysissByProgram.fulfilled]: (state, action) => {
            state.annualProgramAnalysissByProgramStatus = "succeeded";
            // Add any fetched posts to the array
            state.annualProgramAnalysissByProgram = action.payload;
        },
        [fetchAnnualProgramAnalysissByProgram.rejected]: (state, action) => {
            state.annualProgramAnalysissByProgramStatus = "failed";
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload;
                // console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error;
                //console.log("action.error from inside extra add plo reducer", action.error)
            }
        },
        [fetchImprovementByResourceAndResourceIdAndType.pending]: (state, action) => {
            state.improvementByResourceAndResourseIdAndTypeStatus = "loading";
        },
        [fetchImprovementByResourceAndResourceIdAndType.fulfilled]: (state, action) => {
            state.improvementByResourceAndResourseIdAndTypeStatus = "succeeded";
            // Add any fetched posts to the array
            state.improvementByResourceAndResourseIdAndType = action.payload;
        },
        [fetchImprovementByResourceAndResourceIdAndType.rejected]: (state, action) => {
            state.improvementByResourceAndResourseIdAndTypeStatus = "failed";
        },

        [fetchImprovementByResourceAndProgramIdAndType.pending]: (state, action) => {
            state.improvementByResourceAndProgramIdAndTypeStatus = "loading";
        },
        [fetchImprovementByResourceAndProgramIdAndType.fulfilled]: (state, action) => {
            state.improvementByResourceAndProgramIdAndTypeStatus = "succeeded";
            // Add any fetched posts to the array
            state.improvementByResourceAndProgramIdAndType = action.payload;
        },
        [fetchImprovementByResourceAndProgramIdAndType.rejected]: (state, action) => {
            state.improvementByResourceAndProgramIdAndTypeStatus = "failed";
        },

        [fetchAnnualProgramAnalysisById.pending]: (state, action) => {
            state.annualProgramAnalysisStatus = "loading";
        },
        [fetchAnnualProgramAnalysisById.fulfilled]: (state, action) => {
            state.annualProgramAnalysisStatus = "succeeded";
            // Add any fetched posts to the array
            state.annualProgramAnalysis = action.payload;
        },
        [fetchAnnualProgramAnalysisById.rejected]: (state, action) => {
            state.annualProgramAnalysisStatus = "failed";
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload;
                // console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error;
                //console.log("action.error from inside extra add plo reducer", action.error)
            }
        },



        [fetchAllAnnualProgramAnalysiss.pending]: (state, action) => {
            state.AllAnnualProgramAnalysisStatus = 'loading'
        },
        [fetchAllAnnualProgramAnalysiss.fulfilled]: (state, action) => {
            state.AllAnnualProgramAnalysisStatus = 'succeeded'
            // Add any fetched posts to the array
            state.AllAnnualProgramAnalysiss = action.payload
        },
        [fetchAllAnnualProgramAnalysiss.rejected]: (state, action) => {
            state.AllAnnualProgramAnalysisStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                // console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                //console.log("action.error from inside extra add plo reducer", action.error)
            }
        },

        [addNewAnnualProgramAnalysis.fulfilled]: (state, action) => {
            state.annualProgramAnalysissByProgramStatus = 'succeeded'
            state.annualProgramAnalysissByProgram.push(action.payload)
        },
        [addNewAnnualProgramAnalysis.rejected]: (state, action) => {
            state.annualProgramAnalysissStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
        [updateAnnualProgramAnalysis.fulfilled]: (state, action) => {
            state.annualProgramAnalysissStatus = 'succeeded'
            console.log(action)
        },
        [updateAnnualProgramAnalysis.rejected]: (state, action) => {
            state.annualProgramAnalysissStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
        [deleteAnnualProgramAnalysis.fulfilled]: (state, action) => {
            state.annualProgramAnalysissStatus = 'succeeded'
            console.log(action)
        },
        [deleteAnnualProgramAnalysis.rejected]: (state, action) => {
            state.annualProgramAnalysissStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
    },
});

export default annualProgramAnalysissSlice.reducer;
