// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
// @material-ui/icons
import { Breadcrumb, Select } from 'antd';
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchFields } from "reduxToolkit/features/field/FieldSpecSlice";
import { addNewSpecialization, fetchSpecializationById, fetchSpecializations, updateSpecialization } from "reduxToolkit/features/specialization/SpecializationSpecSlice";

const useStyles = makeStyles(styles);

export default function CreateSpecializationPage({ match }) {
  const { t } = useTranslation();
  const { id } = match.params

  const isAddMode = isNaN(id);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [fieldSpec_id, setField_id] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const { Option } = Select;
  const canSave = [name, code, fieldSpec_id].every(Boolean) && addRequestStatus === 'idle'

  const onNameChanged = (e) => setName(e.target.value)
  const onCodeChanged = (e) => setCode(e.target.value)
  const onField_idChanged = (e) => setField_id(e.target.value)

  const dispatch = useDispatch()

  const fieldsStatus = useSelector(state => state.fieldSpec.fieldsStatus)

  const fields = useSelector(state => state.fieldSpec.fields)

  const specialization = useSelector((state) => state.specializationSpec.specialization)

  const history = useHistory();

  useEffect(() => {
    if (fieldsStatus === 'idle') {
      dispatch(fetchFields())
    }
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchSpecializationById(id))
    }
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      setName(specialization && specialization.name)
      setCode(specialization && specialization.code)
      setField_id(specialization && specialization.fieldSpec_id)
    }
  }, []);

  const onSaveSpecializationClicked = async () => {
    if(!name || !code || !fieldSpec_id)
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        addNewSpecialization({ name, code, fieldSpec_id })
      )
      unwrapResult(resultAction)
      setName('')
      setCode('')
      setField_id('')
      dispatch(fetchSpecializations())
      history.push('/citrine/specializations');
    } catch (err) {} finally {
      setAddRequestStatus('idle')
    }
  }

  const onUpdateSpecializationClicked = async () => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        updateSpecialization({ id: id, modified: { name, code, fieldSpec_id } })
      )
      unwrapResult(resultAction)
      setName('')
      setCode('')
      setField_id('')
      dispatch(fetchSpecializations())
      history.push('/citrine/specializations');
    } catch (err) {} finally {
      setAddRequestStatus('idle')
    }
  }

  const onChange = (value) => {
    setField_id(value);
  }

  const onBlur = () => {}

  const onFocus = () => {}

  const onSearch = (val) => {}

  return (
    <div>
      {isAddMode &&
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item >
                    {t('Configurations')}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span > {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item >
                    <Link to={`/citrine/specializations`} style={{ color: "orange" }}>
                      {t('Specializations')}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <span className={titleClasses.title}>  {t('Create')} </span>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <CustomInput
                              labelText={t('Name ')}
                              id="name"
                              type="text"
                              name="name"
                              value={name}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a name!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setName(event.target.value);
                                }
                              }}
                            /><br/><br/>
                            <GridItem xs={12} sm={12} md={12} >
                              <label>{t('Field ')} </label><br/>
                              <Select
                                showSearch
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                name="field_id"
                                value={fieldSpec_id}
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {fields.map((field) => (
                                  <option key={field.id} value={field.id}>
                                    {field.name}
                                  </option>
                                ))}
                              </Select>
                            </GridItem>
                            <CustomInput
                              labelText={t('Code ')}
                              id="code"
                              value={code}
                              formControlProps={{
                                fullWidth: true
                              }}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a code!',
                                },
                              ]}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCode(event.target.value);
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Button color="info"
                              className={classes.updateProfileButton}
                              onClick={onSaveSpecializationClicked}
                            >
                              {t('Save')}
                            </Button></GridItem>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      }
      {!isAddMode && specialization &&
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item >
                    {t('Configurations')}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span > {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item >
                    <Link to={`/citrine/specializations`} style={{ color: "orange" }}>
                      {t('Specializations')}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <span className={titleClasses.title}> {t('Edit')} </span>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <CustomInput
                              labelText={t('Name ')}
                              id="name"
                              type="text"
                              name="name"
                              value={name}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a name!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setName(event.target.value);
                                },
                                value: name
                              }}
                            /><br/><br/>
                            <GridItem xs={12} sm={12} md={12} >
                              <label>{t('Field ')} </label><br/>
                              <Select
                                showSearch
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                name="field_id"
                                value={fieldSpec_id}
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {fields.map((field) => (
                                  <option key={field.id} value={field.id}>
                                    {field.name}
                                  </option>
                                ))}
                              </Select>
                            </GridItem>
                            <CustomInput
                              labelText={t('Code ')}
                              id="code"
                              value={code}
                              formControlProps={{
                                fullWidth: true
                              }}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a code!',
                                },
                              ]}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCode(event.target.value);
                                },
                                value: code
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Button color="info"
                              className={classes.updateProfileButton}
                              onClick={onUpdateSpecializationClicked}
                            >
                              {t('Update')}
                            </Button></GridItem>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      }
    </div>
  );
}
