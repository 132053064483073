import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification } from "antd";
import ErrorModal from "../../../Components/ErrorModal";

import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice.js";

import AddIcon from "@material-ui/icons/Add";
import {
  warningColor,
  whiteColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import {
  UserOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {
  updatePerson,
  fetchPersonById,
} from "../../../../reduxToolkit/features/person/PersonsSlice";
import { fetchKpiReportById } from "../../../../reduxToolkit/features/KpiReport/KpiReportSlice";
import { updateStandard } from "../../../../reduxToolkit/features/standardConfig/StandardConfigSlice.jsx";

import { fetchAll_standards_KPIsCollege } from "../../../../reduxToolkit/features/KPI/KPICollegeSlice.jsx";
import { fetchAll_standards_KPIsDepartment } from "../../../../reduxToolkit/features/KPI/KPIDepartmentSlice";
import { fetchAll_standards_KPIsProgram } from "../../../../reduxToolkit/features/KPI/KPIProgramSlice";
import { fetchAll_standards_KPIsUniversity } from "../../../../reduxToolkit/features/KPI/KPIUniversitySlice.jsx";
import{updateAnalysis_of_kpi} from "../../../../reduxToolkit/features/KPI/KPIConfigSlice.jsx"
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function EditkPIAnalysisModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const courseId = props.courseId;
  const [form] = Form.useForm();

  const [course_id, setCourse_id] = useState(props.course_id);

  const { Option } = Select;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchAll_standards_KPIsCollege());
    dispatch(fetchAll_standards_KPIsDepartment());
    dispatch(fetchAll_standards_KPIsProgram());
    dispatch(fetchAll_standards_KPIsUniversity());
    form.setFieldsValue({
        analysis_of_kpi:
        props.analysis_of_kpi,
    });
  }, [dispatch]);

  const onFinish = async (values) => {
    console.log(values, "...values");
    console.log(props.kpi.id, "props.kpi.id");
    const resultAction = await dispatch(
      updateAnalysis_of_kpi({ kpi_id: props.kpi.id,type:props.kpi.type, ...values})
    );
    if (updateAnalysis_of_kpi.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Edit successfully`);

      dispatch(fetchAll_standards_KPIsCollege());
      dispatch(fetchAll_standards_KPIsDepartment());
      dispatch(fetchAll_standards_KPIsProgram());
      dispatch(fetchAll_standards_KPIsUniversity());

      dispatch(fetchKpiReportById(props.kpiReport_id));

      //form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Edit Analysis of Kpi")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                //{...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="analysis_of_kpi"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input the analysis_of_kpi field.",
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Enter analysis kpi" />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
