import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";
import { DataGrid } from "@mui/x-data-grid";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, notification } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchCourseByProgramNextPage,
  fetchCoursesByProgramByPage,
  updateCoursePlanning
} from "../../../../reduxToolkit/features/course/CoursesSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import DropDownPlanningCourseAssessment from "./DropDownPlanningCourseAssessment";

import { fetchProgramById } from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchAcademicYearById } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchSemesterById } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";
import UpdateDatesAll from "./UpdateDatesAll";

export default function CourseAssessment({ match }) {

  const { program_id } = match.params;
  const { semesterId } = match.params;
  const { id } = match.params;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const courseStatus = useSelector((state) => state.course.coursesStatus);
  const coursesByPage = useSelector((state) => state.course.coursesByPage);
  const program = useSelector((state) => state.program.program);
  const semesterStatus = useSelector((state) => state.semester.semesterStatus);
  const semester = useSelector((state) => state.semester.semester);
  const academicYear = useSelector((state) => state.academicYear.academicYear);

  const [rowCountState, setRowCountState] = useState();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [rows, setRows] = useState(null);
  const [openSpinner, setOpenSpinner] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [coordinator, setCoordinator] = useState(null);
  const [theory, setTheory] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const columns = [
    {
      field: "code",
      headerName: "Code",
      flex: 1
    },
    {
      field: "title",
      headerName: "Course",
      flex: 1

    },
    {
      field: "coordinator",
      headerName: "Coordinator",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues &&
              cellValues.row &&
              cellValues.row.coordinator_details &&
              cellValues.row.coordinator_details[0] &&
              cellValues.row.coordinator_details[0].first_name}{" "}
            {""}{" "}
            {cellValues &&
              cellValues.row &&
              cellValues.row.coordinator_details &&
              cellValues.row.coordinator_details[0] &&
              cellValues.row.coordinator_details[0].last_name}
          </div>
        );
      },
      flex: 1

    },
    {
      field: "instructors",
      headerName: "instructors",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues?.row?.instructors?.map((item) => item?.map((itemm) => {
              return (
                <>
                  {itemm?.first_name}
                  {itemm?.last_name}/
                </>
              )
            }))}
          </div>
        );
      },
      flex: 1

    },
    {
      field: "assessment_start_date",
      headerName: "Assessment Start Date",
      type: "date",
      editable: true,
      hide: true,
    },
    {
      field: "assessment_due_date",
      headerName: "Assessment Due Date",
      type: "date",
      editable: true,
      hide: true,
    },
    {
      field: "survey_start_date",
      headerName: "Survey Start Date",
      type: "date",
      editable: true,
      hide: true,
    },
    {
      field: "survey_due_date",
      headerName: "Survey Due Date",
      type: "date",
      editable: true,
      hide: true,
    },
    {
      field: "improvement_due_date",
      headerName: "Improvement Due Date",
      type: "date",
      editable: true,
      hide: true,
    },
    {
      field: "surveyTemplate",
      headerName: "Survey Template",
      valueGetter: (params) => {
        return `${(params.row &&
          params.row.surveyTemplate_details &&
          params.row.surveyTemplate_details[0] &&
          params.row.surveyTemplate_details[0].name) ||
          ""
          }`;
      },
      flex: 1
    },
    {
      field: "active",
      headerName: "Improvement",
      type: "boolean",
      editable: true,
      hide: true,
    },
    {
      field: "clo_Success_Criteria",
      headerName: "CLO Success Criteria",
      flex: 1

    }
  ];

  const handleCloseSpinner = () => {
    setOpenSpinner(false);
  };

  useEffect(() => {
    let data = {
      page: page,
      records: rowsPerPage,
      program_id: program_id,
    };
    dispatch(fetchCoursesByProgramByPage(data))
      .then(unwrapResult)
      .then((courses) => {
        setRows(courses.results);
        handleCloseSpinner();
      });
  }, []);

  useEffect(() => {
    fetchSemester(semesterId);
    fetchAcademicYear(id);
    fetchProgram(program_id);
  }, []);

  const refreshCourses = () => {
    let data = {
      page: page,
      records: rowsPerPage,
      program_id: program_id,
    };
    dispatch(fetchCoursesByProgramByPage(data))
      .then(unwrapResult)
      .then((courses) => {
        setRows(courses.results);
        setRowCountState(courses.count);
        handleCloseSpinner();
      });
    setSelectionModel([]);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onCellEditCommit = async (event) => {
    const value =
      event.field === "active"
        ? event.value
        : moment(event.value).format("YYYY-MM-DD");
    const field = event.field;
    const updated = {
      [field]: value,
    };

    const id = event.id;

    if (
      (value && value !== "Invalid date" && value !== event.row[field]) ||
      (event.field === "active" &&
        value === false &&
        value !== event.row[field])
    ) {
      const resultAction = await dispatch(
        updateCoursePlanning({ id: id, updated: updated })
      );
      if (updateCoursePlanning.fulfilled.match(resultAction)) {
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        openNotificationWithIcon("success", `Updated successfully`);
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const previousPage = () => {
    if (coursesByPage.previous !== null) {
      dispatch(fetchCourseByProgramNextPage(coursesByPage.previous))
        .then(unwrapResult)
        .then((courses) => {
          setRows(courses.results);
        });
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (coursesByPage.next !== null) {
      dispatch(fetchCourseByProgramNextPage(coursesByPage.next))
        .then(unwrapResult)
        .then((courses) => {
          setRows(courses.results);
        });
      setPage(page + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    let data = {
      page: 1,
      records: event.target.value,
    };
    dispatch(fetchCoursesByProgramByPage(data))
      .then(unwrapResult)
      .then((courses) => {
        setRows(courses.results);
      });
  };

  const fetchSemester = async (id) => {
    try {
      const resultAction = await dispatch(fetchSemesterById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchProgram = async (id) => {
    try {
      const resultAction = await dispatch(fetchProgramById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAcademicYear = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const onSelectionModelChange = (newSelectionModel) => {
    const selectedIDs = new Set(newSelectionModel);
    const selectedRows = rows.filter((row) => {
      return selectedIDs.has(row.id);
    });
    let labIDetails = selectedRows.map((item) => {
      return {
        labInstructor_details: item.labInstructor_details,
        group_code: item.group_code,
        labInstructor_ids: item.labInstructor_ids,
      };
    });
    setSelectedRowDetails(...labIDetails);
    setSelectionModel(newSelectionModel);
    selectedRows.map((item) => {
      setCoordinator(item.coordinator_id);
      setTheory(item.theoryInstructor_id)
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openSpinner}
        onClick={handleCloseSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer
        style={{
          width: "100%",
        }}
      >
        <GridItem md={11} xs={11} sm={11}>
          <Breadcrumb>
            <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
            <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/planning/academicCalender/academicYears`}>
                {t("Academic years")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}`}
              >
                {t(`${academicYear && academicYear.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("semesters")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}`}
              >
                {t(`${semester && semester.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}`}
              >
                {t(`${program && program.program_degree_name}`)}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{t("Courses Planning")}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridItem md={1} xs={1} sm={1} style={{ align: "flex-end" }}>
          {/***********************************************************************************************
             * I am passed Theory and Coordinator to the form of Coord/Theory to make them as a InitialValues
             *
             * You can check 'DropDownPlanningCourseAssessment.js' --> 'AddCoordinator.js'
             *
             * I am passed selectedRowDetails that contains default data of labI to make them as a InitialValues
             ***********************************************************************************************/}
          <DropDownPlanningCourseAssessment
            selectedRows={selectionModel}
            refreshCourses={refreshCourses}
            program_id={program_id}
            semester_id={semesterId}
            selectedRowDetails={selectedRowDetails}
            coordinator={coordinator}
            theory={theory}
          />
          {/************************************************************************************************ */}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer
                alignItems="center"
              >
                <GridItem xs={12} sm={10} md={10}>
                  <GridContainer
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={4} md={4}>
                      {/* <Card>
                    <CardBody> */}
                      <h6>Assessment</h6>
                      <h7>
                        From : {program && program.assessment_start_date}
                      </h7>
                      <h7> To: {program && program.assessment_due_date} </h7>
                      {/* </CardBody>
                  </Card> */}
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      {/* <Card>
                    <CardBody> */}
                      <h6>Survey</h6>
                      <h7>From : {program && program.survey_start_date}</h7>
                      <h7> To: {program && program.survey_due_date} </h7>
                      {/* </CardBody>
                  </Card> */}
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      {/* <Card>
                    <CardBody> */}
                      <h6>Improvement</h6>
                      <h7> {program && program.improvement_due_date} </h7>
                      {/* </CardBody>
                  </Card> */}
                    </GridItem>
                  </GridContainer>

                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                  <UpdateDatesAll program_id={program_id} program={program} fetchProgram={fetchProgram} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Card>
        <CardBody>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={(coursesByPage && coursesByPage.results) || []}
              rowCount={coursesByPage.count}
              columns={columns}
              columnBuffer={0}
              disableSelectionOnClick
              onCellEditCommit={onCellEditCommit}
              showCellRightBorder
              showColumnRightBorder
              checkboxSelection
              onSelectionModelChange={onSelectionModelChange}
              selectionModel={selectionModel}
              hideFooterPagination
              hideFooter
              pagination
              paginationMode="server"
              pageSize={rowsPerPage}
              onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
            />
          </div>
        </CardBody>
      </Card>
      <GridContainer>
        <Card>
          <CardBody>
            <TablePagination
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} OF ${count !== -1 ? count : `MORE THAN ${to}`}`
              }
              component="div"
              count={coursesByPage && coursesByPage.count}
              rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                `${coursesByPage && coursesByPage.count}`,
              ]}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "previous page",
                onClick: previousPage,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                onClick: nextPage,
              }}
            />
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
