import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, InputNumber, notification, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Chip from '@material-ui/core/Chip'

import ErrorModal from 'views/Components/ErrorModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAccessControlModels,
  fetchModels,
  fetchModelById,
  addAccessControlModel,
} from 'reduxToolkit/features/model/ModelSlice'
import {
  addNewSection,
  updateSection,
  fetchSectionById,
  fetchSections,
} from 'reduxToolkit/features/Section/SectionSlice'
const { Option, OptGroup } = Select

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
const useStylesChip = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.2),
  },
}))
const EditSectionModal = ({
  openEditSectionModal,
  handleClickOpenEditSectionModal,
  handleCloseEditSectionModal,
  modelId,
  sectionId,
  ping,
}) => {
  const dispatch = useDispatch()
  const classes = useStylesChip()

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [sectionMethod, setSectionMethod] = useState('post')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const model = useSelector((state) => state.model.model)

  const section = useSelector((state) => state.section.section)
  const onFinish = (values) => {
    console.log({ ...values })
    dispatch(updateSection({ ...values, model: section.model, id: section.id }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchModels())
          .unwrap()
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
        openNotificationWithIcon('success', `Updated Successfully`)
        handleCloseEditSectionModal()
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  useEffect(() => {

    if (modelId && sectionId) {
      dispatch(fetchModelById(modelId))
      dispatch(fetchSectionById(sectionId))
        .unwrap()
        .then((section) => {
          console.log(section)
          form.setFieldsValue({
            name: section.name,
            code: section.code,
            Directfield: section.Directfield,
            ChildObjects: section.ChildObjects,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }

  }, [ping, sectionId, modelId])
  return (
    <div>
      <Dialog
        open={openEditSectionModal}
        onClose={handleCloseEditSectionModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <center> {t('Edit Section')}</center>
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the name field.',
                    },
                  ]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the code field.',
                    },
                  ]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="Directfield" label="Direct Field">
                  <Select
                    mode="multiple"
                    placeholder="Select Direct Field"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {model &&
                      model.Directfield &&
                      model?.Directfield?.map((r, index) => (
                        <Option key={index} value={r}>
                          {r}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item name="ChildObjects" label="Child Field">
                  <Select
                    mode="multiple"
                    placeholder="Select Child Field"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {model &&
                      model.ChildObjects &&
                      model?.ChildObjects?.map((r, index) => (
                        <Option key={index} value={r}>
                          {r}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={() => {
                      form.resetFields()
                      handleCloseEditSectionModal()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </Dialog>
    </div>
  )
}

export default EditSectionModal
