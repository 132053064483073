// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { unwrapResult } from '@reduxjs/toolkit'
import { Avatar, Breadcrumb, Divider, notification, Tabs } from 'antd'
import 'antd/dist/antd.css'
import PreviousActionPlan from '../../../Components/PreviousActionPlan'
// core components
import {
  blackColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoReport } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchActivities } from '../../../../reduxToolkit/features/Activitiy/ActivitiesSlice'
import {
  fetchGAReportById,
  fetchGAReports,
  UpdateGAReport,
} from '../../../../reduxToolkit/features/GAReport/GAResportSlice'
import {
  deleteGAReportDocument,
  fetchGAReportDocumentsByProgramId,
} from '../../../../reduxToolkit/features/GAReportDocument/GAReportDocumentSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
import ErrorModal from '../../../Components/ErrorModal'
import AddDocument from './AddDocument'
import AnalysisGA from './AnalysisGA'
import GenerateActionsModal from './GenerateGAActionsModal'
import '../improvement.css'

const { TabPane } = Tabs
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}))

const useStyles = makeStyles(styles)

export default function GAImprovementDetails({ match }) {
  const { t } = useTranslation()
  const classes1 = useStyles1()
  const classes = useStyles()

  const { id } = match.params
  // console.log("id of course report ", id)
  const dispatch = useDispatch()

  const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false)
  const handleClickOpenAddDocumentModal = () => {
    setOpenAddDocumentModal(true)
  }
  const handleCloseAddDocumentModal = () => {
    setOpenAddDocumentModal(false)
  }

  const [disabledStatus, setDisabledStatus] = useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const [stateName, setStateName] = useState('')
  const [show, setShow] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showActivities, setShowActivities] = useState(false)
  const [showTasks, setShowTasks] = useState(false)
  const [showReports, setShowReports] = useState(false)
  const [showDiagram, setShowDiagram] = useState(false)

  const [open, setOpen] = React.useState(false)

  const fetchGAReportByIdCustomFunction = async () => {
    try {
      const resultAction = await dispatch(fetchGAReportById(id)).unwrap()

      setStateName(resultAction.state_object)
      dispatch(fetchProgramById(resultAction.program))
      dispatch(fetchGAReportDocumentsByProgramId(resultAction.program))
      console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchGAReportByIdCustomFunction()
  }, [])
  const GAReport = useSelector((state) => state.GAReport.GAReport)
  const GAReports = useSelector((state) => state.GAReport.GAReports)
  const gaReportDocuments = useSelector(
    (state) => state.gaReportDocument.gaReportDocuments,
  )
  const program = useSelector((state) => state.program.program)
  const currentGaReport = GAReports?.find((el) => el.id == id)
  console.log(currentGaReport)

  console.log('GAReport', GAReport && GAReport.program)
  console.log('program', program && program)

  useEffect(() => {
    dispatch(fetchActivities())
  }, [])

  const Activities = useSelector((state) => state.Activity.Activities)

  const renderTasks = () => {
    setShowTasks(true)
    // setShowActivities(false);
    setShowDiagram(false)
    setShowReports(false)
  }
  // const renderActivities = () => {
  //   setShowActivities(true);
  //   setShowDiagram(false);
  //   setShowTasks(false);
  //   setShowReports(false);
  // };
  const renderReports = () => {
    setShowReports(true)
    //setShowActivities(false);
    setShowDiagram(false)
    setShowTasks(false)
  }

  //bmpn Modal
  const handleClickOpen = () => {
    setOpen(true)
    setShowDiagram(true)
    // setShowActivities(false);
    setShowTasks(false)
    setShowReports(false)
  }

  // const createGAReportDocument = async () => {
  //   try {
  //     const resultAction = await dispatch(
  //       addNewGAReportDocument({ ressource: "ga", ressourceId: GAReport && GAReport.program })
  //     )
  //     unwrapResult(resultAction)

  //   } catch (err) {
  //     console.error('Failed to save the GAReportDocument: ', err)
  //   } finally {
  //     dispatch(fetchGAReportDocumentsByProgramId(GAReport && GAReport.program))
  //   }
  // }

  const renderRow = () => {
    const handleDeleteGAReportDocument = (id) => {
      dispatch(deleteGAReportDocument(id))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          // handle result here
          console.log('originalPromiseResult', originalPromiseResult)
        })
        .then(() => {
          // do additional work
          dispatch(
            fetchGAReportDocumentsByProgramId(GAReport && GAReport.program),
          )
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          console.log(
            'rejectedValueOrSerializedError',
            rejectedValueOrSerializedError,
          )
        })
    }

    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: 'gray' }}
              >
                {Moment(item.DueDate).format('YYYY-MM-DD')}
              </Divider>

              <div
                style={{
                  backgroundColor: '#edeff1',
                  borderBottom: '1px solid #cfd5db',
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={2}>
                    <Avatar
                      style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={10}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      )
    } else if (showTasks) {
      return <div>Tasks</div>
    } else {
      return (
        <GridContainer>
          <Button color="primary" onClick={handleClickOpenAddDocumentModal}>
            New
          </Button>
          {gaReportDocuments &&
            gaReportDocuments.map((gaReportDocument) => (
              <GridItem>
                <Card style={{ backgroundColor: grayColor[8] }}>
                  <GridContainer justify="space-between" alignItems="center">
                    <Link
                      to={`/citrine/GAImprovementDetails/${id}/GAReportDocument/${gaReportDocument.id}`}
                    >
                      <GridItem>
                        <h6> {gaReportDocument?.name}</h6>
                        <br />
                        <h7>State : {gaReportDocument?.state}</h7>
                        <br />
                        <h7>
                          Created By: {gaReportDocument?.creator_details?.first_name} {''} {gaReportDocument?.creator_details?.last_name}
                        </h7>
                        <br />
                        <h7>Created On: {gaReportDocument?.creation_date}</h7>
                        <br />
                        <h7>
                          Assigned To:{' '}
                          {gaReportDocument.report_details.assign_to_details &&
                            gaReportDocument.report_details.assign_to_details
                              .first_name} {''} {gaReportDocument.report_details.assign_to_details &&
                                gaReportDocument.report_details.assign_to_details
                                  .last_name}
                        </h7>
                        <br />
                        <h7>Version: {gaReportDocument?.version} </h7>
                        <br />
                      </GridItem>
                    </Link>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={() => {
                          handleDeleteGAReportDocument(gaReportDocument?.id)
                        }}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      )
    }
  }

  const UpdateState = async (value) => {
    setStateName(value)
    let data = {
      updatedGAReport: {
        state_object: value,
        due_date: GAReport && GAReport.due_date && GAReport.due_date,
      },
      id: id,
    }
    const resultAction = await dispatch(UpdateGAReport(data))

    if (UpdateGAReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchGAReports())
      openNotificationWithIcon('success', `updated successfully`)
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const Approve = () => {
    setShow(true)
    setStateName('Approved')
    localStorage.setItem('GAReport_State', 'Approved')
    try {
      let updatedGAReport = {
        due_date: GAReport && GAReport.due_date && GAReport.due_date,
        state_object: 'Approved',
      }
      const resultAction = dispatch(
        UpdateGAReport({
          id: id,
          updatedGAReport: { ...updatedGAReport },
        }),
        dispatch(fetchGAReports()),
      )
      unwrapResult(resultAction)
      setStateName('Approved')
      dispatch(fetchGAReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchGAReports())
    }
  }
  const SendforApproval = () => {
    setShow(true)
    setStateName('Waiting for approval')
    localStorage.setItem('GAReport_State', 'Waiting for approval')
    try {
      let updatedGAReport = {
        due_date: GAReport && GAReport.due_date && GAReport.due_date,
        state_object: 'Waiting for approval',
      }
      const resultAction = dispatch(
        UpdateGAReport({
          id: id,
          updatedGAReport: { ...updatedGAReport },
        }),
        dispatch(fetchGAReports()),
      )
      unwrapResult(resultAction)
      setStateName('Waiting for approval')
      dispatch(fetchGAReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchGAReports())
    }
  }
  const ResetTODraft = () => {
    setStateName('Created')
    localStorage.setItem('GAReport_State', 'Created')
    try {
      let updatedGAReport = {
        due_date: GAReport && GAReport.due_date && GAReport.due_date,
        state_object: 'Created',
      }
      const resultAction = dispatch(
        UpdateGAReport({
          id: id,
          updatedGAReport: { ...updatedGAReport },
        }),
        dispatch(fetchGAReports()),
      )
      unwrapResult(resultAction)
      setStateName('Created')
      dispatch(fetchGAReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchGAReports())
    }
  }

  const Archive = () => {
    setShow(true)
    setStateName('Archived')
    localStorage.setItem('CourseReport_State', 'Archived')
    try {
      let updatedGAReport = {
        due_date: GAReport && GAReport.due_date && GAReport.due_date,
        state_object: 'Archived',
      }
      const resultAction = dispatch(
        UpdateGAReport({
          id: id,
          updatedGAReport: { ...updatedGAReport },
        }),
        dispatch(fetchGAReports()),
      )
      unwrapResult(resultAction)
      setStateName('Archived')
      dispatch(fetchGAReports())
      setDisabledStatus(true)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
      dispatch(fetchGAReports())
    }
  }

  const callback = (key) => {
    console.log(key)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let content
  {
    GAReport &&
      GAReport.states_actions &&
      GAReport.states_actions.map((item) => {
        if (stateName === 'Created' && item.state === 'Created') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          GAReport_id={GAReport && GAReport.id}
                          GAReport={GAReport && GAReport}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('reset tO draft')
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {GAReport &&
                    GAReport.states_actions &&
                    GAReport.states_actions.map((item) => {
                      return item.state === 'Created' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (
          stateName &&
          stateName.toLowerCase().includes('waiting for approval') &&
          item.state &&
          item.state.toLowerCase().includes('waiting for approval')
        ) {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          GAReport_id={GAReport && GAReport.id}
                          GAReport={GAReport && GAReport}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {GAReport &&
                    GAReport.states_actions &&
                    GAReport.states_actions.map((item) => {
                      return item.state &&
                        item.state
                          .toLowerCase()
                          .includes('waiting for approval') ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (stateName === 'Approved' && item.state === 'Approved') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          GAReport_id={GAReport && GAReport.id}
                          GAReport={GAReport && GAReport}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('archive')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Archive')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {GAReport &&
                    GAReport.states_actions &&
                    GAReport.states_actions.map((item) => {
                      return item.state === 'Approved' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        } else if (stateName === 'Archived' && item.state === 'Archived') {
          content = (
            <div
              style={{
                height: '100%',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '3px',
                border: '1px solid lightgrey',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '10px',
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes('send for approval')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Send for approval')}
                          </span>
                        </Button>
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('generate actions')
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          GAReport_id={GAReport && GAReport.id}
                          GAReport={GAReport && GAReport}
                        />
                      )
                    } else if (
                      action &&
                      action.name.toLowerCase().includes('approve')
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Approve')}
                          </span>
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: 'grey' }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: 'white', fontSize: '11px' }}>
                            {t('Reset tO draft')}
                          </span>
                        </Button>
                      )
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: '550px', height: '40px' }}
                >
                  {GAReport &&
                    GAReport.states_actions &&
                    GAReport.states_actions.map((item) => {
                      return item.state === 'Archived' ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: 'auto',
                            paddingTop: '10px',
                            textAlign: 'center',
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: 'auto', paddingTop: '10px' }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      )
                    })}
                </ol>
              </div>
            </div>
          )
        }
      })
  }

  // const renderGAReport = () => {
  //   if (stateName === "Created") {
  //     return (
  //       <div>
  //         <div
  //           style={{
  //             height: 40,
  //             backgroundColor: "white",
  //             width: "100%",
  //             borderRadius: "3px",
  //             border: "1px solid lightgray",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "0px 25px 0px 20px",
  //             }}
  //           >
  //             <Button
  //               color="primary"
  //               size="sm"
  //               className={classes.marginRight}
  //               onClick={() => UpdateState("WaitingForApproval")}
  //             >
  //               <strong style={{ color: "white", fontSize: "11px" }}>
  //                 {t("Send For Approval")}
  //               </strong>
  //             </Button>
  //             <div>
  //               <div className="tabs">
  //                 <ul className="ulTabs">
  //                   <li
  //                     style={{ fontWeight: "bold" }}
  //                     className="active liTabs"
  //                   > {t("Created")} </li>
  //                   <li className="liTabs"> {t("WaitingForApproval")} </li>
  //                   <li
  //                     className="liTabs"
  //                   >
  //                     {t("Approved")}
  //                   </li>
  //                   <li
  //                     className="liTabs"
  //                   > {t("Archived")} </li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (stateName === "WaitingForApproval") {
  //     return (
  //       <div>
  //         <div
  //           style={{
  //             height: 40,
  //             backgroundColor: "white",
  //             width: "100%",
  //             border: "1px solid lightgray",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "0px 25px 0px 20px",
  //             }}
  //           >
  //             <div style={{ display: "flex" }}>
  //               <Button
  //                 color="primary"
  //                 size="sm"
  //                 className={classes.marginRight}
  //                 onClick={() => UpdateState("Approved")}
  //               >
  //                 <strong style={{ color: "white", fontSize: "11px" }}>
  //                   {t("Approve")}
  //                 </strong>
  //               </Button>
  //               <GenerateActionsModal
  //                 handleDropDownButtonClose={handleClose}
  //                 GAReport_id={GAReport && GAReport.id}
  //                 GAReport={GAReport && GAReport}
  //               />
  //             </div>
  //             <div>
  //               <div className="tabs">
  //                 <ul className="ulTabs">
  //                   <li className="liTabs">{t("Created")}</li>
  //                   <li
  //                     style={{ fontWeight: "bold" }}
  //                     className="active liTabs "
  //                   >
  //                     {t("WaitingForApproval")}
  //                   </li>
  //                   <li className="liTabs">
  //                     {t("Approved")}
  //                   </li>
  //                   <li className="liTabs"> {t("Archived")}</li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (stateName === "Approved") {
  //     return (
  //       <div>
  //         <div
  //           style={{
  //             height: 40,
  //             backgroundColor: "white",
  //             width: "100%",
  //             borderRadius: "3px",
  //             border: "1px solid lightgray",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "0px 25px 0px 20px",
  //             }}
  //           >
  //             <Button
  //               color="primary"
  //               size="sm"
  //               className={classes.marginRight}
  //               onClick={() => UpdateState("Archived")}
  //             >
  //               <strong style={{ color: "white", fontSize: "11px" }}>
  //                 {t("Archive")}
  //               </strong>
  //             </Button>
  //             <div>
  //               <div className="tabs">
  //                 <ul className="ulTabs">
  //                   <li className="liTabs"> {t("Created")} </li>
  //                   <li className="liTabs"> {t("WaitingForApproval")} </li>
  //                   <li
  //                     style={{ fontWeight: "bold" }}
  //                     className="active liTabs "
  //                   >
  //                     {t("Approved")}
  //                   </li>
  //                   <li
  //                     className="liTabs"
  //                   > {t("Archived")} </li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (stateName === "Archived") {
  //     return (
  //       <div>
  //         <div
  //           style={{
  //             height: 40,
  //             backgroundColor: "white",
  //             width: "100%",
  //             borderRadius: "3px",
  //             border: "1px solid lightgray",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               margin: "0px 25px 0px 20px",
  //             }}
  //           >
  //             <Button
  //               color="primary"
  //               size="sm"
  //               className={classes.marginRight}
  //               onClick={() => UpdateState("Created")}
  //             >
  //               <strong style={{ color: "white", fontSize: "11px" }}>
  //                 {t("Reset")}
  //               </strong>
  //             </Button>
  //             <div>
  //               <div className="tabs">
  //                 <ul className="ulTabs">
  //                   <li className="liTabs"> {t("Created")} </li>
  //                   <li className="liTabs"> {t("WaitingForApproval")} </li>
  //                   <li
  //                     className="liTabs"
  //                   >
  //                     {t("Approved")}
  //                   </li>
  //                   <li
  //                     style={{ fontWeight: "bold" }}
  //                     className="active liTabs "
  //                   > {t("Archived")} </li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          {/* <Breadcrumb.Item>
            <Link to={`/citrine/programs`}> {t("Programs List")}</Link>
          </Breadcrumb.Item> */}
          <Breadcrumb.Item>
            <Link to={`/citrine/GAImprovementList/`}>
              {' '}
              {t('GA Report List')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: 'orange' }}
              to={`/citrine/GAImprovementDetails/${id}`}
            >
              {t('GA Report Details')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          {/* {renderGAReport()} */}

          <GridContainer xs={12} sm={12} md={12}>
            {content}
          </GridContainer>

          <Card>
            <CardBody>
              <GridContainer justify="space-around" alignItems="center">
                <GridItem>
                  <div
                    style={{
                      color: `${primaryColor[0]}`,
                      fontWeight: 'bold',
                    }}
                  >
                    {t("program's name")}:
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'normal',
                      }}
                    >
                      {`    ${GAReport &&
                        GAReport.programs &&
                        GAReport.programs[0].program_degree_name
                        }`}
                    </span>
                  </div>

                  <br />

                  <div
                    style={{
                      color: `${primaryColor[0]}`,
                      fontWeight: 'bold',
                    }}
                  >
                    {t("program's code")}:
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'normal',
                      }}
                    >
                      {`    ${GAReport &&
                        GAReport.programs &&
                        GAReport.programs[0].code
                        }`}
                    </span>
                  </div>

                  <br />

                  {/* <div
                    style={{
                      color: `${primaryColor[0]}`,
                      fontWeight: "bold",
                    }}
                  >
                    {t("Category")}:
                    <span
                      style={{
                        color: "black",
                        fontWeight: "normal",
                      }}
                    >
                      {`    ${GAReport && GAReport.programs && GAReport.programs[0].category_config_name }`}
                    </span>
                  </div> */}
                </GridItem>

                <GridItem>
                  <div
                    style={{
                      color: `${primaryColor[0]}`,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Institution')}:
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'normal',
                      }}
                    >
                      {`    ${GAReport &&
                        GAReport.programs &&
                        GAReport.programs[0].institution.name
                        }`}
                    </span>
                  </div>

                  <br />

                  <div
                    style={{
                      color: `${primaryColor[0]}`,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Category')}:
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'normal',
                      }}
                    >
                      {`    ${GAReport &&
                        GAReport.programs &&
                        GAReport.programs[0].category_config_name
                        }`}
                    </span>
                  </div>

                  <br />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <div
            className="card scrollable"
            style={{ border: 'none', marginTop: '10px' }}
          >
            <div
              style={{
                padding: '10px',
                borderRadius: '0px',
                color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
                background: whiteColor,
                width: '100%',
                boxShadow:
                  '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '0',
                wordWrap: 'break-word',
                fontSize: '.875rem',
                // borderRight: "1px solid lightgray"
              }}
            >
              <Tabs onChange={callback} type="card">
                <TabPane tab={t('GAs && Recommendations ')} key="1">
                  <AnalysisGA />
                </TabPane>

                {/* <TabPane tab={t("Target")} key="2">
                  <TabsOfTarget
                  // idProgram={PloReport && PloReport.program && PloReport.program}
                  />
                </TabPane> */}
                <TabPane tab={t('Previous Action Plan')} key="3">
                  <PreviousActionPlan
                    resource_id={GAReport && GAReport.id}
                    resource="graduateAttribute"
                  />
                </TabPane>
                {/* <TabPane tab={t("Assessment Analysis")} key="4">
                  <AssessmentAnalysis GAReport={GAReport&&GAReport}/>
                </TabPane> */}
              </Tabs>
            </div>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <div
            style={{
              padding: '10px',
              borderRadius: '0px',
              color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
              background: whiteColor,
              width: '100%',
              boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              minWidth: '0',
              wordWrap: 'break-word',
              fontSize: '.875rem',
              height: 615,
              border: '1px solid lightgray',
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-around">
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderActivities}
                    >
                      <FaHistory
                        color={showActivities ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderTasks}
                    >
                      <FaTasks
                        color={showTasks ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={(event) => {
                        handleClickOpen(event);
                      }}
                    >
                      <FaProjectDiagram
                        color={showDiagram ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderReports}
                    >
                      <GoReport
                        color={showReports ? primaryColor[0] : 'black'}
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  className={classes1.root}
                  className="card"
                  className="scrollable"
                >
                  <div>{renderRow()}</div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
      <AddDocument
        programId={currentGaReport && currentGaReport.program}
        /*programId={GAReport && GAReport.program}*/ open={openAddDocumentModal}
        handleClickOpen={handleClickOpenAddDocumentModal}
        handleClose={handleCloseAddDocumentModal}
      />

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
