// @material-ui/core components
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvaluationList } from "../../../../../reduxToolkit/features/CourseEvaluation/CourseEvaluationSlice.js";
import EditCommentsResponseModal from "../../../../Components/EditCommentsResponseModal";
import useTableCourseEvaluation from "../../../../Components/useTableCourseEvaluation";
import EditFeedbackSurvey from "../../../../Components/EditFeedbackSurvey";



const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);
const headCells = [
  {
    id: "Type",
    label: "Type",
  },
  {
    id: "Students Feedback",
    label: "Students Feedback",
  },
  {
    id: "Program Response",
    label: "Program Response",
  },
  ,
];
export default function ProgramEvaluationSurvey(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const callback = (key) => {
    console.log(key);
  };
  let obj = {
    resource: props.resource,
    resource_id: props.resource_id,
    type: props.type
  }
  useEffect(() => {
    dispatch(fetchEvaluationList(obj))
  }, [dispatch]);

  const CourseEvaluationList = useSelector(
    (state) => state.CourseEvaluation.CourseEvaluationList
  );
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  console.log("CourseEvaluationList", props.CourseEvaluationList);
  console.log("props.idCourse", props.idCourse);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTableCourseEvaluation(records, headCells, filterFn);
  const evaluation_res = Number(props.CourseEvaluationList?.[0]?.evaluation_result).toFixed(1)
  return (
    <div>
      <p style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}>
        Program Evaluation Survey (PES)
      </p>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div
              style={{
                margin: "0px 10px 10px 10px ",
                borderRight: "1px solid lightgray",
                borderLeft: "1px solid lightgray",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#e6e6e6",
                    border: "1px solid  black",
                  }}
                >
                  {"Date of Survey:"}
                </TableCell>
                <TableCell
                  style={{
                    width: "16%",
                    border: "1px solid  black",
                    color: "#4169E1",
                    borderLeft: "none",
                  }}
                >

                  {props.CourseEvaluationList &&
                    props.CourseEvaluationList[0] &&
                    props.CourseEvaluationList[0].date_of_survey}
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#e6e6e6",
                    border: "1px solid  black",
                    borderLeft: "none",
                  }}
                >
                  {"Number of Participants:"}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid  black",
                    color: "#4169E1",
                    borderLeft: "none",
                  }}
                >
                  {props.CourseEvaluationList &&
                    props.CourseEvaluationList[0] &&
                    props.CourseEvaluationList[0].number_of_participants}
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#e6e6e6",
                    border: "1px solid  black",
                    borderLeft: "none",
                  }}
                >
                  {"Percentage of Participation:"}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid  black",
                    color: "#4169E1",
                    borderLeft: "none",
                  }}
                >
                  {props.CourseEvaluationList &&
                    props.CourseEvaluationList[0] &&
                    props.CourseEvaluationList[0].percentage_of_participation}
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#e6e6e6",
                    border: "1px solid  black",
                    borderLeft: "none",
                  }}
                >
                  {"Evaluation Result:"}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid  black",
                    color: "#4169E1",
                    borderLeft: "none",
                  }}
                >
                  {evaluation_res}
                </TableCell>
              </TableRow>
              <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
                <TblHead />
                <TableBody>
                  {props.CourseEvaluationList && props.CourseEvaluationList[0] &&
                    props.CourseEvaluationList[0].survey_feedback &&
                    props.CourseEvaluationList[0].survey_feedback.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item && item.evaluation_type}</TableCell>
                        <TableCell>
                          {item &&
                            item.feed_back &&
                            item.feed_back.map((el) => (
                              <ul>
                                <li>{el.name}</li>
                              </ul>
                            ))}
                        </TableCell>
                        <TableCell>
                          <div>
                            <EditFeedbackSurvey
                              idFeedback={item && item.id}

                              obj={obj}

                            />
                            {item && item.comments}
                            {/* <EditCommentsResponseModal /> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
