import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { DataGrid } from '@material-ui/data-grid'
//import Select from '@mui/material/Select'
import { Form, notification, Select, Input } from 'antd'
// import Button from "components/CustomButtons/Button.js";
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllPlos } from 'reduxToolkit/features/PLO/PLOSpecSlice'
import { mapPlosToClo } from '../../../../../reduxToolkit/features/CLO/CloSpecSlice'
// import { fetchNotAffectedCourses } from "reduxToolkit/features/program/ProgramSpecSlice";
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { fetchProgramsByCourseSpec } from 'reduxToolkit/features/program/ProgramSpecSlice'
import ErrorModal from '../../../../Components/ErrorModal.jsx'
import ProgramsPage from '../../program/ProgramsPage'

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
export default function AddPlosToClo(props) {
  const { t } = useTranslation()
  // const { Option } = Select;
  //console.log('program id in add plo modal', program)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const programsStatus = useSelector(
    (state) => state.programSpec.programsStatus,
  )
  const plosStatus = useSelector((state) => state.ploSpec.plosStatus)
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const [form] = Form.useForm()
  const [ploSpec_ids, setSelectedPlos] = useState([])
  const [ploSpec_id, setPloSpec_id] = useState('')
  const [selectedPrograms, setSelectedPrograms] = useState([])
  const [plos, setPlos] = useState([])
  const [selected_list, setSelected_list] = useState(null)
  // const [mastered, setMastered] = useState([]);
  const dispatch = useDispatch()

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  useEffect(() => {
    let result = props?.clo?.ploSpec_details?.map(({ id }) => id)
    // let result = props.clo?.map((item)=> item?.ploSpec_details?.map(({id})=>id))

    // console.log(result)
    form.setFieldsValue({
      plos: result,
    })
    dispatch(fetchProgramsByCourseSpec(props.courseId))
    dispatch(fetchAllPlos())
  }, [])
  const programs = useSelector(
    (state) => state.programSpec.programsByCourseSpec,
  )

  const allPlos = useSelector((state) => state.ploSpec.allplos)
  //const ploListMapped = props?.clo?.ploSpec_details.map(({ id }) => id)
  const ploListUnMapped = Object.values(
    programs.map((program, index) => program.plos_list.map((el) => el.id)),
  ).reduce((acc, curr) => acc.concat(curr), [])
  const listPlosMap = allPlos.filter((plo) => ploListUnMapped.includes(plo.id))
  console.log(allPlos)
  const { Option } = Select
  let plos_list = []
  let practiced_list = []
  let mastered_list = []

  const onFinish = async (values) => {
    console.log(values)
    const ploList = Object.values(values).reduce(
      (acc, curr) => acc.concat(curr),
      [],
    )
    console.log(ploList)

    let id = props.cloId
    const resultAction = await dispatch(
      mapPlosToClo({ id, ploSpec_ids: ploList }),
    )
    if (mapPlosToClo.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `update successfully!`)
      props.setPing(!props.ping)
      handleClose()
      form.resetFields()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        handleClose()
        form.resetFields()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        handleClose()
        form.resetFields()
      }
    }
  }
  const onChangePlos = (event) => {
    console.log(event.target.value)
    setPloSpec_id(event.target.value)
    // let alt = allPlos && allPlos.filter(plo => value.includes(plo.id))
    ploSpec_ids.push(event.target.value)
  }

  const columns = [
    {
      field: 'program',
      headerName: 'Program',
      width: 250,
      valueGetter: (params) => params.value,
      renderCell: (cellValues) => {
        return <div>{cellValues && cellValues.row.program_degree_name}</div>
      },
    },
    {
      field: 'plo',
      headerName: 'PLO',
      width: 300,
      type: 'singleSelect',
      // valueOptions: (cellValues)=>{ [cellValues.row.plos_list]},
      // preProcessEditCellProps: (params) => {

      //     return { ...params.props };
      // }
      renderCell: (cellValues) => {
        console.log(' cellValues.row.plos_list', cellValues)

        return (
          <Box sx={{ minWidth: 240 }}>
            <FormControl fullWidth>
              <Select
                labelId="ploSpec_ids"
                id="ploSpec_ids"
                value={ploSpec_id}
                onChange={(e) => onChangePlos(e)}
              >
                {cellValues &&
                  cellValues &&
                  cellValues.row.plos_list.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )
      },
    },
  ]
  return (
    <div>
      <MenuItem
        disabled={
          props.selectedRows &&
          props.selectedRows.length !== 1 &&
          props.viewList
        }
        onClick={() => {
          handleClickOpen()
          props.handleDropDownButtonClose()
        }}
      >
        <ListItemText primary="Aligned PLOs" />
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Aligned PLOs')}</center>
        </DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  key={'plos'}
                  label={'PLOs Aligned'}
                  name={'plos'}
                  rules={[
                    {
                      required: true,
                      message: 'Please select plo.',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select PLO"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {listPlosMap?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.code + ' : ' + el.program_details}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* {props?.clo?.ploSpec_details?.length > 0 ? (
                  <Form.Item
                    key={'plos'}
                    label={'PLOs Aligned'}
                    name={'plos'}
                    rules={[
                      {
                        required: true,
                        message: 'Please select plo.',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select PLO"
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      {props?.clo?.ploSpec_details?.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ''
                )} */}

                {/* {programs.map((program, index) => (
                  <Form.Item
                    key={program.id}
                    label={program.program_degree_name}
                    name={[program.program_degree_name]}
                    rules={[
                      {
                        required: true,
                        message: 'Please select plo.',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select PLO"
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      {program?.plos_list?.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))} */}
                <Form.Item {...tailLayout}>
                  <Button
                    //type="primary"
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  //htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>

          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                {programs &&
                  programs.map((program) => (
                    <Form.Item
                      name="name_id"
                      label ={program.program_degree_name}
                      rules={[
                        {
                          required: true,
                          message: 'Please input the State field.',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select State "
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {program?.plos_list?.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ))}

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer> */}

          {/* <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer
                      style={{
                        height: 400,
                        width: 650,
                      }}
                    >
                      <DataGrid
                        rows={(programs && programs) || []}
                        columns={columns}
                        rowHeight={75}
                        //getEstimatedRowHeight={() => 400}
                        pageSize={20}
                        rowsPerPageOptions={[]}
                        hideFooterPagination
                      />
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose()
                            }}
                          >
                            {t('Cancel')}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t('Save')}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer> */}
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
