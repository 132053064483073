import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Select } from "antd";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNewGenaralRecommendation, fetchAPRecommendations } from "../../reduxToolkit/features/Recommendations/RecommendationsSlice";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddAPRecommendationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [annualProgram_id, setAnnualProgram_id] = useState(props.annualProgram_id);
  const [recommendation, setRecommendation] = useState();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  const handleSend = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewGenaralRecommendation({ annualProgram_id, recommendation })
      );
      unwrapResult(resultAction);
      dispatch(fetchAPRecommendations(props.annualProgram_id));
      setRecommendation("");
      props.fetchRecs();
    } catch (err) {
      console.error("Failed to save the action: ", err);
    } finally {
      setAddRequestStatus("idle");
      dispatch(fetchAPRecommendations(props.annualProgram_id));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        color="primary"
        round
        style={{ border: "1px solid #D1CF4B", marginTop: "10px", backgroundColor: "#D1CF4B" }}
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <AddIcon style={{ color: "white" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add  Recommendation")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Recommendation*")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <TextArea
                rows={4}
                id="recommendation"
                value={recommendation}
                onChange={(event) => setRecommendation(event.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleSend();
            }}
            color="primary"
          >
            {t(" Send")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
