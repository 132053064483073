import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// core components
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Table } from "antd";
import Button from "components/CustomButtons/Button.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import {
  deletePlo, fetchCourseSpecPerfPLO, fetchPlosByProgramId
} from "../../../../reduxToolkit/features/PLO/PLOSpecSlice";
import { DeleteCourseSpecPerformancePlo } from "reduxToolkit/features/PLO/CourseSpecPerformancePlo";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../../Components/ErrorModal";
import PLOModal from "./PLOAddModal";
import PLODropDownButton from "./PLODropDownButton.jsx";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import DeleteIcon from "@material-ui/icons/Delete";
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);

const PLOExcerpt = ({ plo, disabledStatus, courses, isEditor, ploID, assessment_calculation_method }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let { programId } = useParams();
  const [performanceToDelete, setPerformanceToDelete] = useState(null);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setPerformanceToDelete(id);
    setOpen(true);

  };

  const handleClose = () => {
    setPerformanceToDelete(null);
    setOpen(false);
  };
  const handleDeletePlo = async (id) => {
    dispatch(deletePlo(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log("originalPromiseResult", originalPromiseResult);
        dispatch(fetchPlosByProgramId(programId));
        openNotificationWithIcon("success", `Deleted`);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

  const handleDeleteCoursePlo = async (id) => {
    dispatch(DeleteCourseSpecPerformancePlo(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log("originalPromiseResult", originalPromiseResult);
        dispatch(fetchPlosByProgramId(programId));
        openNotificationWithIcon("success", `Deleted`);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Performance",
      dataIndex: "performance",
      key: "performance",
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => (
        <Button
          simple
          size="xs"
          color="primary"
          onClick={() => {
            console.log('record.id', record.id);
            setPerformanceToDelete(record.id);
            handleClickOpen(record.id);
          }}
          //style = {{width:"5x"}}
          // style={{pointerEvents: isEditor ? '' : 'none'}}
          justIcon
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  console.log('performanceToDelete', performanceToDelete)
  // const data = plo.ploIndicatorsSpec
  const data = plo.courses;
  console.log(data);
  /***************************************************** */

  /***************************************************** */
  const [rows, setRows] = useState([]);
  useEffect(() => {

    let orderedData = data && data.map((item) => {
      return {
        id: item.id,
        performance: item.performance,
        code: item.code,
        title: item.title,
        category: item.category,
      };
    });
    setRows(orderedData);

  }, [data]);
  console.log(rows)

  return (
    <GridItem xs={12} sm={12} md={11}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <Link to={`/citrine/programSpec/${programId}/PLOs/${plo.id}/PLOIndicators`} >
                <Typography>{plo && plo.name}</Typography>
              </Link>
              <br />

              {/* {data && assessment_calculation_method && (
                <div>
                  <Table
                    columns={columns}
                    dataSource={rows}
                    pagination={false}
                  />
                </div>
              )} */}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              {/* {!disabledStatus &&
                <Button
                  variant="contained"
                  color="primary"
                  justIcon
                  round
                  onClick={handleClickOpen}
                >
                  <DeleteIcon />
                </Button>
              } */}
              {disabledStatus ? <p></p> : (
                <PLODropDownButton
                  handleDeletePlo={handleDeletePlo}
                  plo={plo}
                  courses={courses && courses}
                  programId={programId}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteCoursePlo}
        Element_ID={performanceToDelete}
        open={open}
        handleClose={handleClose}
      />
    </GridItem>
  );
};

export default function PLOsPage() {
  const { t } = useTranslation();

  let { programId } = useParams();
  console.log("programId", programId);

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();
  const [isEditor, setIsEditor] = useState(true);
  const [statusProgram, setStatusProgram] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [question, setQuestion] = React.useState(

  )
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchPLOs = async () => {
    try {
      const resultAction = await dispatch(
        fetchPlosByProgramId(programId)
      ).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const plosStatus = useSelector((state) => state.ploSpec.plosStatus);

  useEffect(() => {
    dispatch(fetchProgramById(programId)).then(unwrapResult).then((program) => {
      if (program.assessment_calculation_method === 'question')
        setQuestion(true)

    });
    fetchPLOs();
    //dispatch(fetchCourseSpecPerformancePlo);
    setStatusProgram(localStorage.getItem("state"));
    if (localStorage.getItem("state") != "Draft") {
      setDisabledStatus(true);
    }
  }, []);
  // useEffect(() => {
  //   dispatch(fetchCourseSpecPerfPLO(ploID));
  // }, [dispatch]);
  //const CourseSpecPerfPLO = useSelector((state) => state.CourseSpecPerfPLO.CourseSpecPerformancePlo)
  const program = useSelector((state) => state.programSpec.program);
  const plos = useSelector((state) => state.ploSpec.plos);

  console.log("program", program);
  console.log("plos", plos);

  let content;

  if (plosStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (plosStatus === "succeeded") {
    if (plos && plos.length) {
      content = plos.map((plo) => (
        //program.active &&
        <PLOExcerpt
          key={plo.id}
          plo={plo}
          disabledStatus={disabledStatus}
          courses={program && program.coursesSpec}
          isEditor={isEditor}
          ploID={plo.id}
          assessment_calculation_method={question}
        />
      ));
    } else {
      content = <h5>{t("This Program has no PLOs to display!")}</h5>;
    }
  } else if (plosStatus === "failed") {
    content = <div>An error has accured</div>;
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t("Programs")} </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}`}>
            <span className={titleClasses.title}>
              {" "}
              {`> ${program && program.program_degree_name}`}{" "}
            </span>
          </Link>
          <span className={titleClasses.title}>{t(`> PLOs`)} </span>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          {isEditor && (
            <PLOModal programId={programId} disabledStatus={disabledStatus} />
          )}
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
