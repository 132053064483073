import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchGAReports,
  UpdateGAReport
} from "../../../../reduxToolkit/features/GAReport/GAResportSlice";
import { fetchGroupStatesByReport } from "reduxToolkit/features/groupState/GroupStateSlice";
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Link } from 'react-router-dom'
import Board, { moveCard } from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Card, notification } from "antd";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next'
import DropDownButtonGAReport from "../../../Components/DropDownButtonGAReport";
import ErrorModal from "../../../Components/ErrorModal";
import Info from "components/Typography/Info.js";
import Moment from "moment";


export default function GAImprovementList(props) {
  const initialBoard = {
    columns: [],
  }
  let content
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [board, setBoard] = useState(initialBoard)
  const [refrech, setrefrech] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const GAWorkFlow = (GAReports) => {
    console.log(board, "board");
    var NewBoard = { ...initialBoard };
    let tab = [];
    for (let i = 0; i < GAReports.length; i++) {
      tab = Object.values(NewBoard)[0];
      let array = [];
      for (let j = 0; j < tab.length; j++) {
        if (GAReports[i].state_object === tab[j].title) {
          tab[j].cards.push(GAReports[i]);
        }
        tab[j].cards = [...tab[j].cards];
        tab[j].cards = [...new Set(tab[j].cards)];
      }
    }
    setBoard(NewBoard);
    console.log(board, "board");
  };

  const onCardMove = async (card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    let NewState;

    let tab = Object.values(board)[0];
    for (let j = 1; j < tab.length; j++) {
      NewState = tab[destination.toColumnId].title;
    }

    let data = {
      updatedGAReport: {
        due_date: card.due_date,
        state_object: NewState,
        process_identification: card.process_identification,
      },
      id: card.id,
    };
    const resultAction = await dispatch(UpdateGAReport(data));

    if (UpdateGAReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchGAReports())
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  }

  function renderCard(card, t, r) {

    return (
      <div>
        {card &&

          <Card
            style={{
              border: "1px solid lightgray",
              width: "220px",
              height: "150px",
            }}
          >
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem key={Element.id} xs={12} sm={12} md={10}>
                {Moment(card.date).format("YYYY-MM-DD")}
                <Link to={`/citrine/GAImprovementDetails/${card.id}`}>
                  <strong>
                    <p>
                      {card && card.program_detail && card.program_detail.program_degree_name && card.program_detail.program_degree_name}
                    </p>
                  </strong>
                </Link>
              </GridItem>
              <GridItem key={Element.id} xs={12} sm={12} md={2}>
                <DropDownButtonGAReport
                  GAReport_ID={card.id}
                  GAWorkFlow={GAWorkFlow}
                  data={card}
                  id={card.id}
                  fetchGAReports={fetchData}
                />
              </GridItem>
            </GridContainer>
          </Card>

        }
      </div>
    );
  }

  const fetchData = () => {
    setrefrech(true)
  }


  useEffect(() => {
    dispatch(fetchGroupStatesByReport('GAReport')).then(unwrapResult).then((GAReport_StateBoard) => {
      console.log("GAReport_StateBoard", GAReport_StateBoard)
      if (GAReport_StateBoard?.colums?.length > 0) {
        GAReport_StateBoard.colums.map((item) => {
          initialBoard.columns.push({
            id: item.id,
            title: item.title,
            cards: [],
          })
        })
      }

    }).catch((error) => {
      setHandledError(error);
      handleOpenErrorModal();
    })
  }, []);

  useEffect(() => {
    dispatch(fetchGAReports())
      .then(unwrapResult)
      .then((GAReports) => {
        GAWorkFlow(GAReports)
      }).catch((error) => {
        setHandledError(error);
        handleOpenErrorModal();
      })

    return () => setrefrech(false)
  }, [refrech])


  const GAReportsStatus = useSelector((state) => state.GAReport.GAReportsStatus);
  const GAReports = useSelector((state) => state.GAReport.GAReports);

  const GAReport_StateBoard = useSelector(
    (state) => state.groupState.groupStatesByReport,
  )
  const groupStatesByReportStatus = useSelector(
    (state) => state.groupState.groupStatesByReportStatus,
  )


  if (GAReportsStatus === "loading" || groupStatesByReportStatus === "loading") {

    content = <GridContainer style={{ height: "100%" }} justifyContent="center" alignItems="center"><GridItem><CircularProgress color="inherit" /></GridItem></GridContainer>
  }

  if (GAReportsStatus === "failed" || groupStatesByReportStatus === "failed") {
    return (
      <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
        We're having trouble retrieving the GA reports. Please try again later. If the issue persists, contact support for assistance.
      </Info></GridItem></GridContainer>
    )
  }

  if (GAReportsStatus === "succeeded" && groupStatesByReportStatus === "succeeded") {
    if (GAReport_StateBoard.colums.length == 0) {
      content = <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
        We could not find any GA reports states. Please add the needed states in the configuration menu or contact support for assistance.
      </Info></GridItem></GridContainer>
    } else {
      content = <Card style={{ minHeight: '600px' }}>
        <Board
          onCardDragEnd={onCardMove}
          border="1px solid red"
          renderCard={renderCard}
          disableColumnDrag
          className="react-kanban-board react-kanban-column"
        >
          {board}
        </Board>
      </Card>
    }
  }


  return (
    <>

      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/GAImprovementList`}>
            {t('Graduate Attributes Report')}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      {content}

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  )
}
