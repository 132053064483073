import { FormOutlined } from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import { warningColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../../Components/ErrorModal";
import {
  addNewRecommendations,
  fetchRecommandationsByResourceAndResourceIdAndType
} from "reduxToolkit/features/Recommendations/RecommendationsSlice";
import {
  fetchImprovementByResourceAndResourceIdAndType,
  updateAnnualProgramAnalysis
} from "reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";

export default function EditImprovement(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Option } = Select;

  const [open, setOpen] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [form] = Form.useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let type = "PLO";
  let resource = "annualProgram";
  let resource_id = props.AnnualProgramReport?.id;

  let getAPIdata = {
    resource,
    resource_id,
    type,
  };

  useEffect(() => {
    dispatch(fetchImprovementByResourceAndResourceIdAndType(getAPIdata))
      .unwrap()
      .then((improvement) => {
        // handle result here
        form.setFieldsValue({
          strength: improvement?.[0]?.strength,
          priorities_for_improvement_ids: improvement?.[0]?.priorities_for_improvement_names,
          areas_for_improvement: improvement?.[0]?.areas_for_improvement,
        });
      });
  }, [open]);

  const improvementByResourceAndResourceIdAndType = useSelector(
    (state) =>
      state.AnnualProgramAnalysis.improvementByResourceAndResourseIdAndType
  );

  const onFinish = async (values) => {
    const theValues = values?.priorities_for_improvement_ids ? { priorities_for_improvement_ids } : values
    let id = improvementByResourceAndResourceIdAndType?.[0]?.id;
    let data = {
      updatedAnnualProgramAnalysis: theValues,
      id: id,
    };
    const resultAction = await dispatch(updateAnnualProgramAnalysis(data));

    if (updateAnnualProgramAnalysis.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `updated successfully`);
      dispatch(fetchImprovementByResourceAndResourceIdAndType(getAPIdata));
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const [
    priorities_for_improvement_ids,
    setPriorities_for_improvement_ids,
  ] = useState([]);

  useEffect(() => {
    dispatch(fetchRecommandationsByResourceAndResourceIdAndType(getAPIdata));
  }, [priorities_for_improvement_ids]);

  const RecommandationsByResourceAndResourceIdAndType = useSelector(
    (state) =>
      state.RecommendationsSlice.RecommandationsByResourceAndResourceIdAndType
  );

  const handleChange = (value) => {
    if (value.length > 0) {
      // handle result here
      let chosenKWObjects =
        RecommandationsByResourceAndResourceIdAndType?.filter((rec) =>
          value.includes(rec.recommendation)
        );
      console.log("chosenKWObjects", chosenKWObjects);
      let ids = chosenKWObjects?.map((el) => {
        return el.id;
      });
      console.log("ids", ids);

      let mapped =
        RecommandationsByResourceAndResourceIdAndType?.map((el) => {
          return el.recommendation;
        });

      const recommendation = [...value].pop();
      if (mapped.includes(recommendation)) {
        setPriorities_for_improvement_ids(ids);
      } else {
        dispatch(
          addNewRecommendations({
            recommendation: recommendation,
            resource: "annualProgram",
            resource_id: props.AnnualProgramReport?.id,
            type: "PLO",
          })
        )
          .unwrap()
          .then((newRecommandation) => {
            console.log("newRecommandation", newRecommandation);
            setPriorities_for_improvement_ids(ids.concat(newRecommandation.id));
          });
      }
    } else {
      setPriorities_for_improvement_ids([]);
    }
  };
  console.log("priorities_for_improvement_ids", priorities_for_improvement_ids);
  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">
          {t("Edit the analysis")}
        </DialogTitle>
        <DialogContent>
          <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
            <GridContainer>
              {props.option === "strength" && (
                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item label={t("Strengths")} name="strength">
                    <Input.TextArea />
                  </Form.Item>
                </GridItem>
              )}

              {props.option === "priorities_for_improvement" && (
                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item
                    label={t("Priorities for improvement")}
                    name="priorities_for_improvement_ids"
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Priorities for improvement"
                      onChange={handleChange}
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      {RecommandationsByResourceAndResourceIdAndType?.map(
                        (recommandation) => (
                          <Option
                            key={recommandation.id}
                            value={recommandation.recommendation}
                          >
                            {recommandation.recommendation}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </GridItem>
              )}

              {props.option === "areas_for_improvement" && (
                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item
                    label={t("Areas for improvement")}
                    name="areas_for_improvement"
                  >
                    <Input.TextArea />
                  </Form.Item>
                </GridItem>
              )}
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button color="primary" onClick={handleClose}>
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={handleClose}
                      >
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
