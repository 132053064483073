import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";

const initialState = {
  RecommandationsByResourceAndResourceIdAndTypeStatus:"idle",
  RecommandationsByResourceAndResourceIdAndType:[],
  RecommandationsByResourceAndResourceIdStatus:"idle",
  RecommandationsByResourceAndResourceId:[],
  CourseRecommendations: [],
  PloRecommendations: [],
  APRecommendations: [],
  GARecommendations: [],
  PeoRecommendations: [],
  KPIsRecommendations:[],
  RecommendationsStatus: "idle",
  RecommendationStatus: "idle",
  Recommendation: null,
  error: null,
  id: null,
};

export const fetchRecommandationsByResourceAndResourceIdAndType = createAsyncThunk('Recommendations/fetchRecommandationsByResourceAndResourceIdAndType', async (data,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/recommendations/by_resource_and_type/?resource=${data.resource}&resource_id=${data.resource_id}&type=${data.type}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log('response.data', response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchRecommandationsByResourceAndResourceId = createAsyncThunk('Recommendations/fetchRecommandationsByResourceAndResourceId', async (data,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/recommendations/by_resource/?resource=${data.resource}&resource_id=${data.resource_id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log('response.data', response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchCourseRecommendations = createAsyncThunk(
  "Recommendations/fetchRecommendations",
  async (course_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/recommendations/course/?course_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchAPRecommendations = createAsyncThunk(
  "Recommendations/fetchAPRecommendations",
  async (annualProgram_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/recommendations/annualProgram/?annualProgram_id=${annualProgram_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchPloRecommendations = createAsyncThunk(
  "Recommendations/fetchPloRecommendations",
  async (plo_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/recommendations/plo/?plo_id=${plo_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchGARecommendations = createAsyncThunk(
  "Recommendations/fetchGARecommendations",
  async (GA_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/recommendations/ga/?graduateAttribute_id=${GA_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchPeoRecommendations = createAsyncThunk(
  "Recommendations/fetchPeoRecommendations",
  async (peo_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/recommendations/peo/?peo_id=${peo_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchKPIsRecommendations = createAsyncThunk(
  "Recommendations/fetchKPIsRecommendations",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl +
          `/recommendations/kpis/?type=${obj.type}&kpi_id=${obj.Kpi_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchRecommendationById = createAsyncThunk(
  "Recommendations/fetchRecommendationById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/recommendations/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
// to add recommendation in course reprte
export const addNewRecommendations = createAsyncThunk(
  "Recommendations/addNewRecommendations",
  async (initialRecommendations, { rejectWithValue }) => {
    try {
      console.log("initialRecommendations", initialRecommendations);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/recommendations/create/`,
        initialRecommendations,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewGenaralRecommendation = createAsyncThunk(
  "Recommendations/addNewGenaralRecommendation",
  async (initialRecommendations, { rejectWithValue }) => {
    try {
      console.log("initialRecommendations", initialRecommendations);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/recommendations/create/`,
        initialRecommendations,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


export const addNewKPIRecommendation = createAsyncThunk(
  "Recommendations/addNewKPIRecommendation",
  async (initialRecommendations, { rejectWithValue }) => {
    try {
      console.log("initialRecommendations", initialRecommendations);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/recommendations/create/`,
        initialRecommendations,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const addNewComments = createAsyncThunk(
  "Recommendations/addNewComments",
  async (initialComments, { rejectWithValue }) => {
    try {
      console.log("initialComments", initialComments);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/comments/create/`,
        initialComments,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteRecommendation = createAsyncThunk(
  "Recommendations/deleteRecommendation",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        BaseUrl + `/recommendations/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateRecommendation = createAsyncThunk(
  "Recommendations/updateRecommendation",
  async (data, { rejectWithValue }) => {
    console.log(data, "updateRecommendation");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/recommendations/${data.id}/edit/`,
        data.updatedRecommendation,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const RecommendationsSlice = createSlice({
  name: "Recommendations",
  initialState,

  extraReducers: {
    //fetch course recommendations
    [fetchRecommandationsByResourceAndResourceIdAndType.pending]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdAndTypeStatus = "loading";
    },
    [fetchRecommandationsByResourceAndResourceIdAndType.fulfilled]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdAndTypeStatus = "succeeded";

      state.RecommandationsByResourceAndResourceIdAndType = action.payload;
    },
    [fetchRecommandationsByResourceAndResourceIdAndType.rejected]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdAndTypeStatus = "failed";
    },

    [fetchRecommandationsByResourceAndResourceId.pending]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdStatus = "loading";
    },
    [fetchRecommandationsByResourceAndResourceId.fulfilled]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdStatus = "succeeded";

      state.RecommandationsByResourceAndResourceId = action.payload;
    },
    [fetchRecommandationsByResourceAndResourceId.rejected]: (state, action) => {
      state.RecommandationsByResourceAndResourceIdStatus = "failed";
    },

    [fetchCourseRecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchCourseRecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";

      state.CourseRecommendations = action.payload;
    },
    [fetchCourseRecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchAPRecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchAPRecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";

      state.APRecommendations = action.payload;
    },
    [fetchAPRecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    //fetch plo recommendations
    [fetchPloRecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchPloRecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";
      state.PloRecommendations = action.payload;
    },
    [fetchPloRecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    //fetch GA recommendations
    [fetchGARecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchGARecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";
      state.GARecommendations = action.payload;
    },
    [fetchGARecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    //fetch peo recommendations
    [fetchPeoRecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchPeoRecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";
      state.PeoRecommendations = action.payload;
    },
    [fetchPeoRecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    //fetchKPIsRecommendations
    [fetchKPIsRecommendations.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchKPIsRecommendations.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";
      state.KPIsRecommendations = action.payload;
    },
    [fetchKPIsRecommendations.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    //fetch recommendation by id

    [fetchRecommendationById.pending]: (state, action) => {
      state.RecommendationsStatus = "loading";
    },
    [fetchRecommendationById.fulfilled]: (state, action) => {
      state.RecommendationsStatus = "succeeded";
      state.Recommendation = action.payload;
    },
    [fetchRecommendationById.rejected]: (state, action) => {
      state.RecommendationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // add new recommendations
    [addNewGenaralRecommendation.fulfilled]: (state, action) => {},
    [addNewGenaralRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  // addNewKPIRecommendation
  [addNewKPIRecommendation.fulfilled]: (state, action) => {},
  [addNewKPIRecommendation.rejected]: (state, action) => {
    if (action.payload) {
      state.error = action.payload;
    } else {
      state.error = action.error;
    }
  },

    


    [addNewRecommendations.fulfilled]: (state, action) => {},
    [addNewRecommendations.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // remove recommendation
    [deleteRecommendation.fulfilled]: (state, action) => {},
    [deleteRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // update recommendation
    [updateRecommendation.fulfilled]: (state, action) => {
      // console.log(action)
    },
    [updateRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    reducers: {},
  },
});

export const {} = RecommendationsSlice.actions;

export default RecommendationsSlice.reducer;
