// @material-ui/core components
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Tooltip } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  rerunCalculations,
  fetchCourseById
} from "../../../../reduxToolkit/features/course/CoursesSlice";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import CourseReportModal from "../../../Components/CourseReportModal";
import CreateSurveyModal from "../../../Components/CreateSurveyModal";


const useStyles2 = makeStyles(styles);

export default function ClosQuestionList() {

  const { t } = useTranslation();
  const classes2 = useStyles2();
  const dispatch = useDispatch();

  const { id, programId } = useParams();
  const semester_id = localStorage.getItem('semester_id')


  const [openCourseReportModal, setOpenCourseReportModal] = React.useState(false);
  const [openSurveyModal, setOpenSurveyModal] = React.useState(false);
  const [score_course_by_question, setScore_course_by_question] = useState();

  const handleOpenCourseReportModal = () => {
    setOpenCourseReportModal(true);
  };

  const handleOpenSurveyModal = () => {
    setOpenSurveyModal(true);
  };


  const rerunCourseCalculations = () => {
    if (semester_id) {
      dispatch(rerunCalculations({ course_id: id, semester_id })).unwrap()
        .then((scores) => {
          setScore_course_by_question(scores.score_by_course)
          dispatch(fetchCourseById(id))
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          console.log(rejectedValueOrSerializedError)
        })
    }
  }

  useEffect(() => {
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        rerunCourseCalculations()
      });
  }, []);

  const course = useSelector((state) => state.course.course);



  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/program/${programId}`}>
              {course?.program_detail?.[0]?.program_degree_name}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/closQuestions_list/program/${programId}/course/${id}`}
            >
              {t("Clos Questions List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>


      <Card>
        <CardHeader>
          <GridContainer justifyContent="space-between" alignItems="center">
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center">
                <GridItem>
                  <p
                    style={{
                      color: "#8F6682",
                    }}
                  >
                    <strong>
                      {course?.program_detail?.[0]?.code}
                    </strong>
                  </p>
                </GridItem>
                <GridItem>
                  <p
                    style={{
                      color: "#6C7B9D",
                    }}
                  >
                    <strong>{course?.code}</strong>
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center">
                <GridItem>
                  <p
                    style={{
                      color: "#8F6682",
                    }}
                  >
                    <strong>
                      {course?.program_detail?.[0]?.program_degree_name}
                    </strong>
                  </p>
                </GridItem>
                <GridItem>
                  <p
                    style={{
                      color: "#6C7B9D",
                    }}
                  >
                    <strong>{course?.title}</strong>
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center">
                <GridItem>
                  <strong>
                    <p>{t("Score Course")} : {score_course_by_question}%</p>
                  </strong>
                </GridItem>
                <GridItem>
                  <strong>
                    <p>
                      {t("Total actual weight")} :
                      {course?.total_actual_weight}
                    </p>
                  </strong>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              {/* <DropDownButtonCourseReport_Survey course={course} /> */}
              <CustomDropdown
                isButtonIcon
                dropdownList={[
                  { text: "Create Survey", onClickHandler() { handleOpenSurveyModal() } },
                  { text: "Create Course Report", onClickHandler() { handleOpenCourseReportModal() } },
                  { text: "Rerun CLOs Scores", onClickHandler() { rerunCourseCalculations() } },
                ]}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>

      <GridContainer>
        {course?.clos?.map((item, id) => (
          <GridItem
            key={id}
            xs={12}
            sm={6}
            md={4}
            component={Link}
            to={`/citrine/result_calcul_Clos_Questions/program/${programId}/course/${course.id}/clo/${item.id}`}
          >
            <Card style={{
              zIndex: 0,
            }}>
              <CardHeader
                style={{
                  textAlign: "center",
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h5 style={{ color: "white", backgroundColor: 'black', borderRadius: 50, padding: 5 }}>{item?.code}</h5>
                  <h6 style={{ color: "white", backgroundColor: item?.score_clo_status === 'Successful' ? 'green' : 'red', borderRadius: 50, padding: 5 }}>{item?.score_clo_status ?? 'Not Set'}</h6>
                </div>
                <h4>
                  <p key={id} className={classes2.cardCategory}>
                    <Tooltip title={item?.title} placement="topRight">
                      {item?.title?.length > 50 ? item.title.slice(0, 50) + '...' : item.title}
                    </Tooltip>
                  </p>
                </h4>
                <GridContainer alignItems="baseline" justifyContent="space-around">
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#968988",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {item &&
                            item.score_clo_question &&
                            item.score_clo_question.toFixed(0)}
                          %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#968988" }}>
                          Score
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#9B6F72",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {item &&
                            item.clo_coverage &&
                            item.clo_coverage.toFixed(0)}
                          %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <p style={{ marginTop: "10px", fontSize: "12px", color: "#9B6F72" }}>
                          Coverage
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#c7b9ab",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {item?.percentage_60?.toFixed(0)}
                          %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column">
                          <GridItem style={{ fontSize: "12px", color: "#e4b88c" }}>60% {">"}</GridItem>
                          <GridItem style={{ fontSize: "12px", color: "#e4b88c" }}>Attainment</GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#E0C4A4",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {item?.percentage_75?.toFixed(0)}
                          %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column">
                          <GridItem style={{ fontSize: "12px", color: "#E0C4A4" }}>75% {">"}</GridItem>
                          <GridItem style={{ fontSize: "12px", color: "#E0C4A4" }}>Attainment</GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer direction="column" justifyContent="center" alignItems="center" >
                      <GridItem>
                        <Avatar
                          style={{
                            backgroundColor: "#574B56",
                            height: "50px",
                            width: "50px",
                          }}
                        >
                          {item &&
                            item.percentage_80 &&
                            item.percentage_80.toFixed(0)}
                          %
                        </Avatar>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column">
                          <GridItem style={{ fontSize: "12px", color: "#574B56" }}>80% {">"}</GridItem>
                          <GridItem style={{ fontSize: "12px", color: "#574B56" }}>Attainment</GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>

              </CardHeader>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <CourseReportModal
        open={openCourseReportModal}
        setOpen={setOpenCourseReportModal}
        courseID={id}
        course={course && course}
      />
      <CreateSurveyModal
        open={openSurveyModal}
        setOpen={setOpenSurveyModal}
        courseID={id}
        course={course && course}
      />
    </div>
  );
}
