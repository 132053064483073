import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DescriptionIcon from "@material-ui/icons/Description";
import PostAddSharpIcon from "@material-ui/icons/PostAddSharp";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import TodayIcon from "@material-ui/icons/Today";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import { FaUniversity } from "react-icons/fa";
import AddEditGroup from "views/Pages/AccessControl/AddEditGroup";
import AddEditMenuAccessControl from "views/Pages/AccessControl/AddEditMenuAccessControl";
import GroupDetails from "views/Pages/AccessControl/GroupDetails";
import GroupList from "views/Pages/AccessControl/GroupList";
import MenuList from "views/Pages/AccessControl/MenuList";
import ModelsList from "views/Pages/AccessControl/ModelsList";
import AddAccessControl from "views/Pages/AccessControl/AddAccessControl";
import AddEditPerson from "views/Pages/AccessControl/Person/AddEditPerson";
import PersonList from "views/Pages/AccessControl/Person/PersonList";
import AddEditUser from "views/Pages/AccessControl/User/AddEditUser";
import UserList from "views/Pages/AccessControl/User/UserList";
import AddNewStudent from "views/Pages/Assessments/courseAssessments/AddStudent";
import AddNewStudentCordinator from "views/Pages/Assessments/courseAssessments/AddNewStudentCordinator";
import EditStudent from "views/Pages/Assessments/courseAssessments/EditStudentForm";
import ActionPlan from "views/Pages/ActionPlan/ActionPlan";
import ActionPlanDetails from "views/Pages/ActionPlan/ActionPlanDetails";
// import ActionPlanCalendarView from "views/Pages/ActionPlan/ActionPlanCalendarView";
// import ActionPlanListView from "views/Pages/ActionPlan/ActionPlanListView";
import Actions from "views/Pages/ActionPlan/Actions";
//assessment
import ClosAssessmentList from "views/Pages/Assessments/CalculAssessmentsNotes/ClosAssessmentList";
import ClosQuestionList from "views/Pages/Assessments/CalculAssessmentsNotes/ClosQuestionList";
import ResultCalculClosNotes from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosNotes";
import ResultCalculClosQuestionNotes from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosQuestionNotes";
import AssessmentQuestionList from "views/Pages/Assessments/courseAssessments/AssessmentQuestionList";
import AssessmentsDetails from "views/Pages/Assessments/courseAssessments/AssessmentsDetails";
import AssessmentsList from "views/Pages/Assessments/courseAssessments/AssessmentsList";
import AssessmentsListFaculty from "views/Pages/Assessments/courseAssessments/AssessmentsListFaculty";
import AssessmentsQuestionsDetails from "views/Pages/Assessments/courseAssessments/AssessmentsQuestionsDetails";
import AssessmentsQuestionsDetailsByGroup from "views/Pages/Assessments/courseAssessments/AssessmentsQuestionsDetailsByGroup";
import CoursesListAssessment from "views/Pages/Assessments/courseAssessments/CoursesListAssessmentPage";
import GADetails from "views/Pages/Assessments/courseAssessments/GADetails";
import GAListPage from "views/Pages/Assessments/courseAssessments/GAListPage";
import PEODetails from "views/Pages/Assessments/courseAssessments/PEODetails";
import PeoListPage from "views/Pages/Assessments/courseAssessments/PeoListPage";
import PloDetails from "views/Pages/Assessments/courseAssessments/PloDetails";
import PloListPage from "views/Pages/Assessments/courseAssessments/PloListPage";
import InstructorCourseAssessmentGroupListPage from "views/Pages/Assessments/courseAssessments/InstructorCourseAssessmentGroupListPage";
import InstructorCourseAssessmentGroupConfigPage from "views/Pages/Assessments/courseAssessments/InstructorCourseAssessmentGroupConfigPage";
import ProgramAssessmentDetails from "views/Pages/Assessments/programsAssessments/ProgramAssessmentDetails";
import ProgramsAssessmentPage from "views/Pages/Assessments/programsAssessments/ProgramsAssessmentPage";
import AcademicYearList from "views/Pages/Configurations/AcademicYear/AcademicYearList";
import CreateAcademicYear from "views/Pages/Configurations/AcademicYear/CreateAcademicYear";
import AccreditationPrograms from "views/Pages/Configurations/AccreditationPrograms";
import ActualLeaningConfigList from "views/Pages/Configurations/ActualLearningHoursConfig/ActualLeaningConfigList";
import ActualLeaningConfigType from "views/Pages/Configurations/ActualLearningHoursConfig/ActualLearningConfigType";
import CreateActualLeaningConfig from "views/Pages/Configurations/ActualLearningHoursConfig/CreateActualLeaningConfig";
import CreateActualLeaningConfigType from "views/Pages/Configurations/ActualLearningHoursConfig/CreateActualLeaningConfigType";
import AreaDetails from "views/Pages/Configurations/area/AreaDetails";
import AreasPage from "views/Pages/Configurations/area/AreasPage";
import CreateAreaPage from "views/Pages/Configurations/area/CreateAreaPage";
import AssessmentMethodsPage from "views/Pages/Configurations/assessmentMethod/AssessmentMethodsPage";
import CreateAssessmentMethodPage from "views/Pages/Configurations/assessmentMethod/CreateAssessmentMethodPage";
import AddAuthority from "views/Pages/Configurations/AuthorityConfig/AddAuthority";
import AuthorityPage from "views/Pages/Configurations/AuthorityConfig/AuthorityPage";
import Authority from "views/Pages/Configurations/Authority/AuthorityPage";
import AddAuthorityConfig from "views/Pages/Configurations/Authority/AddAuthorityConfig";
import EditAuthority from "views/Pages/Configurations/Authority/EditAuthority";
import BranchesPage from "views/Pages/Configurations/branche/Branches";
import CreateBranchePage from "views/Pages/Configurations/branche/CreateBranche";
import codeLevelPage from "views/Pages/Configurations/CodeLevel/CodeLevelPage";
import CreatedCodeLevel from "views/Pages/Configurations/CodeLevel/CreatedCodeLevel";
import CollegeDetails from "views/Pages/Configurations/college/CollegeDetails";
import CollegesPage from "views/Pages/Configurations/college/CollegesPage";
import CreateCollegePage from "views/Pages/Configurations/college/CreateCollegePage";
import DepartmentsByCollege from "views/Pages/Configurations/college/DepartmentsByCollege";
import CollegeKPIs from "views/Pages/Configurations/college/KPIs";
import CourseConfigurations from "views/Pages/Configurations/CourseConfigurations";
import CreateDepartmentPage from "views/Pages/Configurations/department/CreateDepartmentPage";
import DepartmentDetails from "views/Pages/Configurations/department/DepartmentDetails";
import DepartmentsPage from "views/Pages/Configurations/department/DepartmentsPage";
import CreateFacilitiesRequiredConfig from "views/Pages/Configurations/FacilitiesRequired/CreateFacilitiesRequiredConfig";
import FacilitiesRequiredConfigList from "views/Pages/Configurations/FacilitiesRequired/FacilitiesRequiredConfigList";
import GeneralCriteria from "views/Pages/Configurations/GeneralCriteria";
import CreateHTMLTemplate from "views/Pages/Configurations/HTMLTemplates/CreateHTMLTemplate";
import AnnualProgramReportDocument from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/AnnualProgramReportDocument";
import CourseReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseReportDocument";
import CourseSpecificationReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseSpecificationReportDocument";
import CourseSpecificationReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseSpecificationReportDocumentEditor";
import CourseSyllabusReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseSyllabusReportDocument";
import CourseSyllabusReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseSyllabusReportDocumentEditor";
import GAReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/GAReportDocument";
import PLOReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/PLOReportDocumentEditor";
import KpiReportDocument from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/KpiReportDocument";
import KpiReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/KpiReportDocumentEditor";
import PEOReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/PEOReportDocument";
import PLOReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/PLOReportDocument";
import ProgramSpecificationReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/ProgramSpecificationReportDocument";
import ProgramSpecificationReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/ProgramSpecificationReportDocumentEditor";
import StudyPlanReportDocumentDetails from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/StudyPlanReportDocument";
import StudyPlanReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/StudyPlanReportDocumentEditor";
//HTML templates
import HTMLTemplateEditor from "views/Pages/Configurations/HTMLTemplates/HTMLTemplateEditor";
import HTMLTemplatesList from "views/Pages/Configurations/HTMLTemplates/HTMLTemplatesList.jsx";
import HTMLTemplateViewer from "views/Pages/Configurations/HTMLTemplates/HTMLTemplateViewer";
import CreateLevelPlos from "views/Pages/Configurations/LevelPlos/CreateLevelPlos";
import levelPlosPage from "views/Pages/Configurations/LevelPlos/LevelPlosPage";
import CreateModeOfInstructionConfigPage from "views/Pages/Configurations/modeOfInstructionConfig/CreateModeOfInstructionConfigPage";
import ModeOfInstructionConfigPage from "views/Pages/Configurations/modeOfInstructionConfig/ModeOfInstructionConfigPage";
import Organizations from "views/Pages/Configurations/Organizations";
import OthersConfigurations from "views/Pages/Configurations/OthersConfigurations";
import CreateFieldPage from "views/Pages/Configurations/program/fields/CreateFieldPage";
import FieldsPage from "views/Pages/Configurations/program/fields/FieldsPage";
import GraduationRequirementPage from "views/Pages/Configurations/program/graduationRequirement/GraduationRequirementPage";
import CreateKPIConfig from "views/Pages/Configurations/program/KPI/CreateKPIConfig";
import KPIConfigDetails from "views/Pages/Configurations/program/KPI/KPIConfigDetails";
import KPIConfigList from "views/Pages/Configurations/program/KPI/KPIConfigList";
import UpdateKPIConfig from "views/Pages/Configurations/program/KPI/UpdateKPIConfig";
import ProgramConfigurations from "views/Pages/Configurations/program/ProgramConfigurations";
import CreateSpecializationPage from "views/Pages/Configurations/program/specializations/CreateSpecializationPage";
import SpecializationsPage from "views/Pages/Configurations/program/specializations/SpecializationsPage";
import ProgramAccreditationDetails from "views/Pages/Configurations/ProgramAccreditationDetails";
import ProgramCriteria from "views/Pages/Configurations/ProgramCriteria";
import addPublisherPage from "views/Pages/Configurations/Publisher/addPublisherPage";
import PublisherPage from "views/Pages/Configurations/Publisher/PublisherPage";
import addResearchDirectionPage from "views/Pages/Configurations/ResearchDirection/addResearchDirection";
import ResearchDirectionPage from "views/Pages/Configurations/ResearchDirection/ResearchDirection";
import CreateResourceTypePage from "views/Pages/Configurations/ResourceType/CreateResourceTypePage";
import ResourceTypesPage from "views/Pages/Configurations/ResourceType/ResourceTypesPage";
import SemesterList from "views/Pages/Configurations/Semester/SemesterList";
import AddConfigSkills from "views/Pages/Configurations/Skills/AddConfigSkills";
import SkillsPage from "views/Pages/Configurations/Skills/SkillsPage";
import AddStandardPage from "views/Pages/Configurations/StandardsConfig/addStandardPage";
import StandardPage from "views/Pages/Configurations/StandardsConfig/StandardPage";
import CreateSubAreaPage from "views/Pages/Configurations/subArea/CreateSubAreaPage";
import SubAreasPage from "views/Pages/Configurations/subArea/SubAreasPage";
import CreateSubCodeLevel from "views/Pages/Configurations/SubCodeLevel/CreateSubCodeLevel";
import SubCodeLevelPage from "views/Pages/Configurations/SubCodeLevel/SubCodeLevelPage";
import CreateSubLevelPlos from "views/Pages/Configurations/SubLevelPlos/CreateSubLevelPlos";
import SubLevelPlosPage from "views/Pages/Configurations/SubLevelPlos/SubLevelPlosPage";
import CreateTeachingStrategiesConfig from "views/Pages/Configurations/TeachingStrategiesConfig/CreateTeachingStrategiesConfig";
import TeachingStrategiesConfigList from "views/Pages/Configurations/TeachingStrategiesConfig/TeachingStrategiesConfigList";
import CollegesByUniversity from "views/Pages/Configurations/university/CollegesByUniversity";
import CreateUniversityPage from "views/Pages/Configurations/university/CreateUniversityPage";
import UniversityKPIs from "views/Pages/Configurations/university/KPIs";
//Configuration
import UniversitiesPage from "views/Pages/Configurations/university/UniversitiesPage";
import UniversityDetails from "views/Pages/Configurations/university/UniversityDetails";
import CreateVerificationCredibilityStudentsResultsConfigPage from "views/Pages/Configurations/verificationCredibilityStudentsResultsConfig/CreateVerificationCredibilityStudentsResultsConfig.js";
import VerificationCredibilityStudentsResultsConfigsPage from "views/Pages/Configurations/verificationCredibilityStudentsResultsConfig/VerificationCredibilityStudentsResultsConfigsPage.js";
import CreateYearSemesterPage from "views/Pages/Configurations/yearSemester/CreateYearSemesterPage";
import YearSemestersPage from "views/Pages/Configurations/yearSemester/YearSemestersPage";
import ActivitiesByProgram from "views/Pages/Faculty/Activities/ActivitiesByProgram.jsx";
import Activity from "views/Pages/Faculty/Activities/Activity.jsx";
import KpiStandardType from "views/Pages/Configurations/KpiStandardTypeConfig/KpiStandardType";
import AddKpiStandardConfig from "views/Pages/Configurations/KpiStandardTypeConfig/AddKpiStandardConfig";
import EditKpiStandardConfig from "views/Pages/Configurations/KpiStandardTypeConfig/EditKpiStandardConfig";
import AuthorityLevelConfig from "views/Pages/Configurations/AuthorityLevelConfig/AuthorityLevelConfig";
import AddAuthorityLevelConfig from "views/Pages/Configurations/AuthorityLevelConfig/AddAuthorityLevelConfig";
import EditAuthorityLevelConfig from "views/Pages/Configurations/AuthorityLevelConfig/EditAuthorityLevelConfig";
import ActionApprovalProcess from "views/Pages/Configurations/ActionApprovalProcessConfig/ActionApprovalProcessConfigPage";
import AddActionApprovalProcess from "views/Pages/Configurations/ActionApprovalProcessConfig/AddActionApprovalProcessConfig";
import EditActionApprovalProcess from "views/Pages/Configurations/ActionApprovalProcessConfig/EditActionApprovalProcessConfig";
import StateNameConfigPage from "views/Pages/Configurations/StateNames/StateNamesConfigPage";
import AddStateNameConfigPage from "views/Pages/Configurations/StateNames/AddStateNameConfigPage";
import EditStateNameConfigPage from "views/Pages/Configurations/StateNames/EditStateNameConfigPage";
import StepConfigPage from "views/Pages/Configurations/StepConfig/StepConfigPage";
import AddStepConfigPage from "views/Pages/Configurations/StepConfig/AddStepConfigPage";
import EditStepConfigPage from "views/Pages/Configurations/StepConfig/EditStepConfigPage";

// faculty
import AllActivities from "views/Pages/Faculty/Activities/AllActivities.jsx";
import AllCommunityServicesActivities from "views/Pages/Faculty/Activities/AllCommunityServicesActivities";
import AllProfessionalDevelopmentActivities from "views/Pages/Faculty/Activities/AllProfessionalDevelopmentActivities";
import AllResearchAndInnovationActivities from "views/Pages/Faculty/Activities/AllResearchAndInnovationActivities";
import AllTeachingLoadActivities from "views/Pages/Faculty/Activities/AllTeachingLoadActivities";
import CreateActivity from "views/Pages/Faculty/Activities/CreateActivity.jsx";
import CommunityServices from "views/Pages/Faculty/CvTech/Activities/CommunityServices";
import CommunityServicesDetails from "views/Pages/Faculty/CvTech/Activities/CommunityServicesDetails";
import CreateCommunityServicesActivity from "views/Pages/Faculty/CvTech/Activities/CreateCommunityServicesActivity";
import CreateProfessionalDevelopmentActivity from "views/Pages/Faculty/CvTech/Activities/CreateProfessionalDevelopmentActivity";
import CreateResearchAndInnovationActivity from "views/Pages/Faculty/CvTech/Activities/CreateResearchAndInnovationActivity";
import CreateTeachingLoadActivity from "views/Pages/Faculty/CvTech/Activities/CreateTeachingLoadActivity";
import EditCommunityServiceActivity from "views/Pages/Faculty/CvTech/Activities/EditCommunityServices";
import EditProfessionalDevelopmentActivity from "views/Pages/Faculty/CvTech/Activities/EditProfessionalDevelopmentActivity";
import EditResearchAndInnovationActivity from "views/Pages/Faculty/CvTech/Activities/EditResearchAndInnovationActivity";
import ProfessionalDevelopment from "views/Pages/Faculty/CvTech/Activities/ProfessionalDevelopment";
import ProfessionalDevelopmentDetails from "views/Pages/Faculty/CvTech/Activities/ProfessionalDevelopmentDetails";
import ResearchActivityTypeChoice from "views/Pages/Faculty/CvTech/Activities/ResearchActivityTypeChoice";
import ResearchAndInnovation from "views/Pages/Faculty/CvTech/Activities/ResearchAndInnovation";
import ResearchAndInnovationDetails from "views/Pages/Faculty/CvTech/Activities/ResearchAndInnovationDetails";

import SurveyTemplatesNavigationPage from "./views/Pages/Surveys/SurveyTemplatesNavigationPage";
import SurveyTemplatesTypes from "./views/Pages/Surveys/SurveyTemplatesTypes";
import SurveyTemplateTypeAddEdit from "./views/Pages/Surveys/SurveyTemplateTypeAddEdit";

import CreateResearchAndInnovationActivityOutputs from "views/Pages/Faculty/CvTech/Activities/CreateResearchAndInnovationOutputs.jsx";
import EditResearchAndInnovationActivityOutputs from "views/Pages/Faculty/CvTech/Activities/EditResearchAndInnovationActivityOutputs";
import ResearchAndInnovationDetailsOutputs from "views/Pages/Faculty/CvTech/Activities/ResearchAndInnovationDetailsOutputs";
import ResearchAndInnovationOutputs from "views/Pages/Faculty/CvTech/Activities/ResearchAndInnovationOutputs";
import TeachingLoad from "views/Pages/Faculty/CvTech/Activities/TeachingLoad";
import TeachingLoadDetails from "views/Pages/Faculty/CvTech/Activities/TeachingLoadDetails";
import TeachingStaff from "views/Pages/Faculty/CvTech/TeachingStaff";
import TeachingStaffDetails from "views/Pages/Faculty/CvTech/TeachingStaffDetails";
import AnnualProgramImprovementDetails from "views/Pages/Improvement/AnnualProgramImprovement/AnnualProgramImprovementDetails";

import AssessmentMethodDetailsInstructor from "views/Pages/Assessments/courseAssessments/AssessmentMethodDetailsInstructor";
import EditGroupsInstructor from "views/Pages/Assessments/courseAssessments/EditGroupsInstructor";
import AddNewStudentInstructor from "views/Pages/Assessments/courseAssessments/AddNewStudentInstructor";
import EditStudentInstructorForm from "views/Pages/Assessments/courseAssessments/EditStudentInstructorForm";
//import OthersConfigurations from "views/Pages/Configurations/OthersConfigurations";
//import SkillsPage from "views/Pages/Configurations/Skills/SkillsPage";
//import AddConfigSkills from "views/Pages/Configurations/Skills/AddConfigSkills";
//import AuthorityPage from "views/Pages/Configurations/AuthorityConfig/AuthorityPage";
//import AddAuthority from "views/Pages/Configurations/AuthorityConfig/AddAuthority";
//import StandardPage from "views/Pages/Configurations/StandardsConfig/StandardPage";
//import AddStandardPage from "views/Pages/Configurations/StandardsConfig/addStandardPage";
//import PublisherPage from "views/Pages/Configurations/Publisher/PublisherPage";
//import addPublisherPage from "views/Pages/Configurations/Publisher/addPublisherPage";
//import addResearchDirectionPage from "views/Pages/Configurations/ResearchDirection/addResearchDirection";
//import ResearchDirectionPage from "views/Pages/Configurations/ResearchDirection/ResearchDirection";
import AssessmentMethodInstructors from "views/Pages/Assessments/courseAssessments/AssessmentMethodInstructors";

import AddIndexResearch from "views/Pages/Configurations/IndexResearch/addIndexResearch";
import IndexResearchPage from "views/Pages/Configurations/IndexResearch/indexResearchPage";
import AddInternationalResearchConfig from "views/Pages/Configurations/InternationalResearchConfig/addInternationalResearchConfig";
import InternationalResearchConfigPage from "views/Pages/Configurations/InternationalResearchConfig/InternationalResearchConfigPage";
import AddRolesConfigPage from "views/Pages/Configurations/RolesConfig/addRolesConfigPage";
import RolesConfigPage from "views/Pages/Configurations/RolesConfig/RolesConfigPage";
import AddTypesConfigPage from "views/Pages/Configurations/TypesConfig/addTypesConfigPage";
import TypesConfigPage from "views/Pages/Configurations/TypesConfig/TypesConfigPage";
import AddUtilizedResearchLabs from "views/Pages/Configurations/UtilizedResearchLabs/AddUtilizedResearchLabs";
import UtilizedResearchLabsPage from "views/Pages/Configurations/UtilizedResearchLabs/UtilizedResearchLabsPage";

//AnnualProgramImprovementList
import AnnualProgramImprovementList from "views/Pages/Improvement/AnnualProgramImprovement/AnnualProgramImprovementList";
//Improvement
import CourseImprovementDetails from "views/Pages/Improvement/CourseImprovement/CourseImprovementDetails";
import CourseImprovementList from "views/Pages/Improvement/CourseImprovement/CourseImprovementList";
import CreateCourseImprovement from "views/Pages/Improvement/CourseImprovement/CreateCourseImprovement";
import GAImprovementDetails from "views/Pages/Improvement/GAImprovement/GAImprovementDetails";
import GAImprovementList from "views/Pages/Improvement/GAImprovement/GAImprovementList";
import KPIsImprovementDetails from "views/Pages/Improvement/KPIsImprovement/KPIsImprovementDetails";
import KPIsImprovementList from "views/Pages/Improvement/KPIsImprovement/KPIsImprovementList";
import PloImprovementDetails from "views/Pages/Improvement/PloImprovement/PloImprovementDetails";
import PloImprovementList from "views/Pages/Improvement/PloImprovement/PloImprovementList";
import PEOImprovementList from "views/Pages/Improvement/PeoImprovement/PEOImprovementList";
import PEOImprovementDetails from "views/Pages/Improvement/PeoImprovement/PEOImprovementDetails";
import AddEditMailTemplate from "views/Pages/MailServer/AddEditMailTemplate";
import AddEditOutGoingMailServer from "views/Pages/MailServer/AddEditOutGoingMailServer";
import MailList from "views/Pages/MailServer/MailList";
import mailTemplateList from "views/Pages/MailServer/mailTemplateList";
import OutGoingMailServerList from "views/Pages/MailServer/OutGoingMailServerList";
import SendNewMail from "views/Pages/MailServer/SendNewMail";
import AcademicYear from "views/Pages/Planning/AcademicCalender/AcademicYear";
import AcademicYears from "views/Pages/Planning/AcademicCalender/AcademicYears";
import Semester from "views/Pages/Planning/AcademicCalender/Semester.jsx";
import CourseAssessment from "views/Pages/Planning/CourseAssessment/CourseAssessment";
import GA from "views/Pages/Planning/ProgramAssessment/GA";
import KPI from "views/Pages/Planning/ProgramAssessment/KPI";
import PLO from "views/Pages/Planning/ProgramAssessment/PLO";
//planning
import ProgramAssessment from "views/Pages/Planning/ProgramAssessment/ProgramAssessment.jsx";
import SurveyPlanning from "views/Pages/Planning/ProgramAssessment/Survey";
import ActivitiesAssessmentMethodsList from "views/Pages/Specifications/course/Activities-Assessment Methods/Activities/ActivitiesAssessmentMethodsList";
import CreateActivitiesAssessmentMethods from "views/Pages/Specifications/course/Activities-Assessment Methods/Activities/CreateActivitiesAssessmentMethods";
import ActualLearningHourCard from "views/Pages/Specifications/course/ActualLearningHour/ActualLearningHourCard";
import ActualLearningHoursTreeList from "views/Pages/Specifications/course/ActualLearningHour/ActualLearningHourTreeList";
import ActualLearningHourViews from "views/Pages/Specifications/course/ActualLearningHour/ActualLearningHourViews";
import CreateActualLearningHour from "views/Pages/Specifications/course/ActualLearningHour/CreateActualLearningHour";
import AlignmentOfCLOWithTMAndAMList from "views/Pages/Specifications/course/AlignmentOfCLOWithTMAndAM/AlignmentOfCLOWithTMAndAMList";
import CreateAlignmentOfCLOWithTMAndAM from "views/Pages/Specifications/course/AlignmentOfCLOWithTMAndAM/CreateAlignmentOfCLOWithTMAndAM";
import AssessmentTasksForStudentList from "views/Pages/Specifications/course/Assessment tasks for student/AssessmentTasksForStudentList";
import CreateAssessmentTasksForStudent from "views/Pages/Specifications/course/Assessment tasks for student/CreateAssessmentTasksForStudent";
import AssessmentResponsibilitiesList from "views/Pages/Specifications/course/AssessmentResponsibilities/AssessmentResponsibilitiesList";
import CreateAssessmentResponsibilities from "views/Pages/Specifications/course/AssessmentResponsibilities/CreateAssessmentResponsibilities";
import CLOs from "views/Pages/Specifications/course/clos/CLOs";
import CourseCloDetails from "views/Pages/Specifications/course/clos/CourseCloDetails";
import CreateCourseClo from "views/Pages/Specifications/course/clos/CreateCourseClo";
import UpdateClo from "views/Pages/Specifications/course/clos/UpdateClo";
import CourseContactList from "views/Pages/Specifications/course/CourseContact/CourseContactList";
import CreateCourseContact from "views/Pages/Specifications/course/CourseContact/CreateCourseContact";
import CourseContentCard from "views/Pages/Specifications/course/CourseContent/CourseContentCard";
import CourseContentTreeList from "views/Pages/Specifications/course/CourseContent/CourseContentTreeList";
import CourseContentViews from "views/Pages/Specifications/course/CourseContent/CourseContentViews";
import CreateCourseContent from "views/Pages/Specifications/course/CourseContent/CreateCourseContent";
import CourseOutlineList from "views/Pages/Specifications/course/CourseOutline/CourseOutlineList";
import CreateCourseOutline from "views/Pages/Specifications/course/CourseOutline/CreateCourseOutline";
import CourseQualityEvaluationList from "views/Pages/Specifications/course/CourseQualityEvaluation/CourseQualityEvaluationList";
import CreateCourseQualityEvaluation from "views/Pages/Specifications/course/CourseQualityEvaluation/CreateCourseQualityEvaluation";
import CourseSpecDetails from "views/Pages/Specifications/course/CourseSpecDetails";
import CourseSpecificationList from "views/Pages/Specifications/course/CourseSpecificationList";
import CourseSpecUpdate from "views/Pages/Specifications/course/CourseSpecUpdate";
import CourseSyllabus from "views/Pages/Specifications/course/CourseSyllabus/CourseSyllabus.jsx";
import CreateCourseAssessmentMethod from "views/Pages/Specifications/course/CreateCourseAssessmentMethod";
//Specification / Course
import CreateCourseSpec from "views/Pages/Specifications/course/CreateCourseSpec";
import CreateDatesAndTimesAllocation from "views/Pages/Specifications/course/DatesAndTimesAllocation/CreateDatesAndTimesAllocation";
import DatesAndTimesAllocationList from "views/Pages/Specifications/course/DatesAndTimesAllocation/DatesAndTimesAllocationList";
import CreateFacilitiesRequired from "views/Pages/Specifications/course/FacilitiesRequired/CreateFacilitiesRequired";
import FacilitiesRequiredList from "views/Pages/Specifications/course/FacilitiesRequired/FacilitiesRequiredList";
import CreateFieldExperienceLocations from "views/Pages/Specifications/course/FieldExperienceLocationsRequirements/CreateFieldExperienceLocations";
import FieldExperienceLocationsList from "views/Pages/Specifications/course/FieldExperienceLocationsRequirements/FieldExperienceLocationsList";
import CreateLearningResource from "views/Pages/Specifications/course/LearningResource/CreateLearningResource";
import LearningResourceList from "views/Pages/Specifications/course/LearningResource/LearningResourceList";
import CreateModeOfInstruction from "views/Pages/Specifications/course/Mode of Instruction/CreateModeOfInstruction";
import ModeOfInstructionList from "views/Pages/Specifications/course/Mode of Instruction/ModeOfInstructionList";
import CreateRangePage from "views/Pages/Specifications/course/range/CreateRangePage";
import RangeDetails from "views/Pages/Specifications/course/range/RangeDetails";
import RangesPage from "views/Pages/Specifications/course/range/RangesPage";
import SpecificationAprovalDataList from "views/Pages/Specifications/course/SpecificationAprovalData/SpecificationAprovalDataList";
import UpdateSpecificationAprovalData from "views/Pages/Specifications/course/SpecificationAprovalData/UpdateSpecificationAprovalData";
import CreateTeachingStrategies from "views/Pages/Specifications/course/TeachingStrategies/CreateTeachingStrategies";
import TeachingStrategiesList from "views/Pages/Specifications/course/TeachingStrategies/TeachingStrategiesList";
import CreateVerificationCredibility from "views/Pages/Specifications/course/verificationCredibility/CreateVerificationCredibility";
import VerificationCredibilityList from "views/Pages/Specifications/course/verificationCredibility/VerificationCredibilityList";
import AccreditationDates from "views/Pages/Specifications/program/AccreditationDates";
import CoursesByProgram from "views/Pages/Specifications/program/CoursesByProgram";
import CoursesFromLMS from "views/Pages/Specifications/program/CoursesFromLMS";
import CreateProgramAdmissionRequirement from "views/Pages/Specifications/program/CreateProgramAdmissionRequirement";
import CreateProgramForm from "views/Pages/Specifications/program/CreateProgramFormPage";
import CreateProgramGraduationRequirement from "views/Pages/Specifications/program/CreateProgramGraduationRequirement";
// import AssessmentModes from "./views/Pages/Configurations/course/assessmentMode/AssessmentModes";
// import CreateAssessmentMode from "./views/Pages/Configurations/course/assessmentMode/CreateAssessmentMode";
// const isAdministrator = true;
// const user  = localStorage.getItem("user");
// const status = user.status;
//   console.log('status from admin js', status)
//   if(status === "Administrator"){
//   isAdministrator = false;
// }
import ExitPointsPage from "views/Pages/Specifications/program/ExitPointsPage";
import GraduateAttributesPage from "views/Pages/Specifications/program/GraduateAttributes";
import GraduateAttributesDetails from "views/Pages/Specifications/program/GraduateAttributesDetails";
import JobsPage from "views/Pages/Specifications/program/JobsPage";
import KPISpecList from "views/Pages/Specifications/program/KPIs";
import MajorTracksPage from "views/Pages/Specifications/program/MajorTracksPage";
import SurveyTemplatesSpec from "views/Pages/Specifications/program/SurveyTemplates";
import PEOIndicatorsByPEO from "views/Pages/Specifications/program/PEOIndicatorsByPEO";
import PEOsPage from "views/Pages/Specifications/program/PEOs";
import PLOIndicatorsByPLO from "views/Pages/Specifications/program/PLOIndicatorsByPLO";
import PLOIndicator from "views/Pages/Specifications/program/PLOIndicator";
import PLOsPage from "views/Pages/Specifications/program/PLOs";
import ProgramAdmissionRequirements from "views/Pages/Specifications/program/ProgramAdmissionRequirements";
import ProgramDetails from "views/Pages/Specifications/program/ProgramDetails";
//Specification / Program
import programsArchive from "views/Pages/Specifications/program/programsArchive";
import ProgramsPage from "views/Pages/Specifications/program/ProgramsPage.js";
import UpdateProgramForm from "views/Pages/Specifications/program/UpdateProgramFormPage";
import AddWeightToSurveyQuestions from "views/Pages/Surveys/AddWeightToSurveyQuestions";
import CreateSurveyTemplate from "views/Pages/Surveys/CreateSurveyTemplate";
import EditResponsesWeights from "views/Pages/Surveys/EditResponsesWeights";
import StudentSurveyCampaignDetails from "views/Pages/Surveys/StudentSurvey/StudentSurveyCampaignDetails";
import StudentSurveyCampaignList from "views/Pages/Surveys/StudentSurvey/StudentSurveyCampaignList";
//Survey
import SurveyForm from "views/Pages/Surveys/StudentSurvey/Survey";
import SurveyAnalysis from "views/Pages/Surveys/StudentSurvey/SurveyAnalysis";
import SurveyTemplateEditor from "views/Pages/Surveys/SurveyTemplateEditor";
import SurveyTemplateEditor2 from "views/Pages/Surveys/SurveyTemplateEditor2";
import SurveyTemplates from "views/Pages/Surveys/SurveyTemplates";
import SurveyTemplateConfList from "../src/views/Pages/Configurations/HTMLTemplates/SurveyTemplateConfList.jsx";

//user
import AddEvidenceTypeConfigPage from "views/Pages/Configurations/EvidenceTypeConfig/AddEvidenceTypeConfigPage";
import EditEvidenceTypesConfig from "views/Pages/Configurations/EvidenceTypeConfig/EditEvidenceTypesConfig";
import CreateGraduateAttributeConfigPage from "views/Pages/Configurations/program/graduateAttributeConfig/CreateGAConfigPage";
import GraduateAttributeConfigsPage from "views/Pages/Configurations/program/graduateAttributeConfig/GAConfig";
import AddReportStatesConfigPage from "views/Pages/Configurations/ReportStatesConfig/addreportStatesConfigPage";
import ReportStatesConfigPage from "views/Pages/Configurations/ReportStatesConfig/ReportStatesConfigPage";
import LoginPage from "views/Pages/templateComponent/LoginPage";
import UserProfile from "views/Pages/templateComponent/UserProfile";
import GroupModelsReport from "./views/Pages/AccessControl/groupModelsReport";
import ReportStates from "./views/Pages/AccessControl/groupModelsReportControl/ReportStates";
import ReportStatesActions from "./views/Pages/AccessControl/groupModelsReportControl/ReportStatesActions";
import AssessementDetails from "./views/Pages/Configurations/assessmentMethod/AssessementDetails";
import AssessmentModes from "./views/Pages/Configurations/course/assessmentMode/AssessmentModes";
import CreateAssessmentMode from "./views/Pages/Configurations/course/assessmentMode/CreateAssessmentMode";
import CreateLevel from "./views/Pages/Configurations/course/levels/CreateLevel";
import Levels from "./views/Pages/Configurations/course/levels/Levels";
import CreateType from "./views/Pages/Configurations/course/types/CreateType";
import Types from "./views/Pages/Configurations/course/types/Types";
import EvidenceTypeConfigPage from "./views/Pages/Configurations/EvidenceTypeConfig/EvidenceTypeConfigPage";
import Categories from "./views/Pages/Configurations/program/categories/Categories";
import CreateCategory from "./views/Pages/Configurations/program/categories/CreateCategory";
import CreateTotalLevel from "./views/Pages/Configurations/program/totalLevels/CreateTotalLevel";
import TotalLevels from "./views/Pages/Configurations/program/totalLevels/TotalLevels";
import PEO from "./views/Pages/Planning/ProgramAssessment/PEO";
//import KpiReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/KpiReportDocumentEditor";

import ActionsByUser from "views/Pages/ActionPlan/ActionsByUser";
import ClosAssessmentListFaculty from "views/Pages/Assessments/CalculAssessmentsNotes/ClosAssessmentListFaculty";
import ClosQuestionListFaculty from "views/Pages/Assessments/CalculAssessmentsNotes/ClosQuestionListFaculty";
import ResultCalculClosNotesFaculty from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosNotesFaculty";
import ResultCalculClosQuestionNotesFaculty from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosQuestionNotesFaculty";
import AssessmentQuestionListFaculty from "views/Pages/Assessments/courseAssessments/AssessmentQuestionListFaculty";
import AssessmentQuestionListCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentQuestionListCoordinator";
import AssessmentsDetailsCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentsDetailsCoordinator";
import AssessmentsListCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentsListCoordinator";
import AssessmentsQuestionsDetailsByGroupsCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentsQuestionsDetailsByGroupCoordinator";
import AssessmentsQuestionsDetailsCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentsQuestionsDetailsCoordinator";
import CoordinatorCoursesListAssessmentPage from "views/Pages/Assessments/courseAssessments/CoordinatorCoursesListAssessmentPage";
import StaffSurveyCampaignList from "views/Pages/Surveys/StaffSurveyCampaignList";
import ClosAssessmentListCoordinator from "views/Pages/Assessments/CalculAssessmentsNotes/ClosAssessmentListCoordinator";
import ClosQuestionListCoordinator from "views/Pages/Assessments/CalculAssessmentsNotes/ClosQuestionListCoordinator";
import ResultCalculClosNotesCoordinator from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosNotesCoordinator";
import ResultCalculClosQuestionNotesCoordinator from "views/Pages/Assessments/CalculAssessmentsNotes/ResultCalculClosQuestionNotesCoordinator";
import CoordinatorSurveyCampaignList from "views/Pages/Surveys/CourseCoordinatorSurveyCampaignList";
import CourseSpecificationListCoordinator from "views/Pages/Specifications/course/CourseSpecificationListCoordinator";
import AssessmentMethodInstructorsCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentMethodInstructorsCoordinator";
import AssessmentMethodGroups from "views/Pages/Assessments/courseAssessments/AssessmentMethodGroups";
import AssessmentMethodGroupsCoordinator from "views/Pages/Assessments/courseAssessments/AssessmentMethodGroupsCoordinator";
import Dashboard from "views/Dashboard/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentMethodGroupsDetails from "views/Pages/Assessments/courseAssessments/AssessmentMethodGroupsDetails";
import CourseReportDocumentEditor from "views/Pages/Configurations/HTMLTemplates/HTMLDocuments/CourseReportDocumentEditor";
import CourseSepecificationDetailsCoordinator from "views/Pages/Specifications/course/CourseSepecificationDetailsCoordinator";
import AssessmentMethodGroupsCoordinatorGroup from "views/Pages/Assessments/AssessmentMethodGroupsCoordinatorGroup";
import AssessmentMethodInstructorGroupDetails from "views/Pages/Assessments/courseAssessments/AssessmentMethodInstructorGroupDetails";
import ActionTypePage from "views/Pages/Configurations/ActionTypeConfig/ActionTypePage";
import ApprovalLevelsPage from "views/Pages/Configurations/ApprovalLevelConfig/ApprovalLevelsPage";
import AddActionTypeConfig from "views/Pages/Configurations/ActionTypeConfig/AddActionTypeConfig.jsx";
import CreateApprovalLevel from "views/Pages/Configurations/ApprovalLevelConfig/CreateApprovalLevel";
import MaileTempList from "views/Pages/Surveys/templatesMailSurvey/MaileTempList";
import SurveyMails from "views/Pages/Surveys/templatesMailSurvey/SurveyMails";
import SuperSetDashboard from "views/Dashboard/SuperSetDashboard.js";
import DNDTestPage from "./Test";
import SurveyTemplatesMapping from "./views/Pages/Surveys/SurveyTemplatesMapping";
import AdminPage from "./views/Pages/Admin/AdminPage";
import PullLMSData from "./views/Pages/Admin/PullLMSData";
import PushLMSData from "./views/Pages/Admin/PushLMSData";
import MapLMSData from "./views/Pages/Admin/MapLMSData";
import NCAAAReports from "./views/Pages/Improvement/NCAAA/NCAAAReports";
import CTIReports from "./views/Pages/Improvement/CTI/CTIReports";
import ABETReports from "./views/Pages/Improvement/ABET/ABETReports";

var dashRoutes = [
  // {
  //   path: "/",
  //   name: "Navigation",
  //   component: NavigationScreenPage,
  //   invisible: true,
  //   layout: "/auth",
  // },
  {
    path: "/login-page",
    name: "Login Page",
    component: LoginPage,
    invisible: true,
    layout: "/auth",
  },
  // {
  //   path: "/DashboardSuperSet",
  //   name: "DashboardSuperSet",
  //   component: SuperSetDashboard,
  //   invisible: false,
  //   layout: "/citrine",
  // },
  {
    path: "/Specification",
    name: "Specification",
    rtlName: "المواصفات",
    icon: DescriptionIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "specificationsCollapse",
    views: [
      {
        path: "/admin/LMSData/pull/",
        name: "PullLMSData",
        component: PullLMSData,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/admin/LMSData/push/",
        name: "PushLMSData",
        component: PushLMSData,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/admin/LMSData/map/",
        name: "MapLMSData",
        component: MapLMSData,
        invisible: true,
        layout: "/citrine",
      },
      // {
      //   path: "/test",
      //   name: "test",
      //   component: DNDTestPage,
      //   layout: "/citrine",
      // },
      {
        path: "/programSpec",
        name: "Programs",
        rtlName: "البرامج",
        mini: "P",
        rtlMini: "ب",
        component: ProgramsPage,
        layout: "/citrine",
      },
      {
        path: "/CourseSpecificationList",
        name: "Courses",
        rtlName: "المقررات",
        mini: "C",
        rtlMini: "م",
        component: CourseSpecificationList,
        layout: "/citrine",
      },
      {
        path: "/programSpec/:programId/courses",
        name: "CoursesByProgram",
        component: CoursesByProgram,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courses/importFromLMS",
        name: "CoursesFromLMS",
        component: CoursesFromLMS,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:programId/accreditationDates",
        component: AccreditationDates,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/PEOs",
        name: "PEOsPage",
        component: PEOsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/PEOs/:PEOId/PEOIndicators",
        name: "PEOIndicatorsByPEO",
        component: PEOIndicatorsByPEO,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/PLOs",
        name: "PLOsPage",
        component: PLOsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/PLOs/:PLOId/PLOIndicators",
        name: "PLOIndicatorsByPLO",
        component: PLOIndicatorsByPLO,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/PLOs/:PLOId/PLOIndicators/:PLOIndId",
        component: PLOIndicator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/GraduateAttributes",
        name: "GraduateAttributesPage",
        component: GraduateAttributesPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId/GAs/:GAId/GAIndicators",
        name: "GAIndicators",
        component: GraduateAttributesDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/programSpec/:programId/KPIs",
        component: KPISpecList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:programId",
        name: "ProgramDetails",
        component: ProgramDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/addProgramSpec",
        name: "addProgram",
        component: CreateProgramForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/updateProgramSpec/:id",
        name: "updateProgram",
        component: UpdateProgramForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId",
        component: ProgramSpecificationReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId/editor",
        component: ProgramSpecificationReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId",
        component: StudyPlanReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId/editor",
        component: StudyPlanReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:progReportDocumentId",
        component: CourseSpecificationReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:courseSpecReportDocumentId/editor",
        component: CourseSpecificationReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:progReportDocumentId",
        component: CourseSyllabusReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:courseSyllabusReportDocumentId/editor",
        component: CourseSyllabusReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      // Specifications Course
      {
        path: "/createCourseClo/course/:id/clo/:idClo",
        name: "Create course Clo",
        component: CreateCourseClo,
        layout: "/citrine",
        invisible: true,
      },
      /*{
        path: "/editDefaultQuestion/course/:id/clo/:idClo",
        name: "Create course Clo",
        component: EditDefaultQuestion,
        layout: "/citrine",
        invisible: true,
      },*/
      {
        path: "/courseCloDetails/:id",
        name: "Course Clo details",
        component: CourseCloDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/addCourseSpec",
        name: "Create Course",
        component: CreateCourseSpec,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id",
        name: "Course details",
        component: CourseSpecDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecUpdate/:id",
        name: "Course Update",
        component: CourseSpecUpdate,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createCourseAssessmentMethod",
        name: "Create course assessment method",
        component: CreateCourseAssessmentMethod,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/CourseSpecClosList/:id",
      //   component: CourseSpecClosList,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      {
        path: "/courses/:id/clos",
        component: CLOs,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/SpecificationAprovalDataList",
        name: "SpecificationAprovalDataList",
        component: SpecificationAprovalDataList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateModeOfInstruction/course/:id/modeOfInstruction/:idMOI",
        name: "CreateModeOfInstruction",
        component: CreateModeOfInstruction,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ModeOfInstructionList/:id",
        name: "ModeOfInstructionList",
        component: ModeOfInstructionList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources/create",
        name: "CreateLearningResource",
        component: CreateLearningResource,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources/:idLR/edit",
        name: "EditLearningResource",
        component: CreateLearningResource,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources",
        name: "LearningResourceList",
        component: LearningResourceList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds/create",
        name: "CreateFacilitiesRequired",
        component: CreateFacilitiesRequired,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds/:idFR/edit",
        name: "EditFacilitiesRequired",
        component: CreateFacilitiesRequired,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds",
        name: "FacilitiesRequiredList",
        component: FacilitiesRequiredList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours/create",
        name: "CreateActualLearningHour",
        component: CreateActualLearningHour,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours/:idHour/edit",
        name: "EditActualLearningHour",
        component: CreateActualLearningHour,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours",
        name: "ActualLearningHourCard",
        component: ActualLearningHourCard,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ActualLearningHoursViews",
        name: "ActualLearningHourViews",
        component: ActualLearningHourViews,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ActualLearningHoursTreeList",
        name: "ActualLearningHoursTreeList",
        component: ActualLearningHoursTreeList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContacts/:idCC/edit",
        name: "EditCourseContact",
        component: CreateCourseContact,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContacts/create",
        name: "CreateCourseContact",
        component: CreateCourseContact,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContacts",
        name: "CourseContactList",
        component: CourseContactList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines/:idCC/edit",
        name: "EditCourseOutline",
        component: CreateCourseOutline,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines/create",
        name: "CreateCourseOutline",
        component: CreateCourseOutline,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines",
        name: "CourseOutlineList",
        component: CourseOutlineList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/courseSyllabus",
        component: CourseSyllabus,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents/:idCC/edit",
        name: "EditCourseContent",
        component: CreateCourseContent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents/create",
        name: "CreateCourseContent",
        component: CreateCourseContent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents",
        name: "CourseContentCard",
        component: CourseContentCard,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContentViews",
        name: "CourseContentViews",
        component: CourseContentViews,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContentTreeList",
        name: "CourseContentTreeList",
        component: CourseContentTreeList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AssessmentTasksForStudents/create",
        name: "CreateAssessmentTasksForStudent",
        component: CreateAssessmentTasksForStudent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentTasksForStudents/:idATFS/edit",
        name: "CreateAssessmentTasksForStudent",
        component: CreateAssessmentTasksForStudent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentTasksForStudents",
        name: "AssessmentTasksForStudentList",
        component: AssessmentTasksForStudentList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/create",
        name: "CreateAlignmentOfCLOWithTMAndAM",
        component: CreateAlignmentOfCLOWithTMAndAM,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/:idACWTMAM/edit",
        name: "EditAlignmentOfCLOWithTMAndAM",
        component: CreateAlignmentOfCLOWithTMAndAM,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs",
        name: "AlignmentOfCLOWithTMAndAMList",
        component: AlignmentOfCLOWithTMAndAMList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations/create",
        name: "CreateCourseQualityEvaluation",
        component: CreateCourseQualityEvaluation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations/:idCQE/edit",
        name: "EditCourseQualityEvaluation",
        component: CreateCourseQualityEvaluation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations",
        name: "CourseQualityEvaluationList",
        component: CourseQualityEvaluationList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies/create",
        name: "CreateTeachingStrategies",
        component: CreateTeachingStrategies,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies/:idTS/edit",
        name: "EditTeachingStrategies",
        component: CreateTeachingStrategies,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies",
        name: "TeachingStrategiesList",
        component: TeachingStrategiesList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods/create",
        name: "CreateActivitiesAssessmentMethods",
        component: CreateActivitiesAssessmentMethods,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods/:idAAM/edit",
        name: "CreateActivitiesAssessmentMethods",
        component: CreateActivitiesAssessmentMethods,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods",
        name: "ActivitiesAssessmentMethodsList",
        component: ActivitiesAssessmentMethodsList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/VerificationCredibilities/create",
        name: "CreateVerificationCredibility",
        component: CreateVerificationCredibility,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/VerificationCredibilities/:idVerif/edit",
        name: "EditVerificationCredibility",
        component: CreateVerificationCredibility,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/VerificationCredibilities",
        name: "VerificationCredibilityList",
        component: VerificationCredibilityList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:IdC/ranges/create",
        name: " Create Range",
        component: CreateRangePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:IdC/ranges/:IdR/edit",
        name: " Edit Range",
        component: CreateRangePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/ranges/:id",
        name: "Range details",
        component: RangeDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ranges",
        name: " Ranges list",
        component: RangesPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/DatesAndTimesAllocations/create",
        name: "CreateDatesAndTimesAllocation",
        component: CreateDatesAndTimesAllocation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/DatesAndTimesAllocations/:idDTA/edit",
        name: "EditDatesAndTimesAllocation",
        component: CreateDatesAndTimesAllocation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/DatesAndTimesAllocations",
        name: "DatesAndTimesAllocationList",
        component: DatesAndTimesAllocationList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/FieldExperienceLocations/create",
        name: "CreateFieldExperienceLocations",
        component: CreateFieldExperienceLocations,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FieldExperienceLocations/:idFEL/edit",
        name: "EditFieldExperienceLocations",
        component: CreateFieldExperienceLocations,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FieldExperienceLocations",
        name: "FieldExperienceLocationsList",
        component: FieldExperienceLocationsList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AssessmentResponsibilities/create",
        name: "CreateAssessmentResponsibilities",
        component: CreateAssessmentResponsibilities,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentResponsibilities/:idAR/edit",
        name: "EditAssessmentResponsibilities",
        component: CreateAssessmentResponsibilities,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentResponsibilities",
        name: "AssessmentResponsibilitiesList",
        component: AssessmentResponsibilitiesList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/UpdateSpecificationAprovalData/:idCourse/:id",
        name: "UpdateSpecificationAprovalData",
        component: UpdateSpecificationAprovalData,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/UpdateClo/:id",
        name: "UpdateClo",
        component: UpdateClo,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/SpecificationAprovalDataList",
        name: "SpecificationAprovalDataList",
        component: SpecificationAprovalDataList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations",
        name: "Organizations",
        rtlName: "المنظمة",
        mini: "O",
        rtlMini: "م",
        component: Organizations,
        layout: "/citrine",
      },
      {
        path: "/organizations/universities",
        name: "Universities",
        component: UniversitiesPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/universities/create/:id",
        name: "Create University",
        component: CreateUniversityPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/universities/:id",
        name: "University Details",
        component: UniversityDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/universities/:id/KPIs",
        component: UniversityKPIs,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/universities/:id/colleges",
        component: CollegesByUniversity,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/colleges",
        name: "Colleges",
        component: CollegesPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/colleges/create/:id",
        name: "Create College",
        component: CreateCollegePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/colleges/:id/departments",
        component: DepartmentsByCollege,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/colleges/:id/KPIs",
        component: CollegeKPIs,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/colleges/:id",
        name: "College Details",
        component: CollegeDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/departments",
        name: "Departments",
        component: DepartmentsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/departments/create/:id",
        name: "Create Department",
        component: CreateDepartmentPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/organizations/departments/:id",
        name: "Department Details",
        component: DepartmentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/branches",
        name: "Branches",
        component: BranchesPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/organizations/branches/create/",
        name: "Create Branche",
        component: CreateBranchePage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/organizations/branches/:id/edit",
        name: "Edit Branche",
        component: CreateBranchePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:id/jobs",
        name: "Jobs Page",
        component: JobsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:id/majorTracks",
        name: "Major Tracks Page",
        component: MajorTracksPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:id/exitPoints",
        name: "Exit Points Page",
        component: ExitPointsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:id/surveyTemplates",
        component: SurveyTemplatesSpec,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/program/:id/surveyTemplates/:idST/mapping/",
        name: "Survey Templates Mapping",
        component: SurveyTemplatesMapping,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },
  //Executive plan
  {
    name: "Planning",
    rtlName: "المخطط",
    icon: TodayIcon,
    collapse: true,
    state: "PlanningCollapse",
    views: [
      {
        path: "/planning/academicCalender/academicYears",
        name: "Academic Calendar",
        rtlName: "التقويم الاكاديمى",
        mini: "AC",
        rtlMini: "ت",
        component: AcademicYears,
        layout: "/citrine",
      },
      {
        path: "/planning/academicCalender/academicYears/:id",
        component: AcademicYear,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId",
        component: Semester,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/planning/academicCalender/academicYears/create",
        component: CreateAcademicYear,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/courses",
        name: "Course Assessment",
        rtlName: "تقييم الدورة",
        mini: "CA",
        rtlMini: "ت د",
        component: CourseAssessment,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id",
        name: "Program Assessment",
        rtlName: "تقييم الدورة",
        mini: "PA",
        rtlMini: "ت د",
        component: ProgramAssessment,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/PLO",
        name: "PLO Assessment",
        rtlName: "تقييم الدورة",
        mini: "PLO",
        rtlMini: "ت د",
        component: PLO,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/GA",
        name: "GA Assessment",
        rtlName: "تقييم الدورة",
        mini: "GA",
        rtlMini: "ت د",
        component: GA,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/PEO",
        name: "PEO Assessment",
        rtlName: "تقييم الدورة",
        mini: "PEO",
        rtlMini: "ت د",
        component: PEO,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/KPI",
        name: "KPI Assessment",
        rtlName: "تقييم الدورة",
        mini: "KPI",
        rtlMini: "ت د",
        component: KPI,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/planning/academicCalender/academicYears/:id/semesters/:semesterId/program/:program_id/survey",
        component: SurveyPlanning,
        layout: "/citrine",
        invisible: true,
      },

      // {
      //   path: "/academicCalender/",
      //   name: "Academic Calendar",
      //   rtlName: "التقويم الاكاديمى",
      //   mini: "AC",
      //   rtlMini: "ت",
      //   component: AcademicCalender,
      //   layout: "/citrine",
      // },
      // {
      //   path: "/academicCalender/:id/KPIProgram",
      //   component: KPIProgram,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path: "/academicCalender/:id/KPIProgram/:programId/KPIs",
      //   component: KPIsByProgram,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path: "/academicCalender/:id/KPIProgram/:programId/KPIs/:KPIId",
      //   component: KPIProgramDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
    ],
  },

  // Faculty
  {
    name: "Faculty",
    rtlName: "الأساتذه",
    icon: FaUniversity,
    collapse: true,
    state: "FacultyCollapse",
    views: [
      {
        path: "/faculty/Activities",
        name: "Activities",
        rtlName: "الأنشطة",
        mini: "A",
        rtlMini: "أ",
        component: AllActivities,
        layout: "/citrine",
      },
      {
        path: "/faculty/programs/:id/activities",
        component: ActivitiesByProgram,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/programs/:id/activities/create",
        component: CreateActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/programs/:id/activities/:activityId",
        component: Activity,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff",
        name: "Directory",
        rtlName: "هيئة التدريس",
        mini: "C",
        rtlMini: "أ",
        component: TeachingStaff,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id",
        component: TeachingStaffDetails,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivities",
        component: ResearchAndInnovation,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/researchChoice",
        component: ResearchActivityTypeChoice,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/professionalDevelopmentActivities",
        component: ProfessionalDevelopment,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/communityServicesActivities",
        component: CommunityServices,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/teachingLoadActivities",
        component: TeachingLoad,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivity/:activityId",
        component: ResearchAndInnovationDetails,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/professionalDevelopmentActivity/:activityId",
        component: ProfessionalDevelopmentDetails,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/communityServicesActivity/:activityId",
        component: CommunityServicesDetails,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/teachingLoadActivity/:activityId",
        component: TeachingLoadDetails,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:id/teachingLoadActivities/create",
        component: CreateTeachingLoadActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/communityServicesActivities/create",
        component: CreateCommunityServicesActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivities/create",
        component: CreateResearchAndInnovationActivity,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivityOutputs/:activityId/:type",
        component: ResearchAndInnovationDetailsOutputs,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivitiesOutputs/create",
        component: CreateResearchAndInnovationActivityOutputs,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:id/researchAndInnovationActivitiesOutputs",
        component: ResearchAndInnovationOutputs,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:id/professionalDevelopmentActivities/create",
        component: CreateProfessionalDevelopmentActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/Activities/AllResearchAndInnovationActivities",
        component: AllResearchAndInnovationActivities,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/Activities/AllCommunityServicesActivities",
        component: AllCommunityServicesActivities,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/Activities/AllProfessionalDevelopmentActivities",
        component: AllProfessionalDevelopmentActivities,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/Activities/AllTeachingLoadActivities",
        component: AllTeachingLoadActivities,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:staffId/communityServicesActivities/:id/update",
        component: EditCommunityServiceActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/researchAndInnovationActivities/update/:id",
        component: EditResearchAndInnovationActivity,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/faculty/teachingStaff/:personId/researchAndInnovationActivitiesOutputs/update/:id/:activityType",
        component: EditResearchAndInnovationActivityOutputs,
        invisible: true,
        layout: "/citrine",
      },

      {
        path: "/faculty/teachingStaff/:personId/professionalDevelopmentActivities/update/:id",
        component: EditProfessionalDevelopmentActivity,
        invisible: true,
        layout: "/citrine",
      },
    ],
  },
  {
    path: "/assessmentsByUser",
    name: "Workspace",
    rtlName: "التقييم",
    icon: AssessmentIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "asessmentsCollapseByUser",
    views: [
      {
        path: "/faculty/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:group_id/editStudent",
        name: "edit student instructor",
        component: EditStudentInstructorForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/addStudent/",
        name: "add student instructor",
        component: AddNewStudentInstructor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:group_id",
        name: "assessmentMethodInstructorGroupDetails",
        component: AssessmentMethodInstructorGroupDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/coursesAssessmentsGroups/:id/Grades",
        name: "AssessmentMethodDetailsInstructor",
        component: AssessmentMethodDetailsInstructor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/",
        name: "editGroupInstructor",
        layout: "/citrine",
        component: EditGroupsInstructor,
        invisible: true,
      },
      {
        path: "/faculty/CoursesAssessmentsGroups/",
        name: "My groups",
        mini: "C",
        rtlMini: "م",
        component: InstructorCourseAssessmentGroupListPage,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/faculty/coursesAssessmentsGroups/:id/config",
        name: "Group Config",
        mini: "C",
        rtlMini: "م",
        component: InstructorCourseAssessmentGroupConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/UserSurveysList/",
        name: "My surveys",
        mini: "AS",
        rtlMini: "ا ط",
        component: StaffSurveyCampaignList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/surveys/studentsSurvey/:id",
        name: "StudentSurveyCampaignDetails",
        component: StudentSurveyCampaignDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/:id/surveyForm",
        name: "Survey Form",
        component: SurveyForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/surveyAnalysis",
        name: "Survey Analysis",
        component: SurveyAnalysis,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/assessments_list/course/:id",
        name: "AssessmentsList",
        component: AssessmentsListFaculty,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "Assessments List 1 ",
      },
      {
        path: "/faculty/assessmentsQuestions_list/course/:id",
        name: "AssessmentQuestionListFaculty",
        component: AssessmentQuestionListFaculty,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentQuestionList",
      },
      {
        path: "/faculty/assessmentsQuestionsDetails/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetails",
        component: AssessmentsQuestionsDetails,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetails",
      },

      {
        path: "/faculty/assessmentsQuestionsDetailsByGroup/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetailsByGroup",
        component: AssessmentsQuestionsDetailsByGroup,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetailsByGroup",
      },

      {
        path: "/faculty/clos_list/course/:id",
        name: "ClosAssessmentListFaculty",
        component: ClosAssessmentListFaculty,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/closQuestions_list/course/:id",
        name: "ClosQuestionListFaculty",
        component: ClosQuestionListFaculty,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/result_calcul_Clos/course/:id/clo/:clo_id",
        name: "ResultCalculClosNotesFaculty",
        component: ResultCalculClosNotesFaculty,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Insturctors/",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodInstructors,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodInstructors",
      },
      {
        path: "/faculty/result_calcul_Clos_Questions/course/:id/clo/:clo_id",
        name: "ResultCalculClosQuestionNotesFaculty",
        component: ResultCalculClosQuestionNotesFaculty,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/faculty/assessmentsDetails/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsDetails",
        component: AssessmentsDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/actionPlan/actions",
        name: "My actions",
        rtlName: "أجراءات",
        mini: "A",
        rtlMini: "أ",
        component: ActionsByUser,
        layout: "/citrine",
        invisible: false,
      },
      // {
      //   path:
      //     "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId",
      //   component: ProgramSpecificationReportDocumentDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId/editor",
      //   component: ProgramSpecificationReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path: "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId",
      //   component: StudyPlanReportDocumentDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId/editor",
      //   component: StudyPlanReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/courseSpecDetails/:id/CourseSpecificationReportDocument/:progReportDocumentId",
      //   component: CourseSpecificationReportDocumentDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/courseSpecDetails/:id/CourseSpecificationReportDocument/:courseSpecReportDocumentId/editor",
      //   component: CourseSpecificationReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/courseSpecDetails/:id/CourseSyllabusReportDocument/:progReportDocumentId",
      //   component: CourseSyllabusReportDocumentDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path:
      //     "/courseSpecDetails/:id/CourseSyllabusReportDocument/:courseSyllabusReportDocumentId/editor",
      //   component: CourseSyllabusReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },
    ],
  },

  //Assessments
  {
    path: "/assessments",
    name: "Assessment",
    rtlName: "التقييم",
    icon: AssessmentIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "asessmentsCollapse",
    views: [
      {
        path: "/programs_assessment",
        name: "Programs",
        rtlName: "برامج",
        mini: "P",
        rtlMini: "ب",
        component: ProgramsAssessmentPage,
        layout: "/citrine",
        breadcrumbName: "Program",
      },
      {
        path: "/program/:id",
        name: "Course",
        rtlName: "مقررات",
        mini: "C",
        rtlMini: "م",
        component: CoursesListAssessment,
        layout: "/citrine",
        breadcrumbName: "courses assessment",
        invisible: true,
      },

      {
        path: "/program_assessmentDetails/:id",
        name: "ProgramAssessmentDetails",
        component: ProgramAssessmentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/assessments_list/program/:ProgramId/course/:id",
        name: "AssessmentsList",
        component: AssessmentsList,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "Assessments List 1 ",
      },

      {
        path: "/assessmentsQuestions_list/program/:ProgramId/course/:id",
        name: "AssessmentQuestionList",
        component: AssessmentQuestionList,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentQuestionList",
      },
      {
        path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetails",
        component: AssessmentsQuestionsDetails,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetails",
      },

      {
        path: "/AssessmentsQuestionsDetailsByGroup/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetailsByGroup",
        component: AssessmentsQuestionsDetailsByGroup,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetailsByGroup",
      },
      {
        path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Insturctors/",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodInstructors,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodInstructors",
      },
      {
        path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodGroups,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodGroups",
      },
      {
        path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:group_id/",
        name: "AssessmentMethodGroupsDetails",
        component: AssessmentMethodGroupsDetails,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodGroupsDetails",
      },
      {
        path: "/clos_list/program/:programId/course/:id",
        name: "ClosAssessmentList",
        component: ClosAssessmentList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/closQuestions_list/program/:programId/course/:id",
        name: "ClosQuestionList",
        component: ClosQuestionList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/result_calcul_Clos/program/:programId/course/:id/clo/:clo_id",
        name: "ResultCalculClosNotes",
        component: ResultCalculClosNotes,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/result_calcul_Clos_Questions/program/:programId/course/:id/clo/:clo_id",
        name: "ResultCalculClosQuestionNotes",
        component: ResultCalculClosQuestionNotes,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/assessmentsDetails/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsDetails",
        component: AssessmentsDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/program/:id/PloListPage/",
        name: "PloListPage",
        component: PloListPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/program/:id/Plo/:idPlo",
        name: "PloDetails",
        component: PloDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/program/:id/PeoListPage/",
        name: "PeoListPage",
        component: PeoListPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/program/:id/Peo/:idPeo",
        name: "PEO Details",
        component: PEODetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/program/:id/GAListPage/",
        name: "GAListPage",
        component: GAListPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/program/:id/GA/:idGA",
        name: "GADetails",
        component: GADetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/assessmentMethods",
        name: "Assessment Methods",
        component: AssessmentMethodsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createAssessmentMethod/:id",
        name: " Create Assessment Method",
        component: CreateAssessmentMethodPage,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },

  // configurations

  // Reports
  {
    path: "/Improvement",
    name: "Reports",
    rtlName: "التحسينات",
    icon: PostAddSharpIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "ImprovementCollapse",
    views: [
      {
        path: "/reports/NCAAA/",
        name: "NCAAA",
        rtlName: "NCAAA",
        mini: "N",
        rtlMini: "N",
        component: NCAAAReports,
        layout: "/citrine",
      },
      {
        path: "/reports/CTI/",
        name: "CTI",
        rtlName: "CTI",
        mini: "C",
        rtlMini: "C",
        component: CTIReports,
        layout: "/citrine",
      },
      {
        path: "/reports/ABET/",
        name: "ABET",
        rtlName: "ABET",
        mini: "A",
        rtlMini: "A",
        component: ABETReports,
        layout: "/citrine",
      },
      {
        path: "/reports/:type/course/",
        name: "Courses Reports",
        rtlName: "تحسين المقررات",
        mini: "CR",
        rtlMini: "ت م",
        component: CourseImprovementList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/CreateCourseImprovement/:id",
        name: "Course Improvement",
        component: CreateCourseImprovement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/course/:id",
        name: "Course Improvement",
        component: CourseImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/course/:id/documents/:docId",
        component: CourseReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/course/:id/documents/:docId/edit",
        component: CourseReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/ProgramReports",
      //   name: "Programs Reports",
      //   rtlName: "التحسينات",
      //   layout: "/citrine",
      //   invisible: true,
      //   mini: "PR",
      //   collapse: true,
      //   state: "ProgramReportsCollapse",
      // views: [
      {
        path: "/reports/:type/PLO",
        name: "PLOs Report",
        rtlName: "تحسين مخرجات برنامج التعليم",
        mini: "PR",
        rtlMini: "ت م ب ت",
        component: PloImprovementList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/PLO/:id",
        name: "Plo  Improvement",
        component: PloImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/PLO/:id/documents/:docId",
        component: PLOReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/PLO/:id/documents/:docId/edit",
        component: PLOReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/GAImprovementList/",
        name: "GAs Report",
        rtlName: "تحسين المقررات",
        mini: "GR",
        rtlMini: "ت م",
        component: GAImprovementList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/GAImprovementDetails/:id",
        name: "GA Improvement",
        component: GAImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/GAImprovementDetails/:id/GAReportDocument/:gaReportDocumentId",
        component: GAReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/GAImprovementDetails/:id/GAReportDocument/:gaReportDocumentId/editor",
      //   component: GAReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      {
        path: "/PeoImprovementList/",
        name: "PEO Report",
        rtlName: "تحسين المقررات",
        mini: "PR",
        rtlMini: "ت م",
        component: PEOImprovementList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/PeoImprovementDetails/:id",
        name: "Peo Improvement",
        component: PEOImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/PEOImprovementDetails/:id/PEOReportDocument/:peoReportDocumentId",
        component: PEOReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/PEOImprovementDetails/:id/PEOReportDocument/:peoReportDocumentId/editor",
      //   component: PEOReportDocumentEditor,
      //   layout: "/citrine",
      //   invisible: true,
      // },

      {
        path: "/reports/:type/annualProgram",
        name: "AP Report",
        rtlName: "تحسين المقررات",
        mini: "APR",
        rtlMini: "ت م",
        component: AnnualProgramImprovementList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/reports/:type/annualProgram/:id",
        name: "Annual Program",
        component: AnnualProgramImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/reports/:type/annualProgram/:id/documents/:docId",
        component: AnnualProgramReportDocument,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/KPIsImprovementList/",
        name: "KPIs Reports",
        rtlName: "تحسين مخرجات برنامج التعليم",
        mini: "KR",
        rtlMini: "ت م ب ت",
        component: KPIsImprovementList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/KPIsImprovementDetails/:Id/:type/",
        name: "kpi Improvement",
        component: KPIsImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      // ],
      // },
    ],
  },
  //action plan
  {
    path: "/actionPlan/",
    name: "Action Plan",
    rtlName: "مخطط العمل",
    icon: ViewListRoundedIcon,
    collapse: true,
    views: [
      {
        path: "/actionPlan/Workflow",
        name: "Action Plan",
        rtlName: "مخطط العمل",
        mini: "AP",
        rtlMini: "م ع",
        component: ActionPlan,
        layout: "/citrine",
      },
      {
        path: "/actionPlan/actions",
        name: "Actions",
        rtlName: "أجراءات",
        mini: "A",
        rtlMini: "أ",
        component: Actions,
        layout: "/citrine",
      },
      {
        path: "/ActionPlanDetails/:id",
        name: "Action Plan Details",
        rtlName: "مخطط العمل",
        icon: ViewListRoundedIcon,
        component: ActionPlanDetails,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/actionPlan/calendar",
      //   name: "Calendar",
      //   rtlName: "التقويم",
      //   mini: "C",
      //   rtlMini: "ت",
      //   component: ActionPlanCalendarView,
      //   layout: "/citrine",
      // },
      // {
      //   path: "/actionPlan/list",
      //   name: "List",
      //   rtlName: "التقويم",
      //   mini: "C",
      //   rtlMini: "ت",
      //   component: ActionPlanListView,
      //   layout: "/citrine",
      // },
    ],
  },
  //surveys
  {
    name: "Surveys",
    rtlName: "الاستبيان",
    icon: SpeakerNotesIcon,
    collapse: true,
    state: "surveyCollapse",
    views: [
      {
        path: "/surveys/studentsSurvey",
        name: "Internal Survey",
        rtlName: "استبيان الطلاب",
        mini: "AS",
        rtlMini: "ا ط",
        component: StudentSurveyCampaignList,
        layout: "/citrine",
      },
      // {
      //   path: "/surveys/externalSurvey",
      //   name: "External Survey",
      //   rtlName: "استبيان الطلاب",
      //   mini: "ES",
      //   rtlMini: "ا ط",
      //   component: StudentSurveyCampaignList,
      //   layout: "/citrine",
      // },
      {
        path: "/surveys/studentsSurvey/:id",
        name: "StudentSurveyCampaignDetails",
        component: StudentSurveyCampaignDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/:id/surveyForm",
        name: "Survey Form",
        component: SurveyForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/surveyAnalysis",
        name: "Survey Analysis",
        component: SurveyAnalysis,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: "/citrine",
  //   invisible: true,
  // },
  //configuration
  {
    path: "/Configuration",
    name: "Configuration",
    rtlName: "الإعدادات",
    icon: SettingsApplicationsIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "configurationsCollapse",
    views: [
      {
        path: "/configuration/others/StepConfigPage",
        name: "Step Config Page",
        component: StepConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/addStepConfigPage",
        name: "Add Step Config Page",
        component: AddStepConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/editStepConfig/:id",
        name: "edit auth level config",
        component: EditStepConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/StateNamePage",
        name: "State Name Page",
        component: StateNameConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/addStateNamePage",
        name: "add state name",
        component: AddStateNameConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/editStateNamePage/:id",
        name: "editStateNamePage",
        component: EditStateNameConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/ActionApprovalProcessPage",
        name: "ActionApprovalProcess",
        component: ActionApprovalProcess,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/addActionApprovalProcessPage",
        name: "AddActionApprovalProcess",
        component: AddActionApprovalProcess,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/editActionApprovalProcessPage/:id",
        name: "EditActionApprovalProcess",
        component: EditActionApprovalProcess,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/authorityLevelPage",
        name: "add auth level config",
        component: AuthorityLevelConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/addAuthorityLevelPage",
        name: "add auth level config",
        component: AddAuthorityLevelConfig,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/editAuthorityLevelPage/:id",
        name: "edit authority level config",
        component: EditAuthorityLevelConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/AddKpiStandardConfig",
        name: "Add Kpi Standard onfig",
        component: AddKpiStandardConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/EditKpiStandardConfig/:id",
        name: "edit Kpi Standard Config",
        component: EditKpiStandardConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/KpiStandardType",
        name: "kpi standard config page",
        component: KpiStandardType,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/programs/archive",
        name: "programsArchive",
        component: programsArchive,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "مم",
        component: UserProfile,
        invisible: true,
        layout: "/citrine",
      },
      {
        path: "/resourceTypeConfigs",
        name: "Resource Type Configs",
        component: ResourceTypesPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createResourceTypeConfig/:id",
        name: " Create Resource Type",
        component: CreateResourceTypePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/verificationCredibilityStudentsResultsConfigs",
        name: "Verification Credibility Configs",
        component: VerificationCredibilityStudentsResultsConfigsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createVerificationCredibilityStudentsResultsConfig/:id",
        name: " Create Verification Credibility",
        component: CreateVerificationCredibilityStudentsResultsConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ModeOfInstructionConfigs",
        name: "Mode Of Instruction Configs",
        component: ModeOfInstructionConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createModeOfInstructionConfig/:id",
        name: "Mode Of Instruction Config",
        component: CreateModeOfInstructionConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/yearSemesters",
        name: "YearSemesters",
        component: YearSemestersPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/yearSemesters/create",
        name: " Create YearSemesters",
        component: CreateYearSemesterPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/yearSemesters/:id/edit",
        name: " Edit YearSemesters",
        component: CreateYearSemesterPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/areas",
        name: "Areas",
        component: AreasPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/areas/create",
        name: " Create Area",
        component: CreateAreaPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/areas/:id/edit",
        name: " Edit Area",
        component: CreateAreaPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/areas/:id",
        name: "Area details",
        component: AreaDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/course/subAreas",
        name: "SubAreas",
        component: SubAreasPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/subAreas/create",
        name: " Create SubArea",
        component: CreateSubAreaPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/subAreas/:id/edit",
        name: " Edit SubArea",
        component: CreateSubAreaPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/admissionRequirements",
        name: " Admission requirements list",
        component: ProgramAdmissionRequirements,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduateAttributeConfigs",
        name: " Graduate Attribute Configs list",
        component: GraduateAttributeConfigsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduateAttributeConfigs/create",
        name: " Create Graduate Attribute Configs",
        component: CreateGraduateAttributeConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduateAttributeConfigs/edit/:id",
        name: " Edit Graduate Attribute Configs",
        component: CreateGraduateAttributeConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/admissionRequirements/create",
        name: " Create Admission requirements",
        component: CreateProgramAdmissionRequirement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/admissionRequirements/:id/edit",
        name: " Create Admission requirements",
        component: CreateProgramAdmissionRequirement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduationRequirements/",
        name: " Graduation requirements list",
        component: GraduationRequirementPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/levelPlos/",
        name: " levelPlos list",
        component: levelPlosPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/levelPlos/create",
        name: " levelPlos create",
        component: CreateLevelPlos,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/levelPlos/:id/edit/",
        name: " levelPlos edit",
        component: CreateLevelPlos,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/subLevelPlos/",
        name: " sublevel Plos list",
        component: SubLevelPlosPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/subLevelPlos/create",
        name: " sublevel Plos create",
        component: CreateSubLevelPlos,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/subLevelPlos/:id/edit/",
        name: " sublevel Plos edit",
        component: CreateSubLevelPlos,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/fields/",
        name: " Fields list",
        component: FieldsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createField/:id",
        name: " Create Field",
        component: CreateFieldPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specializations/",
        name: " Specializations list",
        component: SpecializationsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createSpecialization/:id",
        name: " Create Specialization",
        component: CreateSpecializationPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduationRequirements/create",
        name: " Create Graduation requirements",
        component: CreateProgramGraduationRequirement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/graduationRequirements/edit/:id",
        name: " Create Graduation requirements",
        component: CreateProgramGraduationRequirement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/AcademicYearList/",
        name: "AcademicYearList",
        component: AcademicYearList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/semesterList/:id",
        name: "SemesterList",
        component: SemesterList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/TeachingStrategiesConfigList",
        name: "TeachingStrategiesConfigList",
        component: TeachingStrategiesConfigList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateTeachingStrategiesConfig/:id",
        name: "CreateTeachingStrategiesConfig",
        component: CreateTeachingStrategiesConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ActualLeaningConfigList",
        name: "ActualLeaningConfigList",
        component: ActualLeaningConfigList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateActualLeaningConfig/:id",
        name: "CreateActualLeaningConfig",
        component: CreateActualLeaningConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ActualLeaningConfigType",
        name: "ActualLeaningConfigType",
        component: ActualLeaningConfigType,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateActualLeaningConfigType/:id",
        name: "CreateActualLeaningConfigType",
        component: CreateActualLeaningConfigType,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/FacilitiesRequiredConfigList",
        name: "FacilitiesRequiredConfigList",
        component: FacilitiesRequiredConfigList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateFacilitiesRequiredConfig/:id",
        name: "CreateFacilitiesRequiredConfig",
        component: CreateFacilitiesRequiredConfig,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/KPI",
        component: KPIConfigList,
        name: "KPIs",
        rtlName: "KPI",
        mini: "K",
        rtlMini: "ك",
        layout: "/citrine",
      },
      {
        path: "/configuration/KPI/create",
        component: CreateKPIConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/KPI/:id",
        component: KPIConfigDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/KPI/:id/update",
        component: UpdateKPIConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course",
        name: "Courses",
        rtlName: "مقررات",
        mini: "C",
        rtlMini: "م",
        component: CourseConfigurations,
        layout: "/citrine",
      },
      {
        path: "/configuration/program",
        name: "Programs",
        rtlName: "برامج",
        mini: "P",
        rtlMini: "ب",
        component: ProgramConfigurations,
        layout: "/citrine",
      },
      {
        path: "/configuration/others",
        name: "Other",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: OthersConfigurations,
        layout: "/citrine",
      },

      {
        path: "/configuration/others/AddConfigSkills",
        name: "type",
        component: AddConfigSkills,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/SkillsPage",
        name: "type",
        component: SkillsPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddAuthority",
        name: "type",
        component: AddAuthority,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AuthorityPage",
        name: "type",
        component: AuthorityPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/Authority",
        name: "authority",
        component: Authority,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/AddAuthorityConfig",
        name: "addAuthority",
        component: AddAuthorityConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/actiontypepage",
        name: "type",
        component: ActionTypePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/approvalLevels",
        component: ApprovalLevelsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/addActionTypeConfig",
        name: "type",
        component: AddActionTypeConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/approvalLevels/create",
        component: CreateApprovalLevel,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/EditAuthority/:id",
        name: "editAuthority",
        component: EditAuthority,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/AddStandard",
        name: "type",
        component: AddStandardPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/StandardPage",
        name: "type",
        component: StandardPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddPublisher",
        name: "type",
        component: addPublisherPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/PublisherPage",
        name: "type",
        component: PublisherPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddResearchDirection",
        name: "type",
        component: addResearchDirectionPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/ResearchDirectionPage",
        name: "type",
        component: ResearchDirectionPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddReportStatesConfigPage",
        name: "ReportStates",
        component: AddReportStatesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/ReportStatesConfigPage",
        name: "ReportStates",
        component: ReportStatesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddTypesConfigPage",
        name: "type",
        component: AddTypesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/TypesConfigPage",
        name: "type",
        component: TypesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddRolesConfigPage",
        name: "type",
        component: AddRolesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/RolesConfigPage",
        name: "type",
        component: RolesConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddIndexResearchPage",
        name: "type",
        component: AddIndexResearch,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/IndexResearchPage",
        name: "type",
        component: IndexResearchPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddUtilizedResearchLabsPage",
        name: "type",
        component: AddUtilizedResearchLabs,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/UtilizedResearchLabsPage",
        name: "type",
        component: UtilizedResearchLabsPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/AddInternationalResearchConfig",
        name: "type",
        component: AddInternationalResearchConfig,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/InternationalResearchConfigPage",
        name: "type",
        component: InternationalResearchConfigPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/type",
        name: "type",
        component: Types,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/AccreditationPrograms",
        name: "AccreditationPrograms",
        component: AccreditationPrograms,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ProgramAccreditationDetails",
        name: "ProgramAccreditationDetails",
        component: ProgramAccreditationDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/GeneralCriteria",
        name: "GeneralCriteria",
        component: GeneralCriteria,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ProgramCriteria",
        name: "ProgramCriteria",
        component: ProgramCriteria,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/category",
        name: "category",
        component: Categories,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/category/createCategory/:id",
        name: "create Category",
        component: CreateCategory,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/type",
        name: "type",
        component: Types,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/type/createType/:id",
        name: "create Type",
        component: CreateType,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/totalLevel",
        component: TotalLevels,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/program/totalLevel/createTotalLevel/:id",
        component: CreateTotalLevel,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/level",
        component: Levels,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/level/createLevel/:id",
        component: CreateLevel,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/assessmentMode",
        component: AssessmentModes,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/assessmentMode/createAssessmentMode/:id",
        component: CreateAssessmentMode,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/codeLevels",
        name: "code Level",
        component: codeLevelPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createCodeLevel/:id",
        name: " create Code Level",
        component: CreatedCodeLevel,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/subCodeLevels",
        name: "subCode Level",
        component: SubCodeLevelPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/subCodeLevels/create",
        name: " create Code Level",
        component: CreateSubCodeLevel,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/course/subCodeLevels/:id/edit",
        name: " edit Code Level",
        component: CreateSubCodeLevel,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/surveyTemplatesNavigationPage",
      //   name: "Survey Templates",
      //   rtlName: "قوالب الاستطلاع",
      //   mini: "S T",
      //   rtlMini: "ق ا",
      //   component: SurveyTemplatesNavigationPage,
      //   layout: "/citrine",
      // },
      {
        path: "/surveyTemplates",
        name: "Survey Templates",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: SurveyTemplates,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/types/",
        name: "Survey Templates Types",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: SurveyTemplatesTypes,
        layout: "/citrine",
        invisible: true,
      },

      // {
      //   path: "/mailtemplates",
      //   name: "Survey Templates Types",
      //   rtlName: "قوالب الاستطلاع",
      //   mini: "S T",
      //   rtlMini: "ق ا",
      //   component: MailTemplates,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      // {
      //   path: "/mailtemplates/:id",
      //   name: "Survey Templates Types",
      //   rtlName: "قوالب الاستطلاع",
      //   mini: "S T",
      //   rtlMini: "ق ا",
      //   component: MailTemplatesDetails,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      {
        path: "/mailetemplist",
        name: "Survey Templates Types",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: MaileTempList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/SurveyMails/",
        name: "Survey Templates Types",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: SurveyMails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/types/add",
        name: "Survey Templates Types",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: SurveyTemplateTypeAddEdit,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/types/:id/edit",
        name: "Survey Templates Types",
        rtlName: "قوالب الاستطلاع",
        mini: "S T",
        rtlMini: "ق ا",
        component: SurveyTemplateTypeAddEdit,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/add",
        component: CreateSurveyTemplate,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/:id",
        component: SurveyForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/:id/editWeights",
        component: EditResponsesWeights,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/:id/addWeightToQuestions",
        component: AddWeightToSurveyQuestions,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveyTemplates/:id/editor",
        component: SurveyTemplateEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/SurveyTemplateConfList/",
        component: SurveyTemplateConfList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/SurveyTemplateConfList/",
        component: SurveyTemplateConfList,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/surveyTemplates/:id/editor",
      //   component: SurveyTemplateEditor2,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      {
        path: "/HTMLTemplates/:id/editor",
        component: HTMLTemplateEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/HTMLTemplates/:id",
        component: HTMLTemplateViewer,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/HTMLTemplates/HTMLTemplate/add",
        component: CreateHTMLTemplate,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/others/EvidenceTypeConfigPage",
        name: "EvidenceTypeConfigPage",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: EvidenceTypeConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/assessmentMethodDetails/:id/:name",
        name: "AssessmntDetails",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: AssessementDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/configuration/editEvidenceType/:id",
        name: "EditEvidenceTypeConfigPage",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: EditEvidenceTypesConfig,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/AddEvidenceTypeConfigPage",
        name: "AddEvidenceTypeConfigPage",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: AddEvidenceTypeConfigPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/configuration/others/file",
        name: "file",
        rtlName: "المراجع",
        mini: "O",
        rtlMini: "م",
        component: File,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/KPIImprovementDetails/:id/KPIReportDocument/:kpiReportDocumentId",
        component: KpiReportDocument,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/KPIImprovementDetails/:id/KPIReportDocument/:kpiReportDocumentId/editor",
        component: KpiReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },
  //access control
  {
    path: "/AccessControl",
    name: "Access Control",
    rtlName: "التحكم في الوصول",
    icon: AccessibilityIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "AccessControlCollapse",
    views: [
      {
        path: "/menuAccessControl",
        name: "Menus",
        rtlName: " لوحات التصفح",
        mini: "M",
        rtlMini: " ل ت",
        component: MenuList,
        layout: "/citrine",
        invisible: false,
      },

      {
        path: "/addEditAccessControl/:id",
        name: "MenusAccessControlAddEdit",
        component: AddEditMenuAccessControl,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/models",
        name: "Models",
        rtlName: "المجموعات",
        mini: "M",
        rtlMini: "م",
        component: ModelsList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/models/addAccessControl/:id",
        name: "addAccessControl",
        component: AddAccessControl,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/groups",
        name: "Groups",
        rtlName: "المجموعات",
        mini: "G",
        rtlMini: "م",
        component: GroupList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/groupActions",
        name: "Group Actions",
        rtlName: "المجموعات",
        mini: "G",
        rtlMini: "م",
        component: GroupModelsReport,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/groupActions/states/:Report/",
        name: "Group Sates",
        rtlName: "المجموعات",
        mini: "G",
        rtlMini: "م",
        component: ReportStates,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/groupActions/states/:Report/:id/actions/",
        name: "Group Sates Actions",
        rtlName: "المجموعات",
        mini: "G",
        rtlMini: "م",
        component: ReportStatesActions,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/groupDetails/:id/:name",
        name: "Group Details",
        rtlName: "المجموعات",
        component: GroupDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/addEditGroup/:id",
        name: "GroupAddEdit",
        component: AddEditGroup,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/persons",
        name: "Persons",
        rtlName: "الأشخاص",
        mini: "P",
        rtlMini: "أ",
        component: PersonList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/addEditPerson/:id",
        name: "PersonAddEdit",
        component: AddEditPerson,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/addUser",
        name: "UserAdd",
        component: AddEditUser,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/EditUser/:id",
        name: "UserEdit",
        component: AddEditUser,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/users",
        name: "Users",
        component: UserList,
        layout: "/citrine",
        invisible: false,
        rtlName: "المستعملين",
        mini: "U",
        rtlMini: "م",
      },
      {
        path: "/addStudent/",
        name: "add student",
        component: AddNewStudent,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/editStudent/",
        name: "edit student",
        component: EditStudent,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },
  //templates
  {
    path: "/HTMLTemplates",
    name: "HTML Templates",
    rtlName: "القوالب",
    icon: IntegrationInstructionsIcon,
    component: HTMLTemplatesList,
    layout: "/citrine",
  },
  {
    path: "/Admin",
    name: "Admin",
    rtlName: "إدارة",
    icon: AdminPanelSettingsIcon,
    component: AdminPage,
    layout: "/citrine",
    views: [
      // {
      //   path: "/LMSData/pull/",
      //   name: "PullLMSData",
      //   component: PullLMSData,
      //   invisible: true,
      //   layout: "/citrine",
      // },
    ],
  },
  //mail
  {
    path: "/Mail",
    name: "Mail",
    rtlName: "البريد",
    icon: AccessibilityIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "MailCollapse",
    views: [
      {
        path: "/addEditOutGoingMailServer/:id",
        name: "addEditOutGoingMailServer",
        rtlName: " البريد",
        mini: "M",
        rtlMini: " ل ت",
        component: AddEditOutGoingMailServer,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/outGoingMailServers",
        name: "OutGoingMailServers",
        rtlName: " البريد",
        mini: "M",
        rtlMini: " ل ت",
        component: OutGoingMailServerList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/addEditMailTemplate/:id",
        name: "addEditMailTemplate",
        rtlName: " البريد",
        mini: "M",
        rtlMini: " ل ت",
        component: AddEditMailTemplate,
        layout: "/citrine",
        invisible: false,
      },
      // {
      //   path: "/mailTemplates",
      //   name: "MailTemplates",
      //   rtlName: " البريد",
      //   mini: "M",
      //   rtlMini: " ل ت",
      //   component: mailTemplateList,
      //   layout: "/citrine",
      //   invisible: false,
      // },

      {
        path: "/sendNewMail",
        name: "Send Mail",
        rtlName: " البريد",
        mini: "M",
        rtlMini: " ل ت",
        component: SendNewMail,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/mails",
        name: "Mails List",
        rtlName: " البريد",
        mini: "M",
        rtlMini: " ل ت",
        component: MailList,
        layout: "/citrine",
        invisible: false,
      },
    ],
  },
  //courseCoordinatorWorkspace
  {
    path: "/courseCoordinatorWorkspace",
    name: "Course Workspace",
    rtlName: "التقييم",
    icon: AssessmentIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "courseCoordinatorWorkspace",
    views: [
      //specification
      {
        path: "/CourseSpecificationList",
        name: "Specifications",
        rtlName: "المقررات",
        mini: "S",
        rtlMini: "م",
        component: CourseSpecificationListCoordinator,
        layout: "/citrine",
      },
      // {
      //   path: "/CourseSpecificationList",
      //   name: "Specifications",
      //   rtlName: "المقررات",
      //   mini: "S",
      //   rtlMini: "م",
      //   component: CourseSepecificationDetailsCoordinator,
      //   layout: "/citrine",
      // },
      {
        path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:progReportDocumentId",
        component: CourseSpecificationReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:courseSpecReportDocumentId/editor",
        component: CourseSpecificationReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:progReportDocumentId",
        component: CourseSyllabusReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:courseSyllabusReportDocumentId/editor",
        component: CourseSyllabusReportDocumentEditor,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createCourseClo/course/:id/clo/:idClo",
        name: "Create course Clo",
        component: CreateCourseClo,
        layout: "/citrine",
        invisible: true,
      },
      // {
      //   path: "/editDefaultQuestion/course/:id/clo/:idClo",
      //   name: "Create course Clo",
      //   component: EditDefaultQuestion,
      //   layout: "/citrine",
      //   invisible: true,
      // },
      {
        path: "/courseCloDetails/:id",
        name: "Course Clo details",
        component: CourseCloDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/addCourseSpec",
        name: "Create Course",
        component: CreateCourseSpec,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id",
        name: "Course details",
        component: CourseSpecDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecUpdate/:id",
        name: "Course Update",
        component: CourseSpecUpdate,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/createCourseAssessmentMethod",
        name: "Create course assessment method",
        component: CreateCourseAssessmentMethod,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courses/:id/clos",
        component: CLOs,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseSpecDetails/:id/SpecificationAprovalDataList",
        name: "SpecificationAprovalDataList",
        component: SpecificationAprovalDataList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CreateModeOfInstruction/course/:id/modeOfInstruction/:idMOI",
        name: "CreateModeOfInstruction",
        component: CreateModeOfInstruction,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/ModeOfInstructionList/:id",
        name: "ModeOfInstructionList",
        component: ModeOfInstructionList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources/create",
        name: "CreateLearningResource",
        component: CreateLearningResource,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources/:idLR/edit",
        name: "EditLearningResource",
        component: CreateLearningResource,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/LearningResources",
        name: "LearningResourceList",
        component: LearningResourceList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds/create",
        name: "CreateFacilitiesRequired",
        component: CreateFacilitiesRequired,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds/:idFR/edit",
        name: "EditFacilitiesRequired",
        component: CreateFacilitiesRequired,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FacilitiesRequireds",
        name: "FacilitiesRequiredList",
        component: FacilitiesRequiredList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours/create",
        name: "CreateActualLearningHour",
        component: CreateActualLearningHour,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours/:idHour/edit",
        name: "EditActualLearningHour",
        component: CreateActualLearningHour,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActualLearningHours",
        name: "ActualLearningHourCard",
        component: ActualLearningHourCard,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ActualLearningHoursViews",
        name: "ActualLearningHourViews",
        component: ActualLearningHourViews,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ActualLearningHoursTreeList",
        name: "ActualLearningHoursTreeList",
        component: ActualLearningHoursTreeList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContacts/:idCC/edit",
        name: "EditCourseContact",
        component: CreateCourseContact,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContacts/create",
        name: "CreateCourseContact",
        component: CreateCourseContact,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContacts",
        name: "CourseContactList",
        component: CourseContactList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines/:idCC/edit",
        name: "EditCourseOutline",
        component: CreateCourseOutline,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines/create",
        name: "CreateCourseOutline",
        component: CreateCourseOutline,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseOutlines",
        name: "CourseOutlineList",
        component: CourseOutlineList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/courseSyllabus",
        component: CourseSyllabus,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents/:idCC/edit",
        name: "EditCourseContent",
        component: CreateCourseContent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents/create",
        name: "CreateCourseContent",
        component: CreateCourseContent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseContents",
        name: "CourseContentCard",
        component: CourseContentCard,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContentViews",
        name: "CourseContentViews",
        component: CourseContentViews,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/CourseContentTreeList",
        name: "CourseContentTreeList",
        component: CourseContentTreeList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AssessmentTasksForStudents/create",
        name: "CreateAssessmentTasksForStudent",
        component: CreateAssessmentTasksForStudent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentTasksForStudents/:idATFS/edit",
        name: "CreateAssessmentTasksForStudent",
        component: CreateAssessmentTasksForStudent,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentTasksForStudents",
        name: "AssessmentTasksForStudentList",
        component: AssessmentTasksForStudentList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/create",
        name: "CreateAlignmentOfCLOWithTMAndAM",
        component: CreateAlignmentOfCLOWithTMAndAM,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/:idACWTMAM/edit",
        name: "EditAlignmentOfCLOWithTMAndAM",
        component: CreateAlignmentOfCLOWithTMAndAM,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs",
        name: "AlignmentOfCLOWithTMAndAMList",
        component: AlignmentOfCLOWithTMAndAMList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations/create",
        name: "CreateCourseQualityEvaluation",
        component: CreateCourseQualityEvaluation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations/:idCQE/edit",
        name: "EditCourseQualityEvaluation",
        component: CreateCourseQualityEvaluation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/CourseQualityEvaluations",
        name: "CourseQualityEvaluationList",
        component: CourseQualityEvaluationList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies/create",
        name: "CreateTeachingStrategies",
        component: CreateTeachingStrategies,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies/:idTS/edit",
        name: "EditTeachingStrategies",
        component: CreateTeachingStrategies,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/TeachingStrategies",
        name: "TeachingStrategiesList",
        component: TeachingStrategiesList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods/create",
        name: "CreateActivitiesAssessmentMethods",
        component: CreateActivitiesAssessmentMethods,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods/:idAAM/edit",
        name: "CreateActivitiesAssessmentMethods",
        component: CreateActivitiesAssessmentMethods,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/ActivitiesAssessmentMethods",
        name: "ActivitiesAssessmentMethodsList",
        component: ActivitiesAssessmentMethodsList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/VerificationCredibilities/create",
        name: "CreateVerificationCredibility",
        component: CreateVerificationCredibility,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/VerificationCredibilities/:idVerif/edit",
        name: "EditVerificationCredibility",
        component: CreateVerificationCredibility,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/VerificationCredibilities",
        name: "VerificationCredibilityList",
        component: VerificationCredibilityList,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:IdC/ranges/create",
        name: " Create Range",
        component: CreateRangePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:IdC/ranges/:IdR/edit",
        name: " Edit Range",
        component: CreateRangePage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/ranges/:id",
        name: "Range details",
        component: RangeDetails,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/ranges",
        name: " Ranges list",
        component: RangesPage,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/DatesAndTimesAllocations/create",
        name: "CreateDatesAndTimesAllocation",
        component: CreateDatesAndTimesAllocation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/DatesAndTimesAllocations/:idDTA/edit",
        name: "EditDatesAndTimesAllocation",
        component: CreateDatesAndTimesAllocation,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/DatesAndTimesAllocations",
        name: "DatesAndTimesAllocationList",
        component: DatesAndTimesAllocationList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/FieldExperienceLocations/create",
        name: "CreateFieldExperienceLocations",
        component: CreateFieldExperienceLocations,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FieldExperienceLocations/:idFEL/edit",
        name: "EditFieldExperienceLocations",
        component: CreateFieldExperienceLocations,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/FieldExperienceLocations",
        name: "FieldExperienceLocationsList",
        component: FieldExperienceLocationsList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/specification/course/:id/AssessmentResponsibilities/create",
        name: "CreateAssessmentResponsibilities",
        component: CreateAssessmentResponsibilities,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentResponsibilities/:idAR/edit",
        name: "EditAssessmentResponsibilities",
        component: CreateAssessmentResponsibilities,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/specification/course/:id/AssessmentResponsibilities",
        name: "AssessmentResponsibilitiesList",
        component: AssessmentResponsibilitiesList,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/UpdateSpecificationAprovalData/:idCourse/:id",
        name: "UpdateSpecificationAprovalData",
        component: UpdateSpecificationAprovalData,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/UpdateClo/:id",
        name: "UpdateClo",
        component: UpdateClo,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/SpecificationAprovalDataList",
        name: "SpecificationAprovalDataList",
        component: SpecificationAprovalDataList,
        layout: "/citrine",
        invisible: true,
      },
      //assessment
      {
        path: "/courseCoordinatorWorkspace/assessment/",
        name: "Assessments",
        rtlName: "تحسين المقررات",
        mini: "CA",
        rtlMini: "ت م",
        component: CoordinatorCoursesListAssessmentPage,
        layout: "/citrine",
      },
      {
        path: "/courseCoordinatorWorkspace/assessments_list/course/:id",
        name: "AssessmentsList",
        component: AssessmentsListCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "Assessments List 1 Coordinator",
      },

      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestions_list/course/:id",
        name: "AssessmentQuestionList",
        component: AssessmentQuestionListCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentQuestionListCoordinator",
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetails",
        component: AssessmentsQuestionsDetailsCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetailsCoordinator",
      },
      {
        path: "/courseCoordinatorWorkspace/AssessmentsQuestionsDetailsByGroup/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsQuestionsDetailsByGroup",
        component: AssessmentsQuestionsDetailsByGroupsCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentsQuestionsDetailsByGroupCoordinator",
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Insturctors/",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodInstructorsCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodInstructors",
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodGroupsCoordinator,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodGroups",
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:groupid/addStudent",
        name: "add student",
        component: AddNewStudentCordinator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:groupid",
        name: "AssessmentMethodInstructors",
        component: AssessmentMethodGroupsCoordinatorGroup,
        layout: "/citrine",
        invisible: true,
        breadcrumbName: "AssessmentMethodGroups",
      },
      {
        path: "/courseCoordinatorWorkspace/clos_list/course/:id",
        name: "ClosAssessmentList",
        component: ClosAssessmentListCoordinator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/closQuestions_list/course/:id",
        name: "ClosQuestionList",
        component: ClosQuestionListCoordinator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/result_calcul_Clos/course/:id/clo/:clo_id",
        name: "ResultCalculClosNotes",
        component: ResultCalculClosNotesCoordinator,
        layout: "/citrine",
        invisible: true,
      },

      {
        path: "/courseCoordinatorWorkspace/result_calcul_Clos_Questions/course/:id/clo/:clo_id",
        name: "ResultCalculClosQuestionNotes",
        component: ResultCalculClosQuestionNotesCoordinator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentsDetails/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
        name: "AssessmentsDetails",
        component: AssessmentsDetailsCoordinator,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/assessmentMethods",
        name: "Assessment Methods",
        component: AssessmentMethodsPage,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/courseCoordinatorWorkspace/createAssessmentMethod/:id",
        name: " Create Assessment Method",
        component: CreateAssessmentMethodPage,
        layout: "/citrine",
        invisible: true,
      },
      //report
      {
        path: "/CourseImprovementList/",
        name: "Reports",
        rtlName: "تحسين المقررات",
        mini: "CR",
        rtlMini: "ت م",
        component: CourseImprovementList,
        layout: "/citrine",
      },
      {
        path: "/CreateCourseImprovement/:id",
        name: "Course Improvement",
        component: CreateCourseImprovement,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CourseImprovementDetails/:id",
        name: "Course Improvement",
        component: CourseImprovementDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/CourseImprovementDetails/:id/CourseReportDocument/:courseReportDocumentId",
        component: CourseReportDocumentDetails,
        layout: "/citrine",
        invisible: true,
      },

      //survey
      {
        path: "/CoordinatorSurveysList/",
        name: "Surveys",
        mini: "AS",
        rtlMini: "ا ط",
        component: CoordinatorSurveyCampaignList,
        layout: "/citrine",
        invisible: false,
      },
      {
        path: "/surveys/studentsSurvey/:id",
        name: "StudentSurveyCampaignDetails",
        component: StudentSurveyCampaignDetails,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/:id/surveyForm",
        name: "Survey Form",
        component: SurveyForm,
        layout: "/citrine",
        invisible: true,
      },
      {
        path: "/surveys/studentsSurvey/surveyAnalysis",
        name: "Survey Analysis",
        component: SurveyAnalysis,
        layout: "/citrine",
        invisible: true,
      },
    ],
  },
  // programCoordinatorWorkspace
  {
    path: "/programCoordinatorWorkspace",
    name: "Program Workspace",
    rtlName: "التقييم",
    icon: AssessmentIcon,
    layout: "/citrine",
    invisible: false,
    collapse: true,
    state: "programCoordinatorWorkspace",
    views: [
      //specification
      {
        path: "/Specification",
        name: "Specification",
        rtlName: "المواصفات",
        icon: DescriptionIcon,
        layout: "/citrine",
        invisible: false,
        collapse: true,
        state: "specificationsCollapse",
        views: [
          {
            path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/:group_id/",
            name: "AssessmentMethodGroupsDetails",
            component: AssessmentMethodGroupsDetails,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentMethodGroupsDetails",
          },
          {
            path: "/programSpec",
            name: "Programs",
            rtlName: "البرامج",
            mini: "P",
            rtlMini: "ب",
            component: ProgramsPage,
            layout: "/citrine",
          },
          // {
          //   path: "/CourseSpecificationList",
          //   name: "Courses",
          //   rtlName: "المقررات",
          //   mini: "C",
          //   rtlMini: "م",
          //   component: CourseSpecificationList,
          //   layout: "/citrine",
          // },
          {
            path: "/programSpec/:programId/courses",
            name: "CoursesByProgram",
            component: CoursesByProgram,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/program/:programId/accreditationDates",
            component: AccreditationDates,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/PEOs",
            name: "PEOsPage",
            component: PEOsPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/PEOs/:PEOId/PEOIndicators",
            name: "PEOIndicatorsByPEO",
            component: PEOIndicatorsByPEO,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/PLOs",
            name: "PLOsPage",
            component: PLOsPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/PLOs/:PLOId/PLOIndicators",
            name: "PLOIndicatorsByPLO",
            component: PLOIndicatorsByPLO,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/GraduateAttributes",
            name: "GraduateAttributesPage",
            component: GraduateAttributesPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId/GAs/:GAId/GAIndicators",
            name: "GAIndicators",
            component: GraduateAttributesDetails,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/programSpec/:programId/KPIs",
            component: KPISpecList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:programId",
            name: "ProgramDetails",
            component: ProgramDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/addProgramSpec",
            name: "addProgram",
            component: CreateProgramForm,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/updateProgramSpec/:id",
            name: "updateProgram",
            component: UpdateProgramForm,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId",
            component: ProgramSpecificationReportDocumentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:id/ProgramSpecificationReportDocument/:progReportDocumentId/editor",
            component: ProgramSpecificationReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId",
            component: StudyPlanReportDocumentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/programSpec/:id/StudyPlanReportDocument/:progReportDocumentId/editor",
            component: StudyPlanReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:progReportDocumentId",
            component: CourseSpecificationReportDocumentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id/CourseSpecificationReportDocument/:courseSpecReportDocumentId/editor",
            component: CourseSpecificationReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:progReportDocumentId",
            component: CourseSyllabusReportDocumentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id/CourseSyllabusReportDocument/:courseSyllabusReportDocumentId/editor",
            component: CourseSyllabusReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          // Specifications Course
          {
            path: "/createCourseClo/course/:id/clo/:idClo",
            name: "Create course Clo",
            component: CreateCourseClo,
            layout: "/citrine",
            invisible: true,
          },
          /*{
            path: "/editDefaultQuestion/course/:id/clo/:idClo",
            name: "Create course Clo",
            component: EditDefaultQuestion,
            layout: "/citrine",
            invisible: true,
          },*/
          {
            path: "/courseCloDetails/:id",
            name: "Course Clo details",
            component: CourseCloDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/addCourseSpec",
            name: "Create Course",
            component: CreateCourseSpec,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id",
            name: "Course details",
            component: CourseSpecDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecUpdate/:id",
            name: "Course Update",
            component: CourseSpecUpdate,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/createCourseAssessmentMethod",
            name: "Create course assessment method",
            component: CreateCourseAssessmentMethod,
            layout: "/citrine",
            invisible: true,
          },
          // {
          //   path: "/CourseSpecClosList/:id",
          //   component: CourseSpecClosList,
          //   layout: "/citrine",
          //   invisible: true,
          // },
          {
            path: "/courses/:id/clos",
            component: CLOs,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/courseSpecDetails/:id/SpecificationAprovalDataList",
            name: "SpecificationAprovalDataList",
            component: SpecificationAprovalDataList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/CreateModeOfInstruction/course/:id/modeOfInstruction/:idMOI",
            name: "CreateModeOfInstruction",
            component: CreateModeOfInstruction,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/ModeOfInstructionList/:id",
            name: "ModeOfInstructionList",
            component: ModeOfInstructionList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/LearningResources/create",
            name: "CreateLearningResource",
            component: CreateLearningResource,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/LearningResources/:idLR/edit",
            name: "EditLearningResource",
            component: CreateLearningResource,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/LearningResources",
            name: "LearningResourceList",
            component: LearningResourceList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/FacilitiesRequireds/create",
            name: "CreateFacilitiesRequired",
            component: CreateFacilitiesRequired,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/FacilitiesRequireds/:idFR/edit",
            name: "EditFacilitiesRequired",
            component: CreateFacilitiesRequired,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/FacilitiesRequireds",
            name: "FacilitiesRequiredList",
            component: FacilitiesRequiredList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActualLearningHours/create",
            name: "CreateActualLearningHour",
            component: CreateActualLearningHour,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActualLearningHours/:idHour/edit",
            name: "EditActualLearningHour",
            component: CreateActualLearningHour,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActualLearningHours",
            name: "ActualLearningHourCard",
            component: ActualLearningHourCard,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/ActualLearningHoursViews",
            name: "ActualLearningHourViews",
            component: ActualLearningHourViews,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/ActualLearningHoursTreeList",
            name: "ActualLearningHoursTreeList",
            component: ActualLearningHoursTreeList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/CourseContacts/:idCC/edit",
            name: "EditCourseContact",
            component: CreateCourseContact,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseContacts/create",
            name: "CreateCourseContact",
            component: CreateCourseContact,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseContacts",
            name: "CourseContactList",
            component: CourseContactList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseOutlines/:idCC/edit",
            name: "EditCourseOutline",
            component: CreateCourseOutline,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseOutlines/create",
            name: "CreateCourseOutline",
            component: CreateCourseOutline,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseOutlines",
            name: "CourseOutlineList",
            component: CourseOutlineList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/courseSyllabus",
            component: CourseSyllabus,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseContents/:idCC/edit",
            name: "EditCourseContent",
            component: CreateCourseContent,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseContents/create",
            name: "CreateCourseContent",
            component: CreateCourseContent,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseContents",
            name: "CourseContentCard",
            component: CourseContentCard,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/CourseContentViews",
            name: "CourseContentViews",
            component: CourseContentViews,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/CourseContentTreeList",
            name: "CourseContentTreeList",
            component: CourseContentTreeList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/AssessmentTasksForStudents/create",
            name: "CreateAssessmentTasksForStudent",
            component: CreateAssessmentTasksForStudent,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AssessmentTasksForStudents/:idATFS/edit",
            name: "CreateAssessmentTasksForStudent",
            component: CreateAssessmentTasksForStudent,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AssessmentTasksForStudents",
            name: "AssessmentTasksForStudentList",
            component: AssessmentTasksForStudentList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/create",
            name: "CreateAlignmentOfCLOWithTMAndAM",
            component: CreateAlignmentOfCLOWithTMAndAM,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs/:idACWTMAM/edit",
            name: "EditAlignmentOfCLOWithTMAndAM",
            component: CreateAlignmentOfCLOWithTMAndAM,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AlignmentOfCLOWithTMAndAMs",
            name: "AlignmentOfCLOWithTMAndAMList",
            component: AlignmentOfCLOWithTMAndAMList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseQualityEvaluations/create",
            name: "CreateCourseQualityEvaluation",
            component: CreateCourseQualityEvaluation,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseQualityEvaluations/:idCQE/edit",
            name: "EditCourseQualityEvaluation",
            component: CreateCourseQualityEvaluation,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/CourseQualityEvaluations",
            name: "CourseQualityEvaluationList",
            component: CourseQualityEvaluationList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/TeachingStrategies/create",
            name: "CreateTeachingStrategies",
            component: CreateTeachingStrategies,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/TeachingStrategies/:idTS/edit",
            name: "EditTeachingStrategies",
            component: CreateTeachingStrategies,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/TeachingStrategies",
            name: "TeachingStrategiesList",
            component: TeachingStrategiesList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActivitiesAssessmentMethods/create",
            name: "CreateActivitiesAssessmentMethods",
            component: CreateActivitiesAssessmentMethods,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActivitiesAssessmentMethods/:idAAM/edit",
            name: "CreateActivitiesAssessmentMethods",
            component: CreateActivitiesAssessmentMethods,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/ActivitiesAssessmentMethods",
            name: "ActivitiesAssessmentMethodsList",
            component: ActivitiesAssessmentMethodsList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/VerificationCredibilities/create",
            name: "CreateVerificationCredibility",
            component: CreateVerificationCredibility,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/VerificationCredibilities/:idVerif/edit",
            name: "EditVerificationCredibility",
            component: CreateVerificationCredibility,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/VerificationCredibilities",
            name: "VerificationCredibilityList",
            component: VerificationCredibilityList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:IdC/ranges/create",
            name: " Create Range",
            component: CreateRangePage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:IdC/ranges/:IdR/edit",
            name: " Edit Range",
            component: CreateRangePage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/ranges/:id",
            name: "Range details",
            component: RangeDetails,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/ranges",
            name: " Ranges list",
            component: RangesPage,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/DatesAndTimesAllocations/create",
            name: "CreateDatesAndTimesAllocation",
            component: CreateDatesAndTimesAllocation,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/DatesAndTimesAllocations/:idDTA/edit",
            name: "EditDatesAndTimesAllocation",
            component: CreateDatesAndTimesAllocation,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/DatesAndTimesAllocations",
            name: "DatesAndTimesAllocationList",
            component: DatesAndTimesAllocationList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/FieldExperienceLocations/create",
            name: "CreateFieldExperienceLocations",
            component: CreateFieldExperienceLocations,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/FieldExperienceLocations/:idFEL/edit",
            name: "EditFieldExperienceLocations",
            component: CreateFieldExperienceLocations,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/FieldExperienceLocations",
            name: "FieldExperienceLocationsList",
            component: FieldExperienceLocationsList,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/specification/course/:id/AssessmentResponsibilities/create",
            name: "CreateAssessmentResponsibilities",
            component: CreateAssessmentResponsibilities,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AssessmentResponsibilities/:idAR/edit",
            name: "EditAssessmentResponsibilities",
            component: CreateAssessmentResponsibilities,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/AssessmentResponsibilities",
            name: "AssessmentResponsibilitiesList",
            component: AssessmentResponsibilitiesList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/course/:id/surveyTemplates",
            component: SurveyTemplatesSpec,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/UpdateSpecificationAprovalData/:idCourse/:id",
            name: "UpdateSpecificationAprovalData",
            component: UpdateSpecificationAprovalData,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/UpdateClo/:id",
            name: "UpdateClo",
            component: UpdateClo,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/SpecificationAprovalDataList",
            name: "SpecificationAprovalDataList",
            component: SpecificationAprovalDataList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/program/:id/jobs",
            name: "Jobs Page",
            component: JobsPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/program/:id/majorTracks",
            name: "Major Tracks Page",
            component: MajorTracksPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/specification/program/:id/exitPoints",
            name: "Exit Points Page",
            component: ExitPointsPage,
            layout: "/citrine",
            invisible: true,
          },
        ],
      },
      //assessment
      {
        path: "/assessments",
        name: "Assessment",
        rtlName: "التقييم",
        icon: AssessmentIcon,
        layout: "/citrine",
        invisible: false,
        collapse: true,
        state: "asessmentsCollapse",
        views: [
          {
            path: "/programs_assessment",
            name: "Programs",
            rtlName: "برامج",
            mini: "P",
            rtlMini: "ب",
            component: ProgramsAssessmentPage,
            layout: "/citrine",
            breadcrumbName: "Program",
          },
          {
            path: "/program/:id",
            name: "Course",
            rtlName: "مقررات",
            mini: "C",
            rtlMini: "م",
            component: CoursesListAssessment,
            layout: "/citrine",
            breadcrumbName: "courses assessment",
            invisible: true,
          },

          {
            path: "/program_assessmentDetails/:id",
            name: "ProgramAssessmentDetails",
            component: ProgramAssessmentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/assessments_list/program/:ProgramId/course/:id",
            name: "AssessmentsList",
            component: AssessmentsList,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "Assessments List 1 ",
          },

          {
            path: "/assessmentsQuestions_list/program/:ProgramId/course/:id",
            name: "AssessmentQuestionList",
            component: AssessmentQuestionList,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentQuestionList",
          },
          {
            path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
            name: "AssessmentsQuestionsDetails",
            component: AssessmentsQuestionsDetails,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentsQuestionsDetails",
          },

          {
            path: "/AssessmentsQuestionsDetailsByGroup/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
            name: "AssessmentsQuestionsDetailsByGroup",
            component: AssessmentsQuestionsDetailsByGroup,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentsQuestionsDetailsByGroup",
          },
          {
            path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Insturctors/",
            name: "AssessmentMethodInstructors",
            component: AssessmentMethodInstructors,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentMethodInstructors",
          },
          {
            path: "/assessmentsQuestionsDetails/program/:ProgramId/course/:course_id/assessmentMethod/:Assessmentid/:AssessmentName/Groups/",
            name: "AssessmentMethodGroups",
            component: AssessmentMethodGroups,
            layout: "/citrine",
            invisible: true,
            breadcrumbName: "AssessmentMethodGroups",
          },
          {
            path: "/clos_list/program/:programId/course/:id",
            name: "ClosAssessmentList",
            component: ClosAssessmentList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/closQuestions_list/program/:programId/course/:id",
            name: "ClosQuestionList",
            component: ClosQuestionList,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/result_calcul_Clos/program/:programId/course/:id/clo/:clo_id",
            name: "ResultCalculClosNotes",
            component: ResultCalculClosNotes,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/result_calcul_Clos_Questions/program/:programId/course/:id/clo/:clo_id",
            name: "ResultCalculClosQuestionNotes",
            component: ResultCalculClosQuestionNotes,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/assessmentsDetails/program/:ProgramId/course/:id/assessmentMethod/:Assessmentid/:AssessmentName",
            name: "AssessmentsDetails",
            component: AssessmentsDetails,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/program/:id/PloListPage/",
            name: "PloListPage",
            component: PloListPage,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/program/:id/Plo/:idPlo",
            name: "PloDetails",
            component: PloDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/program/:id/PeoListPage/",
            name: "PeoListPage",
            component: PeoListPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/program/:id/Peo/:idPeo",
            name: "PEO Details",
            component: PEODetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/program/:id/GAListPage/",
            name: "GAListPage",
            component: GAListPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/program/:id/GA/:idGA",
            name: "GADetails",
            component: GADetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/assessmentMethods",
            name: "Assessment Methods",
            component: AssessmentMethodsPage,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/createAssessmentMethod/:id",
            name: " Create Assessment Method",
            component: CreateAssessmentMethodPage,
            layout: "/citrine",
            invisible: true,
          },
        ],
      },

      // Reports
      {
        path: "/Improvement",
        name: "Reports",
        rtlName: "التحسينات",
        icon: PostAddSharpIcon,
        layout: "/citrine",
        invisible: false,
        collapse: true,
        state: "ImprovementCollapse",
        views: [
          {
            path: "/CourseImprovementList/",
            name: "Courses Reports",
            rtlName: "تحسين المقررات",
            mini: "CR",
            rtlMini: "ت م",
            component: CourseImprovementList,
            layout: "/citrine",
          },

          {
            path: "/KPIsImprovementDetails/:Id/:type/",
            name: "kpi Improvement",
            component: KPIsImprovementDetails,
            layout: "/citrine",
            invisible: true,
          },

          {
            path: "/CreateCourseImprovement/:id",
            name: "Course Improvement",
            component: CreateCourseImprovement,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/CourseImprovementDetails/:id",
            name: "Course Improvement",
            component: CourseImprovementDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/CourseImprovementDetails/:id/CourseReportDocument/:courseReportDocumentId",
            component: CourseReportDocumentDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/CourseImprovementDetails/:id/CourseReportDocument/:courseReportDocumentId/editor",
            component: CourseReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/KPIImprovementDetails/:id/KPIReportDocument/:kpiReportDocumentId",
            component: KpiReportDocument,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/KPIImprovementDetails/:id/KPIReportDocument/:kpiReportDocumentId/editor",
            component: KpiReportDocumentEditor,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/ProgramReports",
            name: "Programs Reports",
            rtlName: "التحسينات",
            layout: "/citrine",
            invisible: false,
            mini: "PR",
            collapse: true,
            state: "ProgramReportsCollapse",
            views: [
              {
                path: "/PloImprovementList/",
                name: "PLOs Report",
                rtlName: "تحسين مخرجات برنامج التعليم",
                mini: "PR",
                rtlMini: "ت م ب ت",
                component: PloImprovementList,
                layout: "/citrine",
              },
              {
                path: "/GAImprovementList/",
                name: "GAs Report",
                rtlName: "تحسين المقررات",
                mini: "GR",
                rtlMini: "ت م",
                component: GAImprovementList,
                layout: "/citrine",
              },
              {
                path: "/PeoImprovementList/",
                name: "PEO Report",
                rtlName: "تحسين المقررات",
                mini: "PR",
                rtlMini: "ت م",
                component: PEOImprovementList,
                layout: "/citrine",
              },

              {
                path: "/PeoImprovementList/",
                name: "PEO Report",
                rtlName: "تحسين المقررات",
                mini: "PR",
                rtlMini: "ت م",
                component: PEOImprovementList,
                layout: "/citrine",
              },
              {
                path: "/GAImprovementDetails/:id",
                name: "GA Improvement",
                component: GAImprovementDetails,
                layout: "/citrine",
                invisible: true,
              },
              {
                path: "/AnnualProgramImprovementList/",
                name: "AP Report",
                rtlName: "تحسين المقررات",
                mini: "APR",
                rtlMini: "ت م",
                component: AnnualProgramImprovementList,
                layout: "/citrine",
              },

              {
                path: "/AnnualProgramImprovementDetails/:Id",
                name: "Annual Program",
                component: AnnualProgramImprovementDetails,
                layout: "/citrine",
                invisible: true,
              },
              {
                path: "/PeoImprovementDetails/:id",
                name: "Peo Improvement",
                component: PEOImprovementDetails,
                layout: "/citrine",
                invisible: true,
              },

              {
                path: "/PEOImprovementDetails/:id/PEOReportDocument/:peoReportDocumentId",
                component: PEOReportDocumentDetails,
                layout: "/citrine",
                invisible: true,
              },
              // {
              //   path: "/PEOImprovementDetails/:id/PEOReportDocument/:peoReportDocumentId/editor",
              //   component: PEOReportDocumentEditor,
              //   layout: "/citrine",
              //   invisible: true,
              // },
              {
                path: "/PLOImprovementDetails/:id/PLOReportDocument/:ploReportDocumentId",
                component: PLOReportDocumentDetails,
                layout: "/citrine",
                invisible: true,
              },
              // {
              //   path: "/PLOImprovementDetails/:id/PLOReportDocument/:ploReportDocumentId/editor",
              //   component: PLOReportDocumentEditor,
              //   layout: "/citrine",
              //   invisible: true,
              // },
              {
                path: "/GAImprovementDetails/:id/GAReportDocument/:gaReportDocumentId",
                component: GAReportDocumentDetails,
                layout: "/citrine",
                invisible: true,
              },
              // {
              //   path: "/GAImprovementDetails/:id/GAReportDocument/:gaReportDocumentId/editor",
              //   component: GAReportDocumentEditor,
              //   layout: "/citrine",
              //   invisible: true,
              // },
              {
                path: "/annualProgramReports/:reportid/documents/:documentId",
                component: AnnualProgramReportDocument,
                layout: "/citrine",
                invisible: true,
              },
              {
                path: "/KPIsImprovementList/",
                name: "KPIs Reports",
                rtlName: "تحسين مخرجات برنامج التعليم",
                mini: "KR",
                rtlMini: "ت م ب ت",
                component: KPIsImprovementList,
                layout: "/citrine",
              },
            ],
          },
        ],
      },
      //surveys
      {
        name: "Surveys",
        rtlName: "الاستبيان",
        icon: SpeakerNotesIcon,
        collapse: true,
        state: "surveyCollapse",
        views: [
          {
            path: "/surveys/studentsSurvey",
            name: "Internal Survey",
            rtlName: "استبيان الطلاب",
            mini: "AS",
            rtlMini: "ا ط",
            component: StudentSurveyCampaignList,
            layout: "/citrine",
          },
          // {
          //   path: "/surveys/externalSurvey",
          //   name: "External Survey",
          //   rtlName: "استبيان الطلاب",
          //   mini: "ES",
          //   rtlMini: "ا ط",
          //   component: StudentSurveyCampaignList,
          //   layout: "/citrine",
          // },
          {
            path: "/surveys/studentsSurvey/:id",
            name: "StudentSurveyCampaignDetails",
            component: StudentSurveyCampaignDetails,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/surveys/studentsSurvey/:id/surveyForm",
            name: "Survey Form",
            component: SurveyForm,
            layout: "/citrine",
            invisible: true,
          },
          {
            path: "/surveys/studentsSurvey/surveyAnalysis",
            name: "Survey Analysis",
            component: SurveyAnalysis,
            layout: "/citrine",
            invisible: true,
          },
        ],
      },
    ],
  },
];
export default dashRoutes;
