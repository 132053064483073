import { DataGrid } from "@mui/x-data-grid";

import Typography from "@material-ui/core/Typography";
import { Breadcrumb, notification } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  //fetchAllPrograms,
  updateSurveyPlanningDates
} from "../../../../reduxToolkit/features/program/ProgramsSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import SurveyDropDown from "./SurveyDropDown";

import { fetchProgramById } from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchAcademicYearById } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchSemesterById } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";
import AddSurvey from "./AddSurvey.jsx";

export default function Survey({ match }) {

  const { program_id } = match.params;
  const { semesterId } = match.params;
  const { id } = match.params;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectionModel, setSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [courseId, setCourseId] = useState([]);

  const semester = useSelector((state) => state.semester.semester);
  const academicYear = useSelector((state) => state.academicYear.academicYear);
  const program = useSelector((state) => state.program.program)
  const semesterStatus = useSelector((state) => state.semester.semesterStatus);
  const programsStatus = useSelector((state) => state.program.allProgramsStatus);

  useEffect(() => {
    fetchSemester(semesterId);
    fetchAcademicYear(id);
    fetchProgram(program_id);
  }, []);

  const fetchSemester = async (id) => {
    try {
      const resultAction = await dispatch(fetchSemesterById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchProgram = async (id) => {
    try {
      const resultAction = await dispatch(fetchProgramById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAcademicYear = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      width: 150,
      sortable: true,
    },
    {
      field: "survey_name",
      headerName: "Template",
      width: 350,
      sortable: true,
    },
    {
      field: "start_date",
      headerName: "Start date",
      flex: 0.25,
    },
    {
      field: "due_date",
      headerName: "Due date ",
      flex: 0.25,
      sortable: true,
    },
  ];

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onCellEditCommit = async (event) => {
    if (event.value !== null) {
      const value = moment(event.value).format("YYYY-MM-DD");
      const field = event.field;
      const id = event.id;
      if (value && value !== "Invalid date" && value !== event.row[field]) {
        const resultAction = await dispatch(
          updateSurveyPlanningDates({ programs_update_ids: id, [field]: value })
        );
        if (updateSurveyPlanningDates.fulfilled.match(resultAction)) {
          // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
          dispatch(fetchProgram(program_id));
          openNotificationWithIcon("success", `Updated successfully`);
        } else {
          if (resultAction.payload) {
            // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
            setHandledError(resultAction.payload);
            handleOpenErrorModal();
          } else {
            setHandledError(resultAction.error);
            handleOpenErrorModal();
          }
        }
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
            <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/planning/academicCalender/academicYears`}>
                {t("Academic years")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}`}
              >
                {t(`${academicYear && academicYear.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("semesters")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}`}
              >
                {t(`${semester && semester.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}`}
              >
                {t(`${program && program.program_degree_name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("Survey Planning")}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={11}>
        </GridItem>
        <GridItem xs={12} sm={12} md={1} >
          <SurveyDropDown
            program_id={program_id}
            selectedRows={selectionModel}
          />
        </GridItem>
      </GridContainer>
      <Card>
        <CardBody>
          <div style={{
            display: 'flex',
            height: 600,
            width: "100%",
            margin: 20
          }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 400}
              rows={program && program.surveyTemplates || []}
              columns={columns}
              autoPageSize={true}
              columnBuffer={0}
              disableSelectionOnClick
              onCellEditCommit={onCellEditCommit}
              showCellRightBorder
              showColumnRightBorder
              checkbox
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          </div>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
